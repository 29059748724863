import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * Renders an Image component with the specified field and className props.
 * @param {Object} props - The component props.
 * @param {Object} props.field - The field object to be used by the Image component.
 * @param {string} props.className - The className to be used by the Image component.
 * @param {Object} props.rest - The rest of the props to be passed to the Image component.
 * @returns {JSX.Element} - The rendered Image component.
 */
const ImageSitecoreField = (props) => {
  const { field, className, ...rest } = props;
  return <Image field={field} className={className} {...rest} />;
};

export default ImageSitecoreField;
