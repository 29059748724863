/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import { Link } from 'react-router-dom';
import Tag from '../../../globals/buttons/Tag';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags } from '../../../utils/HelperUtils';
import { withTranslation } from 'react-i18next';

const CardInsightsFeatured = ({
  item,
  backgroundColor,
  title,
  url,
  tagsToShowList,
  componentName,
  t,
}) => {
  const { fields = {}, name, ctaUrl, solutionsTags } = item;
  const { videoContent = {}, contentType = {} } = fields;
  const [showTags, setShowTags] = useState([]);
  const [contentTypeVal, setContentTypeVal] = useState('');
  const getContentType = getContentTypeTags(item, fields)?.CT?.name;
  useEffect(() => {
    switch (getContentType.toLowerCase().trim()) {
      case 'article':
        setContentTypeVal(t('related-insights-carousel-article-read'));
        break;
      case 'podcast':
        setContentTypeVal(t('related-insights-carousel-podcast-listen'));
        break;
      case 'webinar':
        setContentTypeVal(t('related-insights-carousel-webinar-view'));
        break;
      case 'event':
        setContentTypeVal(t('related-insights-carousel-webinar-view'));
        break;
      default:
        setContentTypeVal('');
    }
  }, []);
  useEffect(() => {
    tagsToShowList &&
      tagsToShowList.map((item) => {
        switch (item?.fields?.name?.value.replace(/\s/g, '').toLowerCase()) {
          case 'lineofbusiness':
            setShowTags(fields.lineOfBusiness);
            break;
          case 'solutions':
            setShowTags(fields.solutions);
            break;
          default:
            setShowTags([]);
        }
      });
  }, [tagsToShowList]);
  return (
    <a
      href={url}
      className={'card card__insights featured'}
      {...OtherLinkDataAttributes({
        name: componentName,
        dataItemName: name,
      })}
    >
      <div className="card-body">
        <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
        <div className="card-content">
          <h3 className="title">{title}</h3>
        </div>
        <div className={`card-footer-carousel ${backgroundColor}`}>
          {videoContent && (
            <Link to={url}>
              <Button
                text={contentTypeVal}
                cssClass="listenbtn"
                variant="text-button"
                fontSize="small"
                componentName={componentName}
                dataItemName={name}
              />
            </Link>
          )}
          <div className="related-solutions">
            {showTags &&
              showTags
                ?.slice(0, 1)
                ?.map((tag, index) => (
                  <Tag
                    key={`${index}-${tag.text}`}
                    cssClass={backgroundColor === 'primary' ? 'light' : 'dark'}
                    icon={tag?.fields?.icon?.value}
                    text={tag.name}
                    variant={tag.variant}
                    fontSize="small"
                    componentName={componentName}
                    dataItemName={name}
                  />
                ))}
          </div>
        </div>
      </div>
    </a>
  );
};
CardInsightsFeatured.defaultProps = {
  backgroundColor: '',
  item: {},
};

CardInsightsFeatured.propTypes = {
  item: PropTypes.object,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary']),
};

export default withTranslation()(CardInsightsFeatured);
