import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import playIcon from '../../../images/play-circle.svg';
// let $ = require('jquery');
// window.jQuery = $; // TODO fix hack using webpack!!
// require('@fancyapps/fancybox');

const VideoModal = ({ title, ariaLabel, videoUrl, videoPreviewImg, videoDuration }) => (
  <a className="video__link" href={videoUrl} aria-label={ariaLabel}>
    <img className="video__preview" src={mediaApi.updateImageUrl(videoPreviewImg)} alt={title} />
    <img className="play_icon" src={mediaApi.updateImageUrl(playIcon)} alt={'play_icon'} />
  </a>
);

VideoModal.defaultProps = {
  cssClass: '',
  modalId: '',
  ariaLabel: 'video modal',
  title: '',
  videoDuration: '2:55',
  videoWidth: '560',
  videoHeight: '315',
  videoUrl: '',
  videoPreviewImg: '',
};

VideoModal.propTypes = {
  cssClass: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoWidth: PropTypes.string.isRequired,
  videoHeight: PropTypes.string.isRequired,
  videoPreviewImg: PropTypes.string.isRequired,
  videoDuration: PropTypes.string.isRequired,
  /** Video URL format https://www.youtube.com/embed/XXXXXXXX */
  videoUrl: PropTypes.string.isRequired,
};

export default VideoModal;
