import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';

const CardInsightsCallout = ({ cardListing, componentName = '' }) => {
  const { fields = {} } = cardListing;
  const { backgroundColor, showCTAIcon, ctaLink, ctaText, paragraphText, optCtaLink, optCtaText } =
    fields;

  const width = 'md';
  const value = '';

  return (
    <div
      className={`card card__insights card__callout card__${width} ${value} ${getFooterCallOutBarStylesFromBackgroundColor(
        backgroundColor?.fields?.text?.value
      )}`}
    >
      <div className="card-body">
        {paragraphText && paragraphText?.value && (
          <h3 className="title">
            <TextSitecoreField field={paragraphText} />
          </h3>
        )}

        <div className="cta cta__wrap no-border flex-row">
          {ctaLink?.value?.href && ctaText?.value && (
            <ButtonHref
              cssClass={
                backgroundColor?.fields?.text?.value &&
                getFooterCallOutBarStylesFromBackgroundColor(
                  backgroundColor?.fields?.text?.value
                ) === 'primary'
                  ? 'black'
                  : 'white'
              }
              text={<TextSitecoreField field={ctaText} /> || ctaText?.value}
              href={ctaLink && ctaLink?.value?.href}
              variant="primary-button"
              arrow="true"
              linkType={ctaLink && ctaLink?.value?.linktype}
              linkFields={ctaLink && ctaLink}
              componentName={componentName || 'CardInsightsCallout'}
            />
          )}
          {optCtaLink?.value?.href && optCtaText?.value && (
            <ButtonHref
              cssClass={
                backgroundColor?.fields?.text?.value &&
                getFooterCallOutBarStylesFromBackgroundColor(
                  backgroundColor?.fields?.text?.value
                ) === 'primary'
                  ? 'black'
                  : 'white'
              }
              text={<TextSitecoreField field={optCtaText} /> || optCtaText?.value}
              href={optCtaLink && optCtaLink?.value?.href}
              variant="text-button"
              arrow="true"
              linkType={optCtaLink && optCtaLink?.value?.linktype}
              linkFields={optCtaLink && optCtaLink}
              componentName={componentName || 'CardInsightsCallout'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CardInsightsCallout.defaultProps = {
  insightListing: {
    backgroundColor: {
      fields: {
        value: 'white',
      },
    },

    showCTAIcon: {},

    ctaLink: {
      value: {
        href: '',
        linktype: 'internal',
      },
    },

    ctaStyle: {
      fields: {
        text: {
          value: '',
        },
      },

      ctaText: {
        value: '',
      },

      paragraphText: {
        value: '',
      },
    },
  },
  fields: {},
  componentName: '',
};

CardInsightsCallout.propTypes = {
  insightListing: PropTypes.shape({
    backgroundColor: PropTypes.shape({
      fields: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),

    showCTAIcon: PropTypes.shape({}),

    ctaLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,

        linktype: PropTypes.string,
      }),
    }),

    ctaStyle: PropTypes.shape({
      fields: PropTypes.shape({
        text: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
    }),

    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),

    paragraphText: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  fields: PropTypes.shape({}),
  componentName: PropTypes.string,
};

export default CardInsightsCallout;
