import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getBrowserWindow,
  isSCExperienceEditorActive,
  sitecoreHostUrl,
} from '../../../utils/HelperUtils';
import './searchField.scss';
import IconSearch from '../../../../src/static/icons/icon--search.svg';
import axios from 'axios';
// These suggestions are just for demo purposes
// import { suggestions } from './suggestions';

const SearchField = ({
  placeholder,
  getQuery,
  onSearch = () => {},
  setIsSearchSuggestions = () => {},
  handleAPICall,
}) => {
  const searchButtonRef = useRef(null);
  const suggestionRefs = useRef([]);
  const inputRef = useRef(null);

  const [query, setQuery] = useState(getQuery ? getQuery : '');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);
  const [suggestions, setsuggestions] = useState([]);

  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const navigate = useNavigate();
  const queryParams = getBrowserWindow ? new URLSearchParams(location.search) : '';

  // when someone types in the search field, update the query
  const handleQueryChange = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9*"\s]/g, '');
    setQuery(value);
    setIsTyping(true);
    // Only show suggestions when there are 3 or more characters
    setShowSuggestions(value.length >= 3);
    if (value.length >= 3) {
      setIsSearchSuggestions(true);
    } else {
      setIsSearchSuggestions(false);
    }
  };

  const handleQueryBlur = () => {
    setIsTyping(false);
  };

  // when someone clicks on a suggestion
  const handleSuggestionClick = (suggestion) => {
    setActiveSuggestionIndex(-1);
    setQuery(suggestion);
    setShowSuggestions(false);
    onSearch(suggestion);
    if (!isSCExperienceEditorActive) {
      if (suggestion !== '') {
        queryParams.set('search', suggestion);
        const newSearch = `?${queryParams.toString()}`;
        navigate({ pathname: '/site-search', search: newSearch });
      }
    }
  };

  // when someone clicks on the search button
  const handleSearchClick = (query) => {
    onSearch(query);
    handleAPICall();
    if (!isSCExperienceEditorActive) {
      if (query !== '') {
        queryParams.set('search', query);
        const newSearch = `?${queryParams.toString()}`;
        navigate({ pathname: '/site-search', search: newSearch });
      }
    }
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'search',
          search_term: query,
        });
      }
    }
  };

  // when press return key on input text field
  const handleSearch = (event) => {
    const { key } = event;

    // Handle tab key event
    const handleTab = () => {
      event.preventDefault();
      if (activeSuggestionIndex === -1) {
        // if the user presses tab, select the first suggestion
        setActiveSuggestionIndex(0);
      } else {
        // Focus the search button
        searchButtonRef.current.focus();
      }
    };

    // Handle scrolling the suggestions list and the offset
    const handleScroll = (offset) => {
      const selectedSuggestion = suggestionRefs.current[activeSuggestionIndex];
      const suggestionsList = selectedSuggestion.parentNode;
      const { scrollTop, clientHeight } = suggestionsList;
      const selectedTop = selectedSuggestion.offsetTop - offset;
      const selectedBottom = selectedTop + selectedSuggestion.clientHeight;
      if (selectedBottom > scrollTop + clientHeight) {
        suggestionsList.scrollTop = selectedBottom - clientHeight;
      } else if (selectedTop < scrollTop) {
        suggestionsList.scrollTop = selectedTop;
      }
    };

    // Handle arrow down key event
    const handleArrowDown = () => {
      event.preventDefault();
      if (activeSuggestionIndex < filteredSuggestions.length - 1) {
        // Navigate down the list
        setActiveSuggestionIndex((prev) => prev + 1);
        handleScroll(-100);
      }
    };

    // Handle arrow up key event
    const handleArrowUp = () => {
      event.preventDefault();
      if (activeSuggestionIndex > -1) {
        if (activeSuggestionIndex === 0) {
          // if we're at the top of the list, go back to the input field
          setActiveSuggestionIndex(-1);
          inputRef.current.focus();
          return;
        }
        // Navigate up the list
        setActiveSuggestionIndex((prev) => prev - 1);
        handleScroll(100);
      }
    };

    // Handle enter key event
    const handleEnter = () => {
      event.preventDefault();
      if (activeSuggestionIndex > -1) {
        // if there is an active suggestion, handleSuggestionClick() else onSearch(query)
        handleSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
      } else {
        // perform search with query
        onSearch(query);
        handleAPICall();
        if (!isSCExperienceEditorActive) {
          if (query !== '') {
            queryParams.set('search', query);
            const newSearch = `?${queryParams.toString()}`;
            navigate({ pathname: '/site-search', search: newSearch });
          }
        }
        if (!isSCExperienceEditorActive) {
          if (getBrowserWindow) {
            window?.dataLayer?.push({
              event: 'search',
              search_term: query,
            });
          }
        }
      }
    };

    // Handle search with no suggestions
    const handleSearchNoSuggestions = () => {
      // perform search with query
      onSearch(query);
      if (query !== '') {
        queryParams.set('search', query);
        const newSearch = `?${queryParams.toString()}`;
        navigate({ pathname: '/site-search', search: newSearch });
      }
    };

    if (showSuggestions) {
      // Handle key events when suggestions are visible
      switch (key) {
        case 'Tab':
          handleTab();
          break;
        case 'ArrowDown':
          handleArrowDown();
          break;
        case 'ArrowUp':
          handleArrowUp();
          break;
        case 'Enter':
          handleEnter();
          break;
        default:
          break;
      }
    } else if (key === 'Enter') {
      // Handle key event when suggestions are not visible
      handleSearchNoSuggestions();
    }
  };

  // update the hasSuggestions state based on the filtered suggestions
  // useEffect(() => {
  //   axios.get(`${API_URL}${query}`).then((response) => {
  //     const searchResultApi = response?.data?.searchResult ? response?.data?.searchResult : [];
  //     const suggestionData = searchResultApi.map((item) => {
  //       return item.title;
  //     });
  //     setsuggestions(suggestionData);
  //   });
  // }, [query]);

  // filter the suggestions based on the query
  const filteredSuggestions = suggestions.filter((suggestion) => {
    const words = query.toLowerCase().split(' ');
    return words.every((word) => suggestion.toLowerCase().includes(word));
  });

  // update the hasSuggestions state based on the filtered suggestions
  useEffect(() => {
    setHasSuggestions(filteredSuggestions.length > 0);
  }, [filteredSuggestions]);

  // highlight the matching text
  const highlightMatch = (text) => {
    const queryWithoutSpaces = query.replace(/\s/g, '');
    const queryRegex = new RegExp(`(${queryWithoutSpaces.split('').join('\\s*')})`, 'gi');
    const matches = text.toLowerCase().match(queryRegex);
    if (matches) {
      const parts = text.split(queryRegex);
      return (
        <>
          {parts.map((part, index) =>
            matches.includes(part.toLowerCase()) ? (
              <span key={index} className="highlight">
                {part.replace(new RegExp(queryWithoutSpaces, 'gi'), (match) => `${match}`)}
              </span>
            ) : (
              part
            )
          )}
        </>
      );
    }
    return text;
  };

  // hide when click away
  const suggestionsRef = useRef(null);
  const handleClickAway = (event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  return (
    <div ref={suggestionsRef} className="searchFieldWrapper">
      <div className="searchInputWrapper">
        <input
          type="text"
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleSearch}
          onBlur={handleQueryBlur}
          className={isTyping ? 'typing' : 'search_input'}
          aria-label="Search"
          aria-activedescendant={
            activeSuggestionIndex > -1 ? `suggestion-${activeSuggestionIndex}` : undefined
          }
          aria-owns="suggestions-list"
          placeholder={inputPlaceholder ? inputPlaceholder : 'Search'}
        />

        <button
          ref={searchButtonRef}
          className="search_btn"
          onClick={() => handleSearchClick(query)}
        >
          <img src={IconSearch} alt="search" />
        </button>
      </div>

      {showSuggestions && (
        <>
          {hasSuggestions ? (
            <div className="suggestions">
              <ul
                role="listbox"
                id="suggestions-list"
                aria-label="suggestions list"
                className="suggestionsList"
              >
                {filteredSuggestions.map((suggestion, index) => (
                  <li
                    key={suggestion}
                    id={`suggestion-${index}`}
                    aria-selected={index === activeSuggestionIndex}
                    className={index === activeSuggestionIndex ? 'activeSuggestion' : ''}
                    onClick={() => handleSuggestionClick(suggestion)}
                    ref={(element) => (suggestionRefs.current[index] = element)}
                  >
                    {highlightMatch(suggestion)}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default SearchField;
