import React from 'react';
import PropTypes from 'prop-types';
import CardTeamProfile from '../Cards/CardTeamProfile';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardBlockTeamProfiles = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { teamProfilesList, title, subTitle } = fields;
  return (
    <section
      className={`card-block profile profile__${teamProfilesList && teamProfilesList.length}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row">
          <div className="col section-header teamprofile">
            {title && (
              <h2 className="title_teamProfile">
                <TextSitecoreField field={title} />
              </h2>
            )}
            {subTitle && (
              <p>
                <TextSitecoreField field={subTitle} />
              </p>
            )}
          </div>
        </div>
        <div className="row card-wrapper">
          {teamProfilesList &&
            teamProfilesList.map((item, index) => (
              <CardTeamProfile
                key={`${item.id}-${index}`}
                data={item}
                componentName={componentName}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

CardBlockTeamProfiles.defaultProps = {
  fields: {
    teamProfilesList: [],
    title: '',
  },
};

CardBlockTeamProfiles.propTypes = {
  fields: PropTypes.shape({
    teamProfilesList: PropTypes.arrayOf(PropTypes.shape()),
    title: PropTypes.string,
  }),
};

export default CardBlockTeamProfiles;
