import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import { isEmpty } from 'lodash';

const SubNavPanel = ({
  title,
  text,
  ctaText,
  linkHeading,
  links,
  ctaLink,
  cssClass,
  backgroundColor,
  ctaLinkType,
  linkFields,
}) => (
  <div className={`flex-container row subnav subnav__panel fade-in ${cssClass}`}>
    <div className="flex-container1">
      <h3 className="title grid-title">{<TextSitecoreField field={title} />}</h3>
      <div className="sub-title">{<TextSitecoreField field={text} />}</div>
      <div className="cta__wrap">
        <ButtonHref
          text={ctaText}
          href={ctaLink}
          variant="text-button"
          fontSize={backgroundColor === 'primary' ? 'black' : 'white'}
          {...(ctaText ? { arrow: true } : {})}
          componentName="ExpandableGrid"
          dataItemName={title?.value}
          linkType={ctaLinkType}
          linkFields={linkFields}
        />
      </div>
    </div>

    <div className="flex-container2">
      <div className="wrapper">
        <div className="related-solution label small">
          {<TextSitecoreField field={linkHeading} />}
        </div>
        <div className="links">
          {links &&
            links.map(
              (link) =>
                !isEmpty(link.displayName) && (
                  <Button
                    key={link.id}
                    text={link.displayName}
                    variant="text-button"
                    cssClass={backgroundColor === 'primary' ? 'black' : 'white'}
                    // fontSize={backgroundColor === 'primary' ? 'small' : 'white'}
                    arrow
                    componentName="ExpandableGrid"
                    dataItemName={linkHeading?.value}
                    BtnFunction={() => {
                      !link?.fields?.Link?.value?.target &&
                      (link?.fields?.Link?.value?.target === '' ||
                        link?.fields?.Link?.value?.linktype === 'internal')
                        ? (location.href = link?.fields?.Link?.value?.href)
                        : window.open(link?.fields?.Link?.value?.href, '_blank');
                      return false;
                    }}
                  />
                )
            )}
        </div>
      </div>
    </div>
  </div>
);

SubNavPanel.defaultProps = {
  title: '',
  subTitle: '',
  ctaText: '',
  ctaLink: '',
  links: [],
  cssClass: '',
};

SubNavPanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  links: PropTypes.array,
  cssClass: PropTypes.string,
};

export default SubNavPanel;
