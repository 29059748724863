import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { isSCExperienceEditorActive, getBrowserWindow } from '../../../utils/HelperUtils';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import { CONTENT_TYPE_PODCAST } from '../../../constants';
import { ButtonDataAttributes } from '../../../constants/gtm';

const Player = ({ value, linkTranscipt, cssClass, variant = '', componentName = '' }) => {
  const { href, title } = value;
  const handleVideoPlay = () => {
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        if (variant === CONTENT_TYPE_PODCAST) {
          window?.dataLayer?.push({
            event: 'podcast_start',
            podcast_id: href,
            podcast_name: title,
          });
        } else {
          window?.dataLayer?.push({
            event: 'c_video_start',
            video_id: href,
            video_title: title,
          });
        }
      }
    }
  };

  const handleVideoEnd = () => {
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        if (variant === CONTENT_TYPE_PODCAST) {
          window?.dataLayer?.push({
            event: 'podcast_complete',
            podcast_id: href,
            podcast_name: title,
          });
        } else {
          window?.dataLayer?.push({
            event: 'c_video_complete',
            video_id: href,
            video_title: title,
          });
        }
      }
    }
  };
  return (
    <div className="card podcast-embed" style={{ boxShadow: 'none' }}>
      <div
        className={`card podcast podcast__embed podcast__spotify ${cssClass}`}
        style={{ boxShadow: 'none' }}
      >
        <div className="player-wrapper" style={{ height: '500px' }}>
          <ReactPlayer
            className="react-player"
            url={href}
            controls={true}
            width="100%"
            height="100%"
            onPlay={handleVideoPlay}
            onEnded={handleVideoEnd}
          />
        </div>
      </div>
      {linkTranscipt && (
        <div className="cta__wrap">
          <ButtonHref
            text={linkTranscipt}
            variant="text-button"
            arrow
            {...ButtonDataAttributes({
              name: componentName,
              text: linkTranscipt,
              variant: 'text-button',
              arrow: true,
              dataItemName: '',
            })}
          />
        </div>
      )}
    </div>
  );
};

Player.defaultProps = {
  url: '',
  linkTranscipt: '',
  cssClass: '',
  variant: '',
  componentName: '',
};

Player.propTypes = {
  url: PropTypes.string,
  linkTranscipt: PropTypes.string,
  cssClass: PropTypes.string,
  variant: PropTypes.string,
  componentName: PropTypes.string,
};

export default Player;
