import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { OtherNavDataAttributes } from '../../../constants/gtm';

const ActionBarSocialFollow = ({ items, componentName = '' }) => {
  return (
    <div className="social-follow">
      {items &&
        items?.map((item, id) => (
          <a
            key={id}
            href={item?.fields?.url?.value}
            {...OtherNavDataAttributes({
              name: componentName,
              dataItemName:
                item?.fields?.icon?.value?.alt || 'alt text goes here, not configured at sitecore',
              text: '',
              url: item?.fields?.icon?.value?.src,
            })}
          >
            <div className="icon_contact">
              <ImageSitecoreField field={item?.fields?.icon} />
            </div>
          </a>
        ))}
    </div>
  );
};

ActionBarSocialFollow.defaultProps = {
  items: [],
};

ActionBarSocialFollow.propTypes = {
  /** Social Media Accounts */
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ActionBarSocialFollow;
