import React from 'react';
import PropTypes from 'prop-types';
import SocialIcon from './SocialIcon';

const ActionBarSocial = ({ props }) => {
  return (
    <div className="social-bar">
      {props?.map((item, index) => (
        <SocialIcon
          key={`${index}-${item?.fields?.name?.value}`}
          img={item?.fields?.icon}
          altText={item?.fields?.name?.value}
          tooltip={`Share to ${item?.fields?.name?.value}`}
          ctaUrl={item?.fields?.url?.value}
        />
      ))}
    </div>
  );
};

ActionBarSocial.defaultProps = {
  twitterUrl: '',
  linkedInUrl: '',
  facebookUrl: '',
  youtubeUrl: '',
  instagramUrl: '',
};

ActionBarSocial.propTypes = {
  /** Twitter share URL */
  twitterUrl: PropTypes.string,

  /** LinkedIn share URL */
  linkedInUrl: PropTypes.string,

  /** Facebook share URL */
  facebookUrl: PropTypes.string,

  /** YouTube share URL */
  youtubeUrl: PropTypes.string,

  /** Instagram share URL */
  instagramUrl: PropTypes.string,
};

export default ActionBarSocial;
