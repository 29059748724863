import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DecisionTreeCard from '../DecisionTreeCard/DecisionTreeCard';
import CloseButton from '../../../globals/buttons/CloseButton/CloseButton';
import BackButton from '../../../globals/buttons/BackButton/BackButton';
import DecisionTreeBreadcrumb from '../DecisionTreeBreadcrumb/DecisionTreeBreadcrumb';
import './decisionTreeStep3.scss';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const DecisionTreeStep3 = ({
  subTitle,
  title,
  breadcrumbs,
  cards,
  currentStep,
  backBtnText,
  backBtnIcon,
  backBtnMobileIcon,
  closeIcon,
  ctaArrowIcon,
  ctaText,
  onBackClick,
  closeDecisionTree,
  componentName,
}) => {
  const [cardsWithTags, setCardsWithTags] = useState(0);
  let count = 0;

  useEffect(() => {
    if (count > cardsWithTags) setCardsWithTags(count);
  }, [count]);
  const isBigScreen = useMediaQuery({ minWidth: breakpoints.md });

  return (
    <section className={`dt-step3 dt-step3--${currentStep.toString().replace('.', '-')}`}>
      <div className="dt-step3__container">
        <div className="dt-step3__header">
          <div className="dt-step3__btn-container">
            <BackButton
              backBtnText={backBtnText}
              backBtnIcon={isBigScreen ? backBtnIcon : backBtnMobileIcon}
              onClick={onBackClick}
            />
            <CloseButton icon={closeIcon} cssClass="black" onClick={closeDecisionTree} />
            <DecisionTreeBreadcrumb breadcrumbs={breadcrumbs} />
          </div>
          <div className="dt-step3__title-container">
            <h2 className="dt-step3__sub-title">{subTitle}</h2>
            <h3 className="dt-step3__title">{title}</h3>
          </div>
        </div>
        <div
          className={`dt-step3__card-container ${
            cardsWithTags > 0 ? 'dt-step3__card-container--lg-cols' : ''
          }`}
        >
          {cards &&
            cards.map((card, index) => {
              if (card.tags) count++;
              return (
                <DecisionTreeCard
                  key={index}
                  index={index}
                  tag={card.tag}
                  tags={card.tags}
                  type={card.type}
                  date={card.date}
                  title={card?.fields?.title ? card.fields.title : card.title}
                  text={card?.fields?.subTitle ? card.fields.subTitle : card.subTitle}
                  arrowIcon={card?.fields?.ctaArrowIcon ? card.fields.ctaArrowIcon : ctaArrowIcon}
                  buttonLink={card?.fields?.ctaLink?.value?.href}
                  buttonText={card?.fields?.ctaText ? card.fields.ctaText : ctaText}
                  variant={currentStep}
                  isSmall={card.isSmall}
                  componentName={componentName}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

DecisionTreeStep3.defaultProps = {
  subTitle: '',
  title: '',
  breadcrumbs: [],
  featuredImage: '',
  cards: [],
  backBtnText: '',
  backBtnIcon: {},
  closeIcon: {},
  onBackClick: () => {},
  onCardClick: () => {},
  closeDecisionTree: () => {},
};

DecisionTreeStep3.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  featuredImage: PropTypes.string,
  cards: PropTypes.array,
  backBtnText: PropTypes.string,
  backBtnIcon: PropTypes.shape({}),
  closeIcon: PropTypes.shape({}),
  currentStep: PropTypes.number,
  onBackClick: PropTypes.func,
  onCardClick: PropTypes.func,
  closeDecisionTree: PropTypes.func,
};

export default DecisionTreeStep3;
