import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';

const Overview = ({ title, subTitle, cssClass }) => (
  <div className={`text-header ${cssClass}`}>
    <h2 className="title">
      <TextSitecoreField field={title} />
    </h2>
    {subTitle && (
      <p className="sub-title">
        <TextSitecoreField field={subTitle} />
      </p>
    )}
  </div>
);

Overview.defaultProps = {
  title: '',
  subTitle: '',
  cssClass: 'standard',
};

Overview.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  cssClass: PropTypes.oneOf(['standard', 'small']),
};

export default Overview;
