import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

const CardSpeaker = ({
  img,
  altText,
  name,
  jobTitle,
  Department,
  ctaUrl,
  variant,
  componentName,
}) => (
  <a
    href={ctaUrl}
    className={`card speaker ${variant}`}
    {...OtherLinkDataAttributes({
      name: componentName,
      text: name?.value + jobTitle?.value + Department?.value,
    })}
  >
    {img && <ImageSitecoreField className="headshot" field={img} />}
    <div className="card-body hostright">
      <h3 className="name">
        <TextSitecoreField field={name}></TextSitecoreField>
      </h3>
      {jobTitle && (
        <p className="job-title">
          <TextSitecoreField field={jobTitle}></TextSitecoreField>
        </p>
      )}
      {Department && (
        <p className="company">
          {' '}
          <TextSitecoreField field={Department}></TextSitecoreField>
        </p>
      )}
    </div>
  </a>
);

CardSpeaker.defaultProps = {
  img: '',
  alText: '',
  name: '',
  jobTitle: '',
  Department: '',
  ctaUrl: '',
  variant: 'small',
};

CardSpeaker.propTypes = {
  img: PropTypes.string,
  alText: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  Department: PropTypes.string,
  ctaUrl: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'large']),
};

export default CardSpeaker;
