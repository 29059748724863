import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import CloseIcon from '../../../static/images/icon_close--white.svg';
import { ComponentDataAttributes } from '../../../constants/gtm';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';

const Alert = (props) => {
  const { backgroundColor, fields, mode } = props;
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <section
      className={`calloutbar-section alert alert__initial ${backgroundColor}  ${
        show ? 'open' : 'closed'
      } `}
      {...ComponentDataAttributes('AlertContactus')}
    >
      {mode === 'initial' && (
        <button className="close-button" onClick={handleClose}>
          <img src={mediaApi.updateImageUrl(CloseIcon)} alt="close icon" />
        </button>
      )}
      <div className="container-xl">
        <div className="row content-wrapper">
          <div className="col rich-text" style={{ textAlign: 'center' }}>
            <RichTextSitecoreField field={fields?.successMessage} />
          </div>
        </div>
      </div>
    </section>
  );
};

Alert.defaultProps = {
  richText: '',
  backgroundColor: '',
  mode: 'initial',
};

Alert.propTypes = {
  richText: PropTypes.string,
  backgroundColor: PropTypes.oneOf(['primary', 'secondary', 'violet', 'green', 'blue']),
  mode: PropTypes.oneOf(['initial', 'ongoing']),
  fields: PropTypes.shape({}),
};

export default Alert;
