import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { withSitecoreContext, mediaApi, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';

import Navigation from './Navigation/Navigation';
import SearchBar from './SearchBar/SearchBar';
import PopularSearchResultsCard from '../PopularSearchResultsCard/PopularSearchResultsCard';
import LanguageModal from '../MultiLanguageModal/LanguageModal';
import Button from '../../../globals/buttons/Button';
import LocationGlobeBlack from '../MultiLanguageModal/images/icon-location-global-black.svg';
import LocationGlobeWhite from '../MultiLanguageModal/images/icon-location-global-white.svg';
import './header.scss';
import PropTypes from 'prop-types';
import {
  getBrowserWindow,
  getCountryLanguageToDisplay,
  getLanguageConfig,
  isBrowserDocumentAvailable,
  isSCExperienceEditorActive,
  setCountryLanguageChoiceToCookie,
} from '../../../utils/HelperUtils';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import NavLink from '../../../globals/navLink';
import {
  ButtonDataAttributes,
  ComponentDataAttributes,
  OtherLinkDataAttributes,
} from '../../../constants/gtm';
import { isEmpty } from 'lodash';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useWindowPathName } from '../../../hooks/useWindowPathName';

const CountrySiteHeader = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    headerLogoDark,
    topNavigation = [],
    searchIcon = {},
    contactUsLink,
    languageSwitcherIcon = {},
    languageData = [],
    headerLogoLink = {},
  } = fields;
  const { isGlobal, country, language } = getBrowserWindow
    ? getLanguageConfig(window?.location?.pathname)
    : { isGlobal: true, language: 'en', country: 'Global' };
  const languageToDisplay = getCountryLanguageToDisplay(
    isMobileScreen,
    isGlobal,
    country,
    language
  );
  const containerRef = useRef(null);
  const containerInnerRef = useRef(null);
  const languageBtnRef = useRef(null);
  const headerContainerRef = useRef(null);
  const mobileMenuContainerRef = useRef(null);
  const headerRef = useRef(null);

  const searchBarRef = useRef();
  const suggestionRefs = useRef([]);
  const inputRef = useRef(null);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const [searchOptions, setSearchOptions] = useState(false);
  const [headerSuggestions, setHeaderSuggestions] = useState([]);
  const [isSearchSuggestions, setIsSearchSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const [currentLanguage, setCurrentLanguage] = useState(languageToDisplay);

  const [isExpanded, setIsExpanded] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showFullModal, setShowFullModal] = useState(false);

  const [mobMenuShow, setMobMenuShow] = useState(false);
  const [mobNavBtn, setMobNavBtn] = useState(false);
  const [mobMenuCloseBtn, setMobMenuCloseBtn] = useState(false);
  const [hideSearchBar, setHideSearchBar] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  // Check if mobile screen
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isSmallHeight, setIsSmallHeight] = useState(false); // TODO limit search suggestions to 3 max

  const [isSubnavOpen, setIsSubnavOpen] = useState(false);
  const [isSubSubnavOpen, setIsSubSubnavOpen] = useState(false);

  const mobileMenuScrollTop = () => {
    if (isBrowserDocumentAvailable) {
      const mainMenuScrollTo = document?.querySelector('.main_menu_scrollto');
      mainMenuScrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  function handleSubnavOpen() {
    setIsSubnavOpen(true);
  }

  function handleSubnavClose() {
    setIsSubnavOpen(false);
  }
  function handleSubSubnavOpen() {
    setIsSubSubnavOpen(true);
  }

  function handleSubSubnavClose() {
    setIsSubSubnavOpen(false);
  }

  const handleResize = () => {
    if (getBrowserWindow) {
      setHeaderHeight(headerRef.current.clientHeight);
      setIsMobileScreen(window && window?.innerWidth < 993);
      setIsSmallHeight(window && window?.innerHeight < 600);
      isMobileScreen
        ? setMobNavBtn(true)
        : (setMobNavBtn(false),
          setMobMenuShow(false),
          setHideSearchBar(false),
          setIsSubnavOpen(false),
          setMobMenuCloseBtn(false));
    }
  };

  const handleSearch = (event) => {
    const { key } = event;

    // Handle tab key event
    const handleTab = () => {
      event.preventDefault();
      if (activeSuggestionIndex === -1) {
        // if the user presses tab, select the first suggestion
        setActiveSuggestionIndex(0);
      } else {
        // Focus the search button
        setActiveSuggestionIndex(-1);
        searchBarRef.current.focusSearchButton();
      }
    };

    // Handle scrolling the suggestions list and the offset
    const handleSuggestionsScroll = (offset) => {
      const selectedSuggestion = suggestionRefs.current[activeSuggestionIndex];
      const suggestionsList = selectedSuggestion.parentNode;
      const { scrollTop, clientHeight } = suggestionsList;
      const selectedTop = selectedSuggestion.offsetTop - offset;
      const selectedBottom = selectedTop + selectedSuggestion.clientHeight;
      if (selectedBottom > scrollTop + clientHeight) {
        suggestionsList.scrollTop = selectedBottom - clientHeight;
      } else if (selectedTop < scrollTop) {
        suggestionsList.scrollTop = selectedTop;
      }
    };

    // Handle arrow down key event
    const handleArrowDown = () => {
      event.preventDefault();
      if (activeSuggestionIndex < headerSuggestions?.length - 1) {
        // Navigate down the list
        setActiveSuggestionIndex((prev) => prev + 1);
        handleSuggestionsScroll(-100);
      }
    };

    // Handle arrow up key event
    const handleArrowUp = () => {
      event.preventDefault();
      if (activeSuggestionIndex > -1) {
        if (activeSuggestionIndex === 0) {
          // if we're at the top of the list, go back to the input field
          setActiveSuggestionIndex(-1);
          inputRef.current.focus();
          return;
        }
        // Navigate up the list
        setActiveSuggestionIndex((prev) => prev - 1);
        handleSuggestionsScroll(100);
      }
    };

    // Handle enter key event
    const handleEnter = () => {
      event.preventDefault();
      if (activeSuggestionIndex > -1) {
        // TODO: Insert Search Functionality Here to include selected suggestion
        // handleSuggestionClick(headerSuggestions[activeSuggestionIndex]);
        // alert('TODO: query selected suggestion and navigate to results page');
        setActiveSuggestionIndex(-1);
        setIsExpanded(false);
        setSearchOptions(false);
        setShowSearch(false);
        setIsSearchSuggestions(false);
        isMobileScreen && setMobNavBtn(true);
      } else {
        // TODO: Insert Search Functionality Here
        // alert(`Searching for ${query}`);
      }
    };

    // Handle search with no suggestions
    const handleSearchNoSuggestions = () => {
      // perform search with query
      // TODO: Insert Search Functionality Here
      // alert(`Searching for ${query}`);
    };

    if (isSearchSuggestions) {
      // Handle key events when suggestions are visible
      switch (key) {
        case 'Tab':
          handleTab();
          break;
        case 'ArrowDown':
          handleArrowDown();
          break;
        case 'ArrowUp':
          handleArrowUp();
          break;
        case 'Enter':
          handleEnter();
          break;
        default:
          break;
      }
    } else if (key === 'Enter') {
      // Handle key event when suggestions are not visible
      handleSearchNoSuggestions();
    }
  };

  // useEffect for listening to resize event
  useEffect(() => {
    if (getBrowserWindow) {
      handleResize();
      window?.addEventListener('resize', handleResize);
      return () => window?.removeEventListener('resize', handleResize);
    }
  }, [isMobileScreen, mobNavBtn, isSmallHeight, headerRef]);
  // what happens when we scroll
  const handleScroll = () => {
    if (getBrowserWindow) {
      if (window?.scrollY > 100) {
        setIsHeaderFixed(true);
      } else if (window?.scrollY < 20) {
        setIsHeaderFixed(false);
      }
    }
  };

  // useEffect for listening to scroll event
  useEffect(() => {
    if (getBrowserWindow) {
      if (isScrolled) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
      window?.addEventListener('scroll', handleScroll);
      return () => {
        window?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isScrolled]);

  // height for animation of search options
  useLayoutEffect(() => {
    if (searchOptions) {
      const container = containerRef?.current;
      const containerInner = containerInnerRef?.current;
      const height = containerInner?.scrollHeight + 20;
      container.style.height = `${height}px`;
    } else {
      containerRef.current.style.height = '0';
    }
  }, [searchOptions, isSearchSuggestions, headerSuggestions]);

  // handle click away
  const handleClickAway = (event) => {
    const containerElem = headerContainerRef.current;
    const containerRect = containerElem.getBoundingClientRect();
    const clickX = event.clientX;
    const clickY = event.clientY;
    if (
      clickX < containerRect.left ||
      clickX > containerRect.right ||
      clickY < containerRect.top ||
      clickY > containerRect.bottom
    ) {
      setIsExpanded(false);
      setSearchOptions(false);
      setShowSearch(false);
      setIsSearchSuggestions(false);
      isMobileScreen && setMobNavBtn(true);
    }
  };

  // mobileMenu
  const handleMobileMenu = () => {
    setMobMenuShow(!mobMenuShow);
    setMobMenuCloseBtn(!mobMenuCloseBtn);
    setHideSearchBar(!hideSearchBar);
    mobMenuShow && setIsSubnavOpen(false);
    setIsSubSubnavOpen(false);
  };

  // open Modal
  const openModal = () => {
    setShowModal(true);
  };

  // close Modal
  const closeModal = () => {
    setShowModal(false);
  };

  // open language full Modal
  const openFullModal = () => {
    setShowFullModal(true);
  };

  // close language full Modal
  const closeFullModal = () => {
    setShowFullModal(false);
  };
  const windowVal = useWindowPathName();

  useEffect(() => {
    if (!isSCExperienceEditorActive) {
      setCurrentLanguage(
        getCountryLanguageToDisplay(isMobileScreen, isGlobal, country, language) || 'EN'
      );
    }
  }, [windowVal]);

  const setLocale = (locale) => {
    if (!isSCExperienceEditorActive) {
      setCountryLanguageChoiceToCookie(country, locale); // massage locale value as per country language
      window?.location?.replace(`/${country}/${locale.split('-')[0].toLowerCase()}`);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location?.hash) {
      let elem = document.getElementById('contact-section');
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);
  return (
    // <section className="country-site-header" {...ComponentDataAttributes(componentName)}>
    <>
      <div className={`bg-blur ${isSubnavOpen || searchOptions ? 'active' : ''}`}></div>
      <div
        ref={headerRef}
        className={'header country-header fixed__top white__background'}
        {...ComponentDataAttributes(componentName)}
      >
        <a href="#main" className="skip-to-main-content" aria-label="skip to content">
          skip to content
        </a>
        <div
          ref={headerContainerRef}
          className={`header__container ${searchOptions ? 'search_open' : ''} `}
        >
          {!isMobileScreen ? (
            <div className="header__inner">
              {headerLogoDark?.value?.src && (
                <div className="header__logo">
                  {headerLogoLink?.value?.linktype === 'internal' ? (
                    <Link
                      className="header__logo--link"
                      to={headerLogoLink?.value?.href ? headerLogoLink?.value?.href : '/'}
                      title="Talent Solutions"
                      {...OtherLinkDataAttributes({
                        name: componentName,
                        text: '',
                        url: headerLogoLink?.value?.href,
                        dataItemName: '',
                      })}
                    >
                      <ImageSitecoreField field={headerLogoDark} className="header__logo" />
                    </Link>
                  ) : (
                    <JssLink
                      className="header__logo--link"
                      field={headerLogoLink}
                      {...OtherLinkDataAttributes({
                        name: componentName,
                        text: '',
                        url: headerLogoLink?.value?.href,
                        dataItemName: '',
                      })}
                    >
                      <ImageSitecoreField field={headerLogoDark} className="header__logo" />
                    </JssLink>
                  )}
                </div>
              )}
              <div className="header__search_container">
                <div className="header__links">
                  <Navigation
                    scrolled={isHeaderFixed}
                    mobMenuShow={mobMenuShow}
                    menuItems={topNavigation}
                    onSubnavOpen={handleSubnavOpen}
                    onSubnavClose={handleSubnavClose}
                    mobileMenuScrollTop={mobileMenuScrollTop}
                  />
                </div>
                {!hideSearchBar && !isEmpty(searchIcon?.value) && (
                  <div className="header__search">
                    <SearchBar
                      ref={searchBarRef}
                      scrolled={isHeaderFixed}
                      setSearchOptions={setSearchOptions}
                      setIsSearchSuggestions={setIsSearchSuggestions}
                      setHeaderSuggestions={setHeaderSuggestions}
                      handleClickAway={handleClickAway}
                      showSearch={showSearch}
                      setShowSearch={setShowSearch}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      mobMenuShow={mobMenuShow}
                      handleSearch={handleSearch}
                      componentName={componentName}
                    />
                  </div>
                )}
                {contactUsLink && (
                  <div className="header__cta_desktop">
                    <div className="header__cta1">
                      <ButtonHref
                        href={
                          !isEmpty(contactUsLink?.value?.anchor) ||
                          isEmpty(contactUsLink?.value?.href)
                            ? contactUsLink?.value?.href + contactUsLink?.value?.anchor
                            : contactUsLink?.value?.href
                        }
                        text={contactUsLink?.value?.text}
                        variant="nav-button"
                        arrow={false}
                        linkType={contactUsLink?.value?.linktype}
                        componentName={componentName || 'GlobalHeader'}
                        addMoreClass="button-new"
                        linkFields={contactUsLink}
                      />
                    </div>
                  </div>
                )}
                <div className={'language__cta white__background'}>
                  <button
                    ref={languageBtnRef}
                    className={
                      languageData && languageData?.length > 0
                        ? 'language_button'
                        : 'language_button_no_languages'
                    }
                    onClick={
                      languageData && languageData?.length > 0
                        ? openModal
                        : () => {
                            navigate('/en/countries');
                          }
                    }
                    {...ButtonDataAttributes({
                      name: 'CountrySiteHeader',
                      text: currentLanguage,
                      arrow: false,
                      variant: 'nav-button',
                      dataItemName: '',
                    })}
                  >
                    {Object.keys(languageSwitcherIcon?.value).length > 0 ? (
                      <img
                        src={mediaApi.updateImageUrl(languageSwitcherIcon?.value?.src)}
                        alt={languageSwitcherIcon?.alt}
                      />
                    ) : (
                      <img
                        src={
                          isMobileScreen || isHeaderFixed ? LocationGlobeBlack : LocationGlobeBlack
                        }
                        alt="language"
                      />
                    )}
                    <span className="locale">{currentLanguage}</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="header__inner">
              {!isSubnavOpen ? (
                <div className="header__logo">
                  {headerLogoLink?.value?.linktype === 'internal' ? (
                    <Link
                      className="header__logo--link"
                      to={headerLogoLink?.value?.href}
                      title="Talent Solutions"
                      {...OtherLinkDataAttributes({
                        name: componentName,
                        text: '',
                        url: headerLogoLink?.value?.href,
                        dataItemName: '',
                      })}
                    >
                      <ImageSitecoreField field={headerLogoDark} className="header__logo" />
                    </Link>
                  ) : (
                    <JssLink
                      className="header__logo--link"
                      field={headerLogoLink}
                      {...OtherLinkDataAttributes({
                        name: componentName,
                        text: '',
                        url: headerLogoLink?.value?.href,
                        dataItemName: '',
                      })}
                    >
                      <ImageSitecoreField field={headerLogoDark} className="header__logo" />
                    </JssLink>
                  )}
                </div>
              ) : (
                <div className="header__back">
                  {!isSubSubnavOpen ? (
                    <div
                      className="header__back--btn"
                      onClick={() => {
                        setIsSubnavOpen(false);
                        mobileMenuScrollTop();
                      }}
                    >
                      <span className="back_icon"></span>
                      Menu
                    </div>
                  ) : (
                    <div
                      className="header__back--btn"
                      onClick={() => {
                        setIsSubSubnavOpen(false);
                        mobileMenuScrollTop();
                      }}
                    >
                      <span className="back_icon"></span>
                      Solutions
                    </div>
                  )}
                </div>
              )}

              <div className="header__search_container">
                <div className="header__search">
                  {!hideSearchBar && !isEmpty(searchIcon?.value) && (
                    <SearchBar
                      ref={searchBarRef}
                      scrolled={isHeaderFixed}
                      setSearchOptions={setSearchOptions}
                      setIsSearchSuggestions={setIsSearchSuggestions}
                      setHeaderSuggestions={setHeaderSuggestions}
                      handleClickAway={handleClickAway}
                      showSearch={showSearch}
                      setShowSearch={setShowSearch}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      mobNavBtn={mobNavBtn}
                      setMobNavBtn={setMobNavBtn}
                      componentName={componentName}
                    />
                  )}
                </div>
                {mobNavBtn && (
                  <div className="mobmenu__toggle">
                    {!mobMenuCloseBtn ? (
                      <button
                        className="mobmenu__toggle--btn"
                        onClick={() => {
                          handleMobileMenu();
                        }}
                      >
                        Mobile Nav Toggle
                      </button>
                    ) : (
                      <button
                        className="mobileBtnClose"
                        onClick={() => {
                          handleMobileMenu();
                        }}
                      >
                        Mobile Toggle
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            ref={containerRef}
            className={`header__search_options ${searchOptions ? 'open' : ''} ${
              isSmallHeight ? 'small_height' : ''
            }
          ${showSearch ? 'close_visible' : ''} 
          ${headerSuggestions?.length > 0 && isSearchSuggestions ? 'header_suggestions' : ''}
          `}
          >
            <div ref={containerInnerRef} className="header__inner">
              {searchOptions &&
                (isSearchSuggestions && headerSuggestions?.length > 0 ? (
                  <>
                    <div className="header__search_suggestions">
                      <ul
                        role="listbox"
                        id="header_suggestions-list"
                        aria-label="header suggestions list"
                        className="headerSuggestionsList"
                      >
                        {headerSuggestions &&
                          headerSuggestions?.map((suggestion, index) => (
                            <li
                              key={`header_suggestions_${suggestion}_${index}`}
                              id={`suggestion-${index}`}
                              aria-selected={index === activeSuggestionIndex}
                              className={index === activeSuggestionIndex ? 'activeSuggestion' : ''}
                              onClick={() => {}}
                              ref={(element) => (suggestionRefs.current[index] = element)}
                            >
                              {suggestion}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </>
                ) : (
                  <PopularSearchResultsCard />
                ))}
            </div>
          </div>

          {isMobileScreen && mobMenuShow === true && (
            <div
              ref={mobileMenuContainerRef}
              className={`mobileMenu__container 
            ${isSubnavOpen ? 'subnav_open' : ''}
            ${isSubSubnavOpen ? 'subSubNav_open' : ''}
            `}
            >
              <div className={'mobileMenu__inner  '}>
                <Navigation
                  scrolled={isHeaderFixed}
                  mobMenuShow={mobMenuShow}
                  menuItems={topNavigation}
                  onSubnavOpen={handleSubnavOpen}
                  onSubnavClose={handleSubnavClose}
                  onSubSubnavOpen={handleSubSubnavOpen}
                  onSubSubnavClose={handleSubSubnavClose}
                  handleMobileMenu={handleMobileMenu}
                  mobileMenuScrollTop={mobileMenuScrollTop}
                />
                {contactUsLink?.value && (
                  <div className="header__cta">
                    {contactUsLink?.value?.anchor !== '' ? (
                      <ButtonHref
                        text={contactUsLink?.value?.text}
                        variant="nav-button"
                        arrow={false}
                        linkType={contactUsLink?.value?.linktype}
                        href={
                          !isEmpty(contactUsLink?.value?.anchor) ||
                          isEmpty(contactUsLink?.value?.href)
                            ? contactUsLink?.value?.href + contactUsLink?.value?.anchor
                            : contactUsLink?.value?.href
                        }
                        componentName={componentName || 'GlobalHeader'}
                        addMoreClass="button-new-media"
                        linkFields={contactUsLink}
                        BtnFunction={() => {
                          handleMobileMenu();
                        }}
                      />
                    ) : (
                      <ButtonHref
                        text={contactUsLink?.value?.text}
                        variant="nav-button"
                        arrow={false}
                        linkType={contactUsLink?.value?.linktype}
                        href={contactUsLink?.value?.href}
                        componentName={componentName || 'GlobalHeader'}
                        addMoreClass="button-new-media"
                        linkFields={contactUsLink}
                      />
                    )}
                  </div>
                )}

                <div className={'language__cta white__background'}>
                  <button
                    ref={languageBtnRef}
                    className={
                      languageData && languageData?.length > 0
                        ? 'language_button'
                        : 'language_button_no_languages'
                    }
                    onClick={
                      languageData && languageData?.length > 0
                        ? openModal
                        : () => {
                            navigate('/en/countries');
                          }
                    }
                    {...ButtonDataAttributes({
                      name: 'CountrySiteHeader',
                      text: currentLanguage,
                      arrow: false,
                      variant: 'nav-button',
                      dataItemName: '',
                    })}
                  >
                    {Object.keys(languageSwitcherIcon?.value).length > 0 ? (
                      <img
                        src={mediaApi.updateImageUrl(languageSwitcherIcon?.value?.src)}
                        alt={languageSwitcherIcon?.alt}
                      />
                    ) : (
                      <img
                        src={
                          isMobileScreen || isHeaderFixed ? LocationGlobeBlack : LocationGlobeWhite
                        }
                        alt="language"
                      />
                    )}
                    <span className="locale">{currentLanguage}</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {languageData && languageData.length > 0 && (
          <LanguageModal
            showModal={showModal}
            closeModal={closeModal}
            setLocale={setLocale}
            setShowModal={setShowModal}
            data={languageData && languageData[0]}
            showFullModal={showFullModal}
            openFullModal={openFullModal}
            closeFullModal={closeFullModal}
            setShowFullModal={setShowFullModal}
          />
        )}
      </div>
    </>
    // </section>
  );
};

CountrySiteHeader.defaultProps = {
  fields: {
    headerLogoLight: {},
    headerLogoDark: {},
    topNavigation: [],
    searchIcon: {},
    languageSwitcherIcon: {},
    contactUsLink: {},
    languageData: [],
  },
};

CountrySiteHeader.propTypes = {
  fields: PropTypes.shape({
    headerLogoLight: PropTypes.shape({}),
    headerLogoDark: PropTypes.shape({}),
    topNavigation: PropTypes.arrayOf(PropTypes.shape({})),
    searchIcon: PropTypes.shape({}),
    languageSwitcherIcon: PropTypes.shape({}),
    contactUsLink: PropTypes.shape({}),
    languageData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
export default withSitecoreContext()(CountrySiteHeader);
