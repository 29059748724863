import { postAPI } from '../helper';

export const fetchSearchResult = (
  InsightsFilters,
  recordsPerPage,
  pageNumber,
  language,
  sortPopular,
  sortRelevance,
  searchKeyword
) => {
  const url = '/api/services/TSContentSearch/Search';
  const data = {
    searchKeyword,
    limit: `${recordsPerPage}`,
    offset: `${pageNumber}`,
    sortPopular,
    sortRelevance,
    language: language,
    sortPopular,
    sortRelevance,
    InsightsFilters,
  };
  const result = postAPI(url, data);
  return result;
};
