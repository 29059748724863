import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import './dataBlock.scss';

const DataBlock = ({
  title,
  descLabel,
  description,
  size,
  color,
  chartType,
  useGradient,
  data,
  cta,
  backgroundColor,
}) => {
  let ChartComponent;

  switch (chartType) {
    case 'Bar':
      ChartComponent = lazy(() => import('./BarChart/BarChart'));
      break;
    case 'Bar alt':
      ChartComponent = lazy(() => import('./BarAlt/BarAlt'));
      break;
    case 'Bubble':
      ChartComponent = lazy(() => import('./BubbleChart/BubbleChart'));
      break;
    case 'Doughnut':
      ChartComponent = lazy(() => import('./DoughnutChart/DoughnutChart'));
      break;
    case 'line':
      ChartComponent = lazy(() => import('./LineChart/LineChart'));
      break;
    case 'Linear Gauge':
      ChartComponent = lazy(() => import('./LinearGauge/LinearGauge'));
      break;
    default:
      ChartComponent = lazy(() => import('./BarChart/BarChart'));
      break;
  }

  return (
    <section className={`data-block data-block--${size.toLowerCase()} data-block--${chartType}`}>
      <div className="data-block__container">
        {title && (
          <div className="data-block__title-container">
            <h4 className="data-block__title">{title}</h4>
            {(description || descLabel) && (
              <div className="data-block__desc-container">
                {descLabel && <label className="data-block__label">{descLabel}</label>}
                {description && <p className="data-block__text">{description}</p>}
              </div>
            )}
          </div>
        )}
        <div className="data-block__chart-container">
          <Suspense fallback={<div className="data-block__fallback">Loading Chart...</div>}>
            <ChartComponent
              data={data}
              size={size}
              color={color}
              backgroundColor={backgroundColor}
              useGradient={useGradient}
            />
          </Suspense>
        </div>
      </div>
    </section>
  );
};

DataBlock.defaultProps = {
  color: 'blue-violet',
  size: 'M',
  data: {},
};

DataBlock.propTypes = {
  title: PropTypes.string,
  descLabel: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.oneOf(['blue-violet', 'green-blue']),
  size: PropTypes.oneOf(['Tiny', 'XXS', 'XS', 'S', 'M', 'L', 'XL']),
  chartType: PropTypes.oneOf(['bar', 'Bar alt', 'bubble', 'doughnut', 'line', 'linear-gauge'])
    .isRequired,
  useGradient: PropTypes.bool,
  data: PropTypes.object.isRequired,
  cta: PropTypes.element,
};

export default DataBlock;
