import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, text, onClick, className = '' }) => {
  // eslint-disable-next-line no-unused-vars
  const [errorText, setErrorText] = useState('');
  return (
    <span className="form-block checkbox">
      <input type="checkbox" id={id} onClick={onClick} />
      {errorText && <div className="error-msg">{errorText}</div>}
      <label className={className} htmlFor={id}>
        {text}
      </label>
    </span>
  );
};

Checkbox.defaultProps = {
  id: 'id',
  text: '',
  onClick: () => {},
  className: '',
};

Checkbox.propTypes = {
  /** Field identifier  */
  id: PropTypes.string,

  /** Label text */
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Checkbox;
