import React, { useState, useEffect } from 'react';
import SelectContact from './SelectContact';
import { useNavigate } from 'react-router-dom';
import OverlayLoader from '../ErrorBoundary/OverlayLoader';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ButtonDataAttributes, ComponentDataAttributes } from '../../../constants/gtm';
import './countrySelection.scss';
import { isEmpty, values } from 'lodash';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { enumReducerType } from '../../../state/common';
import {
  getBrowserWindow,
  isSCExperienceEditorActive,
  setCountryLanguageChoiceToCookie,
  setGlobalLanguageToCookie,
} from '../../../utils/HelperUtils';

const ContactUsCountrySelection = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors, submitCount },
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });
  const countryData = useSelector((state) => state.filterReducer?.countryData);
  const [showLoader, setLoader] = useState(false);
  const [clicked, setClicked] = useState(false);
  const { CountrySelection, ctaText, showCTAIcon } = fields;
  const countryVal = countryData?.country !== undefined ? countryData?.country : '';
  const [selectedCountry, setSelectedCountry] = useState('');
  const [defaultLanguageLabel, setdefaultLanguageLabel] = useState([
    {
      displayName: fields?.languageSelectionLabel?.value,
      name: '',
      value: '',
    },
  ]);
  const [countryValues, setCountryValues] = useState([]);
  const [newLanguage, setNewLanguage] = useState(defaultLanguageLabel);
  const [languageValues, setLanguageValues] = useState('');
  const navigate = useNavigate();

  const checkCountrySelected = selectedCountry !== '' ? selectedCountry : countryVal;
  const checkLanguageValueSelected =
    languageValues !== '' ? languageValues : countryData?.countryLanguage;
  useEffect(() => {
    setValue('country', checkCountrySelected, { shouldValidate: false });
    setValue('language', checkLanguageValueSelected, { shouldValidate: false });
  }, [countryData?.countryClick]);
  useEffect(() => {
    if (checkCountrySelected !== '') {
      const availableLanguages =
        !isEmpty(CountrySelection) &&
        CountrySelection.find((item) => checkCountrySelected === item.name);

      const languagesData =
        !isEmpty(availableLanguages) &&
        availableLanguages &&
        Object.keys(availableLanguages?.fields?.languages?.value).map((option) => {
          return {
            displayName: option,
            name: availableLanguages?.fields?.languages?.value[option],
            value: availableLanguages?.fields?.languages?.value[option],
          };
        });
      if (
        languagesData &&
        !isEmpty(languagesData) &&
        languagesData !== undefined &&
        languagesData.length > 1
      ) {
        setNewLanguage([
          {
            displayName: fields?.languageSelectionLabel?.value,
            name: '',
            value: '',
          },
          ...languagesData,
        ]);
      } else {
        languagesData && languagesData !== undefined && setNewLanguage(languagesData),
          setLanguageValues(languagesData[0]?.value);
      }
    }
  }, [selectedCountry]);
  const handleChange = (e) => {
    if (e.target.value === '') {
      setNewLanguage(defaultLanguageLabel);
    } else {
      setNewLanguage(newLanguage);
    }
    setSelectedCountry(e.target.value);
    //  clearErrors(e.target.name);
  };
  const onLanguageSelection = (e) => {
    setLanguageValues(e.target.value);
  };
  if (!isSCExperienceEditorActive) {
    if (getBrowserWindow) {
      let url = window.location.href?.split('/');
      const revUrlValue =
        url <= 5 ? 'global' : url[url.length - 2] + '-' + url[url.length - 3].toUpperCase();
    }
  }
  const dispatch = useDispatch();
  const type = enumReducerType.SET_COUNTRY_DATA;
  let clickedValue = false;

  const onSubmit = () => {
    setSelectedCountry(selectedCountry);
    let revVal;
    let urlSuffix;
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        urlSuffix = window.location.href?.split('/')[window.location.href?.split('/').length - 1];
      }
    }
    if (
      checkLanguageValueSelected &&
      !isEmpty(checkLanguageValueSelected) &&
      checkLanguageValueSelected.includes('-')
    ) {
      const langUrl = `${checkLanguageValueSelected.split('-')[0]}/${
        checkLanguageValueSelected.split('-')[1]
      }`;
      revVal = langUrl?.toLowerCase()?.split('/')?.reverse()?.join('/');
    } else {
      revVal = checkLanguageValueSelected?.toLowerCase();
    }

    clickedValue = true;
    const payload = {
      countryClick: clickedValue,
      country: checkCountrySelected,
      countryLanguage: checkLanguageValueSelected,
    };
    const action = { type, payload };

    dispatch(action);
    if (checkCountrySelected.toLowerCase() === 'global') {
      if (languageValues) {
        Cookies.remove(checkCountrySelected.toLowerCase() + '#lang');
        setGlobalLanguageToCookie(languageValues.toLowerCase());
      }
    } else {
      Cookies.remove('global');
      setCountryLanguageChoiceToCookie(
        checkCountrySelected.toLowerCase(),
        languageValues.toLowerCase()
      );
    }
    navigate(`/${revVal}/${urlSuffix}`);
  };
  const name = 'country';
  const isRequired = true;
  const nameLang = 'language';
  const handleClick = () => {
    const payload = [];
    const action = { type, payload };
    dispatch(action);
    setNewLanguage(defaultLanguageLabel);
    setLanguageValues('');
    setSelectedCountry('');
  };
  if (!isSCExperienceEditorActive) {
    if (getBrowserWindow) {
      countryData?.countryClick
        ? setTimeout(() => {
            document
              ?.getElementById('countryForm')
              ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 1000)
        : '';
    }
  }

  return (
    <section
      className="global-contact contact contact-section"
      id="countryForm"
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl contact-container">
        <div className="col-lg-10  col-xl-7 col-md-12">
          <h2 className="title contact-title">{fields?.Header?.value}</h2>
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="contact-column-12">
                <div className="section-contact-form">
                  <div className="section-1">
                    {/* <SelectContact labelText="Label" options={countrySelection} cssClass={''} /> */}
                    <SelectContact
                      handleChange={handleChange}
                      countryClick={countryData?.countryClick}
                      countryDataVal={countryData?.country}
                      labelText={fields?.countrySelectionLabel?.value}
                      options={CountrySelection}
                      cssClass={'label'}
                      title={fields?.countrySelectionLabel?.value}
                      isRequiredField={isRequired}
                      reg={{
                        ...register(`${name}`, {
                          onChange: handleChange,
                          required: isRequired
                            ? `${fields?.countrySelectionValidationMessage?.value}`
                            : '',
                        }),
                      }}
                      errors={errors[`${name}`]}
                    />
                    {newLanguage && newLanguage.length > 2 && (
                      <SelectContact
                        countryDataVal={languageValues}
                        countryClick={countryData?.countryClick}
                        labelText={fields?.languageSelectionLabel?.value}
                        title={fields?.languageSelectionLabel?.value}
                        handleChange={onLanguageSelection}
                        options={newLanguage}
                        cssClass={'label'}
                        reg={{
                          ...register(`${nameLang}`, {
                            onChange: onLanguageSelection,
                            required: isRequired
                              ? `${fields?.languageSelectionVaildationMessage?.value}`
                              : '',
                          }),
                        }}
                        isRequiredField={isRequired}
                        errors={errors[`${nameLang}`]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showLoader && <OverlayLoader />}
            <div className="form-footer-wraper">
              {fields?.consentText?.value !== '' && (
                <div className="contact-form-footer form-footer"></div>
              )}

              {countryData && (countryData?.length <= 0 || countryData.countryClick === false) && (
                <button
                  className="button primary-button violet medium icon__right signpost contact-submit"
                  type="submit"
                  {...ButtonDataAttributes({
                    name: componentName,
                    text: `${fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}`,
                    variant: 'primary-button',
                    arrow: true,
                    dataItemName: 'contactusForm',
                  })}
                >
                  {fields?.ctaText?.value ? fields?.ctaText?.value : 'Submit'}{' '}
                  <div className="arrow-icon"></div>
                </button>
              )}
            </div>
          </form>
          {countryData &&
            countryData?.countryClick !== undefined &&
            countryData.countryClick === true && (
              <button
                className="button  text-button small signpost"
                type="click"
                onClick={handleClick}
                {...ButtonDataAttributes({
                  name: componentName,
                  text: `${
                    fields?.changeCountryorLanguageLabel?.value
                      ? fields?.changeCountryorLanguageLabel?.value
                      : 'Submit'
                  }`,
                  variant: 'primary-button',
                  arrow: true,
                  dataItemName: 'contactusForm',
                })}
              >
                {fields?.changeCountryorLanguageLabel?.value
                  ? fields?.changeCountryorLanguageLabel?.value
                  : 'Submit'}
              </button>
            )}
        </div>
      </div>
    </section>
  );
};
export default ContactUsCountrySelection;
ContactUsCountrySelection.defaultProps = {
  fields: {
    countrySelection: [
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          redirectUrl: {
            value: {
              href: '',
            },
          },
          languages: {
            value: '',
          },
        },
      },
    ],
  },
};
ContactUsCountrySelection.propTypes = {
  fields: PropTypes.shape({}),
};
