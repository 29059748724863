import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import {
  Placeholder,
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';
import { isEmpty } from 'lodash';
import BreadcrumbClass from '../Breadcrumb/Breadcrumb';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { breakpoints } from '../../../scripts/breakpoints';

const BannerInsightsCarousel = (props) => {
  const {
    fields,
    Breadcrumb = [],
    rendering: { componentName = '' },
  } = props;
  /** * primary is hardcoded, should check with Sitecore if backgroundColor will be provided */
  // const backgroundTextColor = 'white';
  const { backgroundImage, backgroundImageMobile, title, backgroundTextColor } = fields;
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });
  const getSize = () => {
    if (isMobile) {
      return 'isMobile';
    }
    return 'isDesktop';
  };

  const breadcrumbItems =
    Breadcrumb && Array.isArray(Breadcrumb) && Breadcrumb[0]?.props?.fields?.data?.item;
  return (
    <section
      className={`banner-section standard banner insights  ${backgroundTextColor?.fields?.text?.value}`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          isMobile && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageMedium={backgroundImageMobile}
        imageLarge={backgroundImage}
        imageXLarge={backgroundImage}
      />
      <div className="container-xl">
        <div className="row content-wrapper padding-xs">
          <div className="col-xl-8 col-lg-7 col-md-7">
            {!isEmpty(breadcrumbItems) && (
              <BreadcrumbClass
                items={breadcrumbItems}
                cssClass={`${
                  backgroundTextColor?.fields?.text?.value === 'primary'
                    ? 'on-primary'
                    : 'on-secondary'
                }`}
                componentName={componentName}
                // textColor={`${
                //   backgroundTextColor?.fields?.text?.value === 'primary' ? 'primary' : 'secondary'
                // }`}
              />
            )}
            <h1
              className={`title ${
                backgroundTextColor?.fields?.text?.value === 'secondary' &&
                getSize() === 'isDesktop'
                  ? 'on-secondary'
                  : 'on-primary'
              }`}
            >
              <TextSitecoreField field={title} />
            </h1>
          </div>
        </div>
      </div>
      <Placeholder name="jss-banner-carousel" rendering={props.rendering} />
    </section>
  );
};

const BannerInsightsCarouselContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'Breadcrumb' }])(BannerInsightsCarousel)
);

BannerInsightsCarousel.defaultProps = {
  Breadcrumb: [],
  fields: {
    backgroundImageMobile: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    title: {
      value: '',
    },
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
  },

  backgroundTextColor: {
    fields: {
      text: {
        value: 'primary',
      },
    },
  },
};

BannerInsightsCarousel.propTypes = {
  Breadcrumb: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({
    backgroundImageMobile: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
  }),
  backgroundTextColor: PropTypes.shape({
    value: PropTypes.string,
  }),
};

export default BannerInsightsCarouselContainer;
