import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Navigation from './Navigation/Navigation';
import SearchBar from './SearchBar/SearchBar';
import PopularSearchResultsCard from '../PopularSearchResultsCard/PopularSearchResultsCard';
import LanguageModal from '../MultiLanguageModal/LanguageModal';
import LocationGlobeBlack from '../MultiLanguageModal/images/icon-location-global-black.svg';
import LocationGlobeWhite from '../MultiLanguageModal/images/icon-location-global-white.svg';
import './header.scss';
import PropTypes from 'prop-types';
import {
  getBrowserWindow,
  getCountryLanguageToDisplay,
  getLanguageConfig,
  isBrowserDocumentAvailable,
  isSCExperienceEditorActive,
  setCountryLanguageChoiceToCookie,
  setGlobalLanguageToCookie,
} from '../../../utils/HelperUtils';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import Button from '../../../globals/buttons/Button';
import {
  ButtonDataAttributes,
  ComponentDataAttributes,
  OtherLinkDataAttributes,
} from '../../../constants/gtm';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import { useWindowPathName } from '../../../hooks/useWindowPathName';

const Header = (props) => {
  const {
    fields = {},
    rendering: { componentName = '' },
    sitecoreContext,
  } = props;
  const {
    HeaderLogoDark,
    HeaderLogoLight,
    ContactUsLink = {},
    LanguageData = [],
    SearchIcon = {},
    TopNavigation = [],
    LanguageSwitcherIcon = {},
    popularSearchKeywords = [],
    popularSearchText = {},
    searchButtonText = {},
  } = fields;
  // Check if mobile screen
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  const { isGlobal, country, language } = getBrowserWindow
    ? getLanguageConfig(window?.location?.pathname)
    : { isGlobal: true, country: 'Global' };
  const languageToDisplay = getCountryLanguageToDisplay({
    isMobileScreen,
    isGlobal,
    country,
    language,
  });
  const containerRef = useRef(null);
  const containerInnerRef = useRef(null);
  const languageBtnRef = useRef(null);
  const headerContainerRef = useRef(null);
  const mobileMenuContainerRef = useRef(null);
  const headerRef = useRef(null);

  const searchBarRef = useRef();
  const suggestionRefs = useRef([]);
  const inputRef = useRef(null);

  const [currentLanguage, setCurrentLanguage] = useState(languageToDisplay);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const [searchOptions, setSearchOptions] = useState(false);
  const [headerSuggestions, setHeaderSuggestions] = useState([]);
  const [hearderSearchData, setHearderSearchData] = useState([]);
  const [isSearchSuggestions, setIsSearchSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [searchQuery, setsearchQuery] = useState('');

  const [isExpanded, setIsExpanded] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showFullModal, setShowFullModal] = useState(false);

  const [mobMenuShow, setMobMenuShow] = useState(false);
  const [mobNavBtn, setMobNavBtn] = useState(false);
  const [mobMenuCloseBtn, setMobMenuCloseBtn] = useState(false);
  const [hideSearchBar, setHideSearchBar] = useState(false);

  const [isSmallHeight, setIsSmallHeight] = useState(false);

  const [isSubnavOpen, setIsSubnavOpen] = useState(false);
  const [isSubSubnavOpen, setIsSubSubnavOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  const navigate = useNavigate();
  const queryParams = getBrowserWindow ? new URLSearchParams(location.search) : '';
  const locationURL = useLocation();

  const mobileMenuScrollTop = () => {
    if (isBrowserDocumentAvailable) {
      const mainMenuScrollTo = document?.querySelector('.main_menu_scrollto');
      mainMenuScrollTo?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const scrollToContactForm = () => {
    const mainMenuScrollTo = document?.querySelector('#contact-section');
    mainMenuScrollTo?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (!isSCExperienceEditorActive) {
      const lastURLParams = locationURL.pathname.slice(
        locationURL.pathname.lastIndexOf('/'),
        locationURL.pathname.length
      );
      // lastURLParams === '/contact-us' ? scrollToContactForm() : '';
    }
  }, []);

  function handleSubnavOpen() {
    setIsSubnavOpen(true);
  }

  function handleSubnavClose() {
    setIsSubnavOpen(false);
  }
  function handleSubSubnavOpen() {
    setIsSubSubnavOpen(true);
  }

  function handleSubSubnavClose() {
    setIsSubSubnavOpen(false);
  }

  const handleResize = () => {
    if (getBrowserWindow) {
      setHeaderHeight(headerRef.current.clientHeight);
      setIsSmallHeight(window && window?.innerHeight < 600);
      if (isMobileScreen) {
        setMobNavBtn(true);
      } else {
        setMobNavBtn(false);
        setMobMenuShow(false);
        setHideSearchBar(false);
        setIsSubnavOpen(false);
        setMobMenuCloseBtn(false);
      }
    }
  };

  const handleSearch = (event) => {
    const { key } = event;

    // Handle tab key event
    const handleTab = () => {
      event.preventDefault();
      if (activeSuggestionIndex === -1) {
        // if the user presses tab, select the first suggestion
        setActiveSuggestionIndex(0);
      } else {
        // Focus the search button
        setActiveSuggestionIndex(-1);
        searchBarRef.current.focusSearchButton();
      }
    };

    // Handle scrolling the suggestions list and the offset
    const handleSuggestionsScroll = (offset) => {
      // const selectedSuggestion = suggestionRefs?.current[activeSuggestionIndex];
      // const suggestionsList = selectedSuggestion?.parentNode;
      // const { scrollTop, clientHeight } = suggestionsList;
      // const selectedTop = selectedSuggestion?.offsetTop - offset;
      // const selectedBottom = selectedTop + selectedSuggestion?.clientHeight;
      // if (selectedBottom > scrollTop + clientHeight) {
      //   if (suggestionsList) {
      //     suggestionsList.scrollTop = selectedBottom - clientHeight;
      //   }
      // } else if (selectedTop < scrollTop) {
      //   if (suggestionsList) {
      //     suggestionsList.scrollTop = selectedTop;
      //   }
      // }
    };

    // Handle arrow down key event
    const handleArrowDown = () => {
      event.preventDefault();
      if (activeSuggestionIndex < headerSuggestions?.length - 1) {
        // Navigate down the list
        setActiveSuggestionIndex((prev) => prev + 1);
        handleSuggestionsScroll(-100);
      }
    };

    // Handle arrow up key event
    const handleArrowUp = () => {
      event.preventDefault();
      if (activeSuggestionIndex > -1) {
        if (activeSuggestionIndex === 0) {
          // if we're at the top of the list, go back to the input field
          setActiveSuggestionIndex(-1);
          // inputRef.current.focus();
          return;
        }
        // Navigate up the list
        setActiveSuggestionIndex((prev) => prev - 1);
        handleSuggestionsScroll(100);
      }
    };

    // Handle enter key event
    const handleEnter = () => {
      // event.preventDefault();
      if (activeSuggestionIndex > -1) {
        setActiveSuggestionIndex(-1);
        setIsExpanded(false);
        setSearchOptions(false);
        setShowSearch(false);
        setIsSearchSuggestions(false);
        isMobileScreen && setMobNavBtn(true);
        if (searchQuery !== '') {
          queryParams.set('search', hearderSearchData[activeSuggestionIndex]);
          const newSearch = `?${queryParams.toString()}`;
          navigate({ pathname: '/site-search', search: newSearch });
          location?.pathname === '/site-search' ? navigate(0) : '';
        }
      } else {
        if (searchQuery !== '') {
          queryParams.set('search', searchQuery);
          const newSearch = `?${queryParams.toString()}`;
          navigate({ pathname: '/site-search', search: newSearch });
          location?.pathname === '/site-search' ? navigate(0) : '';
        }
      }
    };

    // Handle search with no suggestions
    const handleSearchNoSuggestions = () => {
      // perform search with query
      // TODO: Insert Search Functionality Here
      // alert(`Searching for ${query}`);
    };

    if (isSearchSuggestions) {
      // Handle key events when suggestions are visible
      switch (key) {
        case 'Tab':
          handleTab();
          break;
        case 'ArrowDown':
          handleArrowDown();
          break;
        case 'ArrowUp':
          handleArrowUp();
          break;
        case 'Enter':
          handleEnter();
          break;
        default:
          break;
      }
    } else if (key === 'Enter') {
      // Handle key event when suggestions are not visible
      handleSearchNoSuggestions();
    }
  };

  // useEffect for listening to resize event
  useEffect(() => {
    if (getBrowserWindow) {
      handleResize();
      window?.addEventListener('resize', handleResize);
      return () => getBrowserWindow && window?.removeEventListener('resize', handleResize);
    }
  }, [isMobileScreen, mobNavBtn, isSmallHeight, headerRef]);
  // what happens when we scroll
  const handleScroll = () => {
    if (getBrowserWindow) {
      if (window?.scrollY > 100) {
        setIsHeaderFixed(true);
      } else if (window?.scrollY < 20) {
        setIsHeaderFixed(false);
      }
    }
  };

  // useEffect for listening to scroll event
  useEffect(() => {
    if (getBrowserWindow) {
      if (isScrolled) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
      window?.addEventListener('scroll', handleScroll);
      return () => window?.removeEventListener('scroll', handleScroll);
    }
  }, [isScrolled]);

  // height for animation of search options
  useLayoutEffect(() => {
    if (searchOptions) {
      const container = containerRef.current;
      const containerInner = containerInnerRef.current;
      const height = containerInner.scrollHeight + 20;
      container.style.height = `${height}px`;
    } else {
      containerRef.current.style.height = '0';
    }
  }, [searchOptions, isSearchSuggestions, headerSuggestions]);

  // handle click away
  const handleClickAway = (event) => {
    const containerElem = headerContainerRef.current;
    const containerRect = containerElem.getBoundingClientRect();
    const clickX = event.clientX;
    const clickY = event.clientY;
    if (
      clickX < containerRect.left ||
      clickX > containerRect.right ||
      clickY < containerRect.top ||
      clickY > containerRect.bottom
    ) {
      setIsExpanded(false);
      setSearchOptions(false);
      setShowSearch(false);
      setIsSearchSuggestions(false);
      isMobileScreen && setMobNavBtn(true);
    }
  };

  // mobileMenu
  const handleMobileMenu = () => {
    setMobMenuShow(!mobMenuShow);
    setMobMenuCloseBtn(!mobMenuCloseBtn);
    setHideSearchBar(!hideSearchBar);
    mobMenuShow && setIsSubnavOpen(false);
    setIsSubSubnavOpen(false);
  };

  // open Modal
  const openModal = () => {
    setShowModal(true);
  };

  // close Modal
  const closeModal = () => {
    setShowModal(false);
  };
  const windowVal = useWindowPathName();

  // get Cookie values
  useEffect(() => {
    if (!isSCExperienceEditorActive) {
      setCurrentLanguage(
        getCountryLanguageToDisplay(isMobileScreen, isGlobal, country, language) || 'EN'
      );
    }
  }, [windowVal]);

  const setLocale = (locale) => {
    if (!isSCExperienceEditorActive) {
      if (isGlobal) {
        // set the global cookie with the selected locale and reload the page
        setGlobalLanguageToCookie(locale); // massage locale value as per global language
        // window?.location?.reload();
        window?.location?.replace(`/${locale}/`);
      } else {
        setCountryLanguageChoiceToCookie(country, locale); // massage locale value as per country language
        window?.location?.replace(`/${country}/${locale}`);
      }
    }
  };
  const baseUrl =
    sitecoreContext?.language && !sitecoreContext?.language.includes('-')
      ? `/${sitecoreContext?.language}`
      : '/';
  return (
    <>
      <div className={`bg-blur ${isSubnavOpen || searchOptions ? 'active' : ''}`}></div>
      <div
        ref={headerRef}
        className={`header ${isHeaderFixed ? 'fixed__top' : ''} ${
          mobMenuShow || isHeaderFixed ? 'white__background' : ''
        }  `}
        {...ComponentDataAttributes(componentName)}
      >
        <a href="#main" className="skip-to-main-content" aria-label="skip to content">
          skip to content
        </a>
        <div
          ref={headerContainerRef}
          className={`header__container ${searchOptions ? 'search_open' : ''} `}
        >
          {!isMobileScreen ? (
            <div className="header__inner">
              <div className="header__logo">
                <Link
                  to={baseUrl}
                  title="Talent Solutions"
                  className="header__logo--link"
                  {...OtherLinkDataAttributes({
                    name: componentName,
                    text: '',
                    url: isHeaderFixed ? HeaderLogoDark.value.src : HeaderLogoLight.value.src,
                    dataItemName: '',
                  })}
                >
                  {isHeaderFixed ? (
                    <ImageSitecoreField field={HeaderLogoDark} className="header__logo" />
                  ) : (
                    <ImageSitecoreField field={HeaderLogoLight} className="header__logo" />
                  )}
                </Link>
              </div>
              <div className="header__search_container">
                <div className="header__links">
                  <Navigation
                    scrolled={isHeaderFixed}
                    mobMenuShow={mobMenuShow}
                    menuItems={TopNavigation}
                    onSubnavOpen={handleSubnavOpen}
                    onSubnavClose={handleSubnavClose}
                    mobileMenuScrollTop={mobileMenuScrollTop}
                    componentName={componentName}
                  />
                </div>

                <div className="header__search">
                  {!hideSearchBar && (
                    <SearchBar
                      ref={searchBarRef}
                      scrolled={isHeaderFixed}
                      setSearchOptions={setSearchOptions}
                      setIsSearchSuggestions={setIsSearchSuggestions}
                      setHeaderSuggestions={setHeaderSuggestions}
                      setHearderSearchData={setHearderSearchData}
                      handleClickAway={handleClickAway}
                      showSearch={showSearch}
                      setShowSearch={setShowSearch}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      mobMenuShow={mobMenuShow}
                      handleSearch={handleSearch}
                      searchButtonText={searchButtonText}
                      setsearchQuery={setsearchQuery}
                      componentName={componentName}
                    />
                  )}
                </div>
                {ContactUsLink && (
                  <div className="header__cta_desktop">
                    <div className="header__cta">
                      {ContactUsLink?.value?.anchor !== '' ? (
                        <Button
                          text={ContactUsLink?.value?.text}
                          variant="nav-button"
                          arrow={false}
                          linkType={ContactUsLink?.value?.linktype}
                          componentName={componentName || 'GlobalHeader'}
                          BtnFunction={() => {
                            if (isBrowserDocumentAvailable) {
                              const contactusScrollTo = document?.querySelector(
                                `${
                                  ContactUsLink?.value?.anchor
                                    ? ContactUsLink?.value?.anchor
                                    : '#contact-section'
                                }`
                              );
                              contactusScrollTo?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                              });
                            }
                          }}
                          addMoreClass="button-new"
                        />
                      ) : (
                        <ButtonHref
                          text={ContactUsLink?.value?.text}
                          variant="nav-button"
                          arrow={false}
                          linkType={ContactUsLink?.value?.linktype}
                          href={ContactUsLink?.value}
                          linkFields={ContactUsLink}
                          componentName={componentName || 'GlobalHeader'}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`language__cta ${
                    mobMenuShow || isHeaderFixed ? 'white__background' : ''
                  }`}
                >
                  <button
                    ref={languageBtnRef}
                    className={
                      LanguageData && LanguageData?.length > 0
                        ? 'language_button'
                        : 'language_button_no_languages'
                    }
                    onClick={LanguageData && LanguageData?.length > 0 ? openModal : () => {}}
                    {...ButtonDataAttributes({
                      name: componentName,
                      text: currentLanguage,
                      arrow: false,
                      variant: 'nav-button',
                      dataItemName: '',
                    })}
                  >
                    {Object.keys(LanguageSwitcherIcon?.value).length > 0 ? (
                      <img
                        src={mediaApi.updateImageUrl(LanguageSwitcherIcon?.value?.src)}
                        alt={LanguageSwitcherIcon?.alt}
                      />
                    ) : (
                      <img
                        src={
                          isMobileScreen || isHeaderFixed ? LocationGlobeBlack : LocationGlobeWhite
                        }
                        alt="language"
                      />
                    )}
                    <span className="locale">{currentLanguage}</span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="header__inner">
              {!isSubnavOpen ? (
                <div className="header__logo">
                  <Link
                    to={'/'}
                    title="Talent Solutions"
                    className="header__logo--link"
                    {...OtherLinkDataAttributes({
                      name: componentName,
                      text: '',
                      url: isHeaderFixed ? HeaderLogoDark.value.src : HeaderLogoLight.value.src,
                      dataItemName: '',
                    })}
                  >
                    {isHeaderFixed ? (
                      <ImageSitecoreField field={HeaderLogoDark} className="header__logo" />
                    ) : (
                      <ImageSitecoreField field={HeaderLogoLight} className="header__logo" />
                    )}
                  </Link>
                </div>
              ) : (
                <div className="header__back">
                  {!isSubSubnavOpen ? (
                    <div
                      className="header__back--btn"
                      onClick={() => {
                        setIsSubnavOpen(false);
                        mobileMenuScrollTop();
                      }}
                    >
                      <span className="back_icon"></span>
                      Menu
                    </div>
                  ) : (
                    <div
                      className="header__back--btn"
                      onClick={() => {
                        setIsSubSubnavOpen(false);
                        mobileMenuScrollTop();
                      }}
                    >
                      <span className="back_icon"></span>
                      Solutions
                    </div>
                  )}
                </div>
              )}

              <div className="header__search_container">
                <div className="header__search">
                  {!hideSearchBar && (
                    <SearchBar
                      ref={searchBarRef}
                      scrolled={isHeaderFixed}
                      setSearchOptions={setSearchOptions}
                      setIsSearchSuggestions={setIsSearchSuggestions}
                      setHeaderSuggestions={setHeaderSuggestions}
                      setHearderSearchData={setHearderSearchData}
                      handleClickAway={handleClickAway}
                      showSearch={showSearch}
                      setShowSearch={setShowSearch}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      mobNavBtn={mobNavBtn}
                      setMobNavBtn={setMobNavBtn}
                      searchButtonText={searchButtonText}
                      setsearchQuery={setsearchQuery}
                      componentName={componentName}
                    />
                  )}
                </div>
                {mobNavBtn && (
                  <div className="mobmenu__toggle">
                    {!mobMenuCloseBtn ? (
                      <button
                        className="mobmenu__toggle--btn"
                        onClick={() => {
                          handleMobileMenu();
                        }}
                        {...ButtonDataAttributes({
                          name: componentName,
                          dataItemName: 'mobmenu__toggle--btn',
                        })}
                      >
                        Mobile Nav Toggle
                      </button>
                    ) : (
                      <button
                        className="mobileBtnClose"
                        onClick={() => {
                          handleMobileMenu();
                        }}
                        {...ButtonDataAttributes({
                          name: componentName,
                          dataItemName: 'mobileBtnClose',
                        })}
                      >
                        Mobile Toggle
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            ref={containerRef}
            className={`header__search_options ${searchOptions ? 'open' : ''} ${
              isSmallHeight ? 'small_height' : ''
            }
          ${showSearch ? 'close_visible' : ''} 
          ${headerSuggestions?.length > 0 && isSearchSuggestions ? 'header_suggestions' : ''}
          `}
          >
            <div ref={containerInnerRef} className="header__inner">
              {searchOptions &&
                (isSearchSuggestions && headerSuggestions?.length > 0 ? (
                  <>
                    <div className="header__search_suggestions">
                      <ul
                        role="listbox"
                        id="header_suggestions-list"
                        aria-label="header suggestions list"
                        className="headerSuggestionsList"
                      >
                        {headerSuggestions &&
                          headerSuggestions?.map((suggestion, index) => {
                            return (
                              <li
                                key={`header_suggestions_${suggestion}_${index}`}
                                id={`suggestion-${index}`}
                                aria-selected={index === activeSuggestionIndex}
                                className={
                                  index === activeSuggestionIndex ? 'activeSuggestion' : ''
                                }
                                onClick={() => {
                                  if (!isSCExperienceEditorActive) {
                                    const searchQuer =
                                      suggestion?.props?.children[1]?.props?.children +
                                      '' +
                                      suggestion?.props?.children[2];
                                    queryParams.set('search', searchQuer);
                                    // create new search string and navigate to it
                                    const newSearch = `?${queryParams.toString()}`;
                                    navigate({ pathname: '/site-search', search: newSearch });
                                    location?.pathname === '/site-search' ? navigate(0) : ''; // navigate(0);
                                  }
                                }}
                                ref={(element) => (suggestionRefs.current[index] = element)}
                              >
                                {suggestion}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </>
                ) : (
                  <PopularSearchResultsCard
                    popularSearchKeywords={popularSearchKeywords}
                    popularSearchText={popularSearchText}
                  />
                ))}
            </div>
          </div>

          {isMobileScreen && mobMenuShow === true && (
            <div
              ref={mobileMenuContainerRef}
              className={`mobileMenu__container 
            ${isSubnavOpen ? 'subnav_open' : ''}
            ${isSubSubnavOpen ? 'subSubNav_open' : ''}
            `}
            >
              <div className={'mobileMenu__inner  '}>
                <Navigation
                  scrolled={isHeaderFixed}
                  mobMenuShow={mobMenuShow}
                  menuItems={TopNavigation}
                  onSubnavOpen={handleSubnavOpen}
                  onSubnavClose={handleSubnavClose}
                  onSubSubnavOpen={handleSubSubnavOpen}
                  onSubSubnavClose={handleSubSubnavClose}
                  handleMobileMenu={handleMobileMenu}
                  mobileMenuScrollTop={mobileMenuScrollTop}
                />
                {ContactUsLink && (
                  <div className="header__cta">
                    {ContactUsLink?.value?.anchor !== '' ? (
                      <Button
                        text={ContactUsLink?.value?.text}
                        variant="nav-button"
                        arrow={false}
                        linkType={ContactUsLink?.value?.linktype}
                        componentName={componentName || 'GlobalHeader'}
                        BtnFunction={() => {
                          handleMobileMenu();
                          if (isBrowserDocumentAvailable) {
                            const contactusScrollTo = document?.querySelector(
                              `${
                                ContactUsLink?.value?.anchor
                                  ? ContactUsLink?.value?.anchor
                                  : '#contact-section'
                              }`
                            );
                            contactusScrollTo?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            });
                          }
                        }}
                        addMoreClass="button-new-media"
                      />
                    ) : (
                      <ButtonHref
                        text={ContactUsLink?.value?.text}
                        variant="nav-button"
                        arrow={false}
                        linkType={ContactUsLink?.value?.linktype}
                        href={ContactUsLink?.value?.href}
                        componentName={componentName || 'GlobalHeader'}
                        addMoreClass="button-new-media mobile"
                        linkFields={ContactUsLink}
                      />
                    )}
                  </div>
                )}
                <div className={`language__cta ${mobMenuShow ? 'white__background' : ''}`}>
                  <button
                    ref={languageBtnRef}
                    className={
                      LanguageData && LanguageData?.length > 0
                        ? 'language_button'
                        : 'language_button_no_languages'
                    }
                    onClick={LanguageData && LanguageData?.length > 0 ? openModal : () => {}}
                    {...ButtonDataAttributes({
                      name: componentName,
                      text: currentLanguage,
                      arrow: false,
                      variant: 'nav-button',
                      dataItemName: '',
                    })}
                  >
                    {Object.keys(LanguageSwitcherIcon?.value).length > 0 ? (
                      <img
                        src={mediaApi.updateImageUrl(LanguageSwitcherIcon?.value?.src)}
                        alt={LanguageSwitcherIcon?.alt}
                      />
                    ) : (
                      <img
                        src={
                          isMobileScreen || isHeaderFixed ? LocationGlobeBlack : LocationGlobeWhite
                        }
                        alt="language"
                      />
                    )}
                    <span className="locale">{currentLanguage}</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {fields?.LanguageData && fields?.LanguageData?.length > 0 && (
          <LanguageModal
            showModal={showModal}
            closeModal={closeModal}
            setLocale={setLocale}
            setShowModal={setShowModal}
            data={fields?.LanguageData && fields?.LanguageData[0]}
            showFullModal={showFullModal}
            setShowFullModal={setShowFullModal}
          />
        )}
      </div>
    </>
  );
};

Header.defaultProps = {
  fields: {
    HeaderLogoDark: {},
    HeaderLogoLight: {},
    ContactUsLink: {},
    LanguageData: [],
    SearchIcon: {},
    TopNavigation: [],
    LanguageSwitcherIcon: {},
  },
};

Header.propTypes = {
  fields: PropTypes.shape({
    HeaderLogoDark: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    HeaderLogoLight: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    ContactUsLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        linktype: PropTypes.string,
      }),
    }),
    LanguageData: PropTypes.arrayOf(PropTypes.object),
    SearchIcon: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
    TopNavigation: PropTypes.arrayOf(PropTypes.object),
    LanguageSwitcherIcon: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
  }),
};

export default withSitecoreContext()(Header);
