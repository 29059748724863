import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../../../src/globals/buttons/Button';
import Tag from '../../../../globals/buttons/Tag';
import { OtherLinkDataAttributes } from '../../../../constants/gtm';
import ImageSitecoreField from '../../../sitecorefields/ImageSitecoreField';
import { useMediaQuery } from 'react-responsive';
import ManageContentTypeTags from '../../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags } from '../../../../utils/HelperUtils';
import { breakpoints } from '../../../../scripts/breakpoints';
import RichTextSitecoreField from '../../../sitecorefields/RichTextSitecoreField';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
const CardInsightsCarouselFull = ({
  title,
  ctaUrl,
  cssClass,
  contentType = [],
  backgroundColor,
  isShowGlobalButton,
  image,
  componentName = '',
  item,
  fields,
}) => {
  const [ct, setCt] = useState([]);
  const [showTags, setShowTags] = useState([]);
  const selectorCT = useSelector((state) => state?.masterReducer?.allcontenttypes);
  const selectorShowTags = useSelector((state) => state?.masterReducer?.carouselshowtags);
  useEffect(() => {
    if (selectorCT && selectorCT.length > 0) {
      const filteredCT = contentType
        .map((item) => selectorCT.filter((curr) => curr.id === item).map((ct) => ct.name))
        .flat();
      setCt(filteredCT);
    }
  }, [selectorCT]);
  useEffect(() => {
    setShowTags(selectorShowTags);
  }, [selectorShowTags]);
  // Screen width function
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    <a
      href={ctaUrl}
      className={`card card__full ${cssClass} ${backgroundColor}`}
      style={{ boxShadow: 'none' }}
      {...OtherLinkDataAttributes({
        name: componentName,
        dataItemName: title || '...',
      })}
    >
      {image?.value?.src && (
        <div
          className={`${!isMobileScreen ? 'card-body-image-carousel' : 'card-body-image-carousel'}`}
        >
          <ImageSitecoreField className="article-image-object-fit" field={image} />
          <button className="body-card-button">Latest</button>
        </div>
      )}
      <div className={'card-body card-body-carousel related-insights-carousal'}>
        <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
        <div className={`card-content-carousel flex-column ${backgroundColor} `}>
          {item?.fields?.subTitle?.value && !isMobileScreen && (
            <RichTextSitecoreField
              field={item?.fields?.subTitle}
              className="sub-title-related-insights-carousal"
            />
          )}

          {item?.fields?.Title?.value && (
            <h3 className={isMobileScreen ? 'mobtitle' : 'title twi-featured-title'}>
              <TextSitecoreField field={item?.fields?.Title} className="title" />
            </h3>
          )}

          {item?.fields?.Content?.value && (
            <div className="para twi-featured-para">
              <RichTextSitecoreField className="rich-text-alert" field={item?.fields?.Content} />
            </div>
          )}
        </div>
        <div className="related-solutions related-insights">
          {showTags &&
            showTags?.map((tag, index) => (
              <Tag
                key={`${index}-${tag?.name}`}
                cssClass={tag?.cssClass}
                fontSize="small"
                icon={tag?.fields?.icon?.value}
                text={tag.name}
                componentName={componentName}
                dataItemName={title}
              />
            ))}
          <div>
            {isShowGlobalButton && (
              <Button
                text="Global"
                cssClass="tag card-global"
                fontSize="small"
                componentName={componentName}
                dataItemName={title}
              />
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

CardInsightsCarouselFull.defaultProps = {
  latest: '',
  altText: '',
  contentTags: [],
  contentType: [],
  date: '',
  subTitle: '',
  title: '',
  text: '',
  ctaUrl: '',
  cssClass: 'slide',
  backgroundColor: 'primary',
  solutionsTags: [],
  image: {},
  isShowGlobalButton: false,
};

CardInsightsCarouselFull.propTypes = {
  altText: PropTypes.string,
  latest: PropTypes.string,
  /** Readtime of runtime tag */
  length: PropTypes.string,
  /** Content tag (i.e. webinar, article, or movie etc.) */
  contentTags: PropTypes.array,
  contentType: PropTypes.string,
  date: PropTypes.string,
  subTitle: PropTypes.string,
  cssClass: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  solutionsTags: PropTypes.array,
  image: PropTypes.shape({}),
  isShowGlobalButton: PropTypes.bool,
};

export default CardInsightsCarouselFull;
