import { enumReducerType } from '../common';

export const searchfilterReducer = (
  state = {
    filts: [],
    selectedsearchfilts: [],
    searchedsearchresultdata: [],
    filterpayloadsearchinsight: '',
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.SET_SEARCH_FILTER_SELECTED_DATA:
      return {
        ...state,
        selectedsearchfilts: [...state.selectedsearchfilts, action.payload],
      };
    case enumReducerType.REMOVE_SEARCH_FILTER_SELECTED_DATA:
      const { name, id } = action.payload;
      const filteredData = state.selectedsearchfilts.filter(
        (item) => !(item.name === name && item.id === id)
      );
      return {
        ...state,
        selectedsearchfilts: filteredData,
      };

    case enumReducerType.SET_SEARCHRESULT_SEARCHED_LIST_DATA:
      return {
        ...state,
        searchedsearchresultdata: action.payload,
      };
    case enumReducerType.SET_SEARCH_FILTER_PAYLAOD_INSIGHT:
      return {
        ...state,
        filterpayloadsearchinsight: action.payload,
      };
    default:
      return state;
  }
  return state;
};
