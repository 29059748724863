import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import Button from '../../../globals/buttons/Button';
import { getBrowserWindow } from '../../../utils/HelperUtils';
import './decisionTreeCard.scss';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ButtonDataAttributes } from '../../../constants/gtm';

const DecisionTreeCard = ({
  index,
  tag,
  title,
  text,
  icon,
  buttonText,
  buttonLink,
  image,
  arrowIcon,
  variant,
  isSmall,
  onClick,
  componentName,
}) => {
  let button;
  const displayTitle = () => {
    if (title) {
      if (title.value) {
        return <TextSitecoreField field={title}></TextSitecoreField>;
      }
    }
  };
  const displaysubTitle = () => {
    if (text) {
      if (text.value) {
        return <TextSitecoreField field={text}></TextSitecoreField>;
      }
    }
  };

  const isBigScreen = useMediaQuery({ minWidth: breakpoints.md });

  if (buttonText) {
    button = (
      <Button
        text={buttonText.value}
        alignment="left"
        variant="text-button"
        fontSize="small"
        arrow={true}
        onClick={handleOnClick}
        componentName={componentName}
        dataItemName={displayTitle()}
        BtnFunction={() => {
          location.href = buttonLink;
        }}
      />
    );
  } else {
    button = <ImageSitecoreField className="next-arrow" field={arrowIcon}></ImageSitecoreField>;
  }

  const handleOnClick = () => {
    if (onClick !== undefined) {
      onClick(index);
    } else if (buttonLink) {
      if (getBrowserWindow) {
        window.location = buttonLink;
      }
    }
  };

  return (
    <section
      className={`dt-card ${image ? 'dt-card--img' : ''} dt-card--${variant
        .toString()
        .replace('.', '-')} ${isSmall ? 'dt-card--small' : ''}`}
      onClick={handleOnClick}
      {...ButtonDataAttributes({
        name: componentName,
        dataItemName: title?.value,
      })}
    >
      {image && <ImageSitecoreField className="dt-card__image" field={image} />}
      <div className="dt-card__container">
        {isBigScreen && icon && (
          <ImageSitecoreField className="icon" field={icon}></ImageSitecoreField>
        )}
        <div className="dt-card__content">
          {tag && <p className="dt-card__tag">{tag}</p>}
          {/* {tags && <SearchCardArticleTags tags={tags} contentType={type} date={formatDate(date)} />} */}
          <h4 className="dt-card__title">{displayTitle()}</h4>
          <p className="dt-card__text">{displaysubTitle()}</p>
        </div>
        {button}
      </div>
    </section>
  );
};

DecisionTreeCard.defaultProps = {
  index: 0,
  tag: '',
  tags: PropTypes.array,
  type: '',
  date: '',
  title: '',
  text: '',
  icon: PropTypes.array,
  buttonText: '',
  buttonLink: '',
  arrowIcon: '',
  image: PropTypes.shape({}),
  variant: '',
  isSmall: false,
  onClick: () => {},
};

DecisionTreeCard.propTypes = {
  index: PropTypes.number,
  tag: PropTypes.string,
  tags: PropTypes.array,
  type: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.array,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  arrowIcon: PropTypes.string,
  image: PropTypes.shape({}),
  variant: PropTypes.number,
  isSmall: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DecisionTreeCard;
