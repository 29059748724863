import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react';

const SubNavBarLink = ({ ctaText, ctaUrl, isActive, onShow, linkItem }) => {
  let TagBtn = () => (
    <button
      className={`button tag solid light medium ${isActive ? 'white active' : 'violet'}`}
      onClick={onShow}
    >
      {ctaText}
    </button>
  );

  const tag =
    linkItem?.linkType === 'internal' ? (
      <Link className="buttonhref-anchor" to={linkItem?.href}>
        <TagBtn />
      </Link>
    ) : (
      <JssLink className="buttonhref-anchor" field={linkItem}>
        <TagBtn />
      </JssLink>
    );
  return tag;
};

SubNavBarLink.defaultProps = {
  ctaText: '',
  ctaUrl: '',
};

SubNavBarLink.propTypes = {
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default SubNavBarLink;
