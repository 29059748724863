import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../components/sitecorefields/ImageSitecoreField';
import { getBrowserWindow } from '../../utils/HelperUtils';
import { Image, mediaApi } from '@sitecore-jss/sitecore-jss-react';

const ResponsiveImage = ({ imageSmall, imageMedium, imageLarge, altText, cssClass }) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: getBrowserWindow && window?.innerWidth,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    getBrowserWindow && window?.addEventListener('resize', updateDimension);

    return () => {
      getBrowserWindow && window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  let imageSrc;
  if (screenSize.width <= 425) {
    imageSrc = imageSmall;
  } else if (screenSize.width <= 768) {
    imageSrc = imageMedium;
  } else if (screenSize.width > 768) {
    imageSrc = imageLarge;
  }

  return (
    /** Do not take this commented out code - srcset to be figured out for responsive images. */
    // <img
    //   srcSet={`${mediaApi.updateImageUrl(imageSmall?.value?.src)} 320w, ${mediaApi.updateImageUrl(
    //     imageMedium?.value?.src
    //   )} 680w, ${mediaApi.updateImageUrl(imageLarge?.value?.src)}   960w, ${mediaApi.updateImageUrl(
    //     imageLarge?.value?.src
    //   )} 1980w`}
    //   src={mediaApi.updateImageUrl(imageLarge?.value?.src)}
    //   alt={altText}
    //   className={cssClass}
    // />
    <Image
      field={imageSrc}
      srcSet={[{ mw: 320 }, { mw: 680 }, { mw: 1980 }]}
      sizes="(min-width: 960px) 720px, 100vw"
      className={cssClass}
      alt={altText}
    />
  );
};

ResponsiveImage.defaultProps = {
  imageSmall: {},
  imageMedium: {},
  imageLarge: {},
  imageXLarge: {},
  altText: 'image',
  cssClass: 'image',
};

ResponsiveImage.propTypes = {
  imageXLarge: PropTypes.shape({}),
  imgDesktop: PropTypes.shape({}),
  altText: PropTypes.shape({}),
  cssClass: PropTypes.shape({}),
};

export default ResponsiveImage;
