import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../scripts/breakpoints';
import Button from '../../../../globals/buttons/Button';
import DataBlockFeaturetteChart from '../../DataBlock/DataBlockFeaturetteChart';
import RichTextSitecoreField from '../../../sitecorefields/RichTextSitecoreField';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import { wrapChartData } from '../../../../utils/HelperUtils';
import { isEmpty } from 'lodash';
const CarouselChart = (props) => {
  const { data = {} } = props;
  const { fields = {}, textAlign, backgroundColor, cssClass } = data;
  const _BarChartAlt = !isEmpty(fields) ? wrapChartData(fields) : {};
  const isTiny = useMediaQuery({ maxWidth: breakpoints.xxs - 1 });
  const isMobile = useMediaQuery({
    minWidth: breakpoints.xxs,
    maxWidth: breakpoints.xs - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: breakpoints.xs,
    maxWidth: breakpoints.xl,
  });
  const getSize = () => {
    if (isTiny) {
      return 'Tiny';
    } else if (isMobile) {
      return 'XXS';
    } else if (isTablet) {
      return 'S';
    }
    return 'M';
  };

  return (
    <section className="insights-section data">
      <section
        className={`featurette-section standard data ${textAlign?.fields?.text?.value} ${cssClass} ${backgroundColor}`}
      >
        <div className="container-xl">
          <div className={`row content-wrapper ${textAlign}`}>
            <div className="col-lg-5 col-md-5 col-sm-5 featuratte-wrapper">
              <div className="featurette-body" style={{ backgroundColor: 'black' }}>
                {fields?.subtitle && (
                  <div className="sub-title">
                    <TextSitecoreField field={fields?.subtitle ? fields?.subtitle : 'Case Study'} />
                  </div>
                )}
                {fields?.title && (
                  <h2 className="title">
                    <TextSitecoreField field={fields?.title} />
                  </h2>
                )}
                <div className="richtext-wrapper">
                  <RichTextSitecoreField field={fields?.description} />
                </div>
                {fields?.ctaText?.value && (
                  <div className="cta-wrapper">
                    <Button
                      text={fields?.ctaText?.value}
                      cssClass="white"
                      variant="text-button"
                      arrow={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7">
              <div className="featurette-data">
                {_BarChartAlt && !isEmpty(_BarChartAlt) && (
                  <DataBlockFeaturetteChart
                    title={_BarChartAlt?.title || ''}
                    descLabel={_BarChartAlt?.descLabel || ''}
                    description={_BarChartAlt?.description || ''}
                    size={getSize()}
                    color={_BarChartAlt?.color || ''}
                    backgroundColor={backgroundColor}
                    chartType={_BarChartAlt?.chartType || ''}
                    data={_BarChartAlt?.data || {}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

CarouselChart.defaultProps = {
  data: {
    fields: {},
    textAlign: 'left',
    cssClass: '',
    backgroundColor: 'secondary',
  },
};

CarouselChart.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({}),
  }),
  textAlign: PropTypes.string,
  cssClass: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default CarouselChart;
