import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * Renders a Sitecore text field.
 * @param {Object} props - The component props.
 * @param {Object} props.field - The Sitecore field object.
 * @returns {JSX.Element} - The rendered component.
 */
const TextSitecoreField = (props) => {
  const { field, ...rest } = props;
  return <Text field={field} {...rest} />;
};

export default TextSitecoreField;
