import React from 'react';
import PropTypes from 'prop-types';
import UtilityIcon from '../../../globals/icons/UtilityIcon';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty } from 'lodash';
import { ComponentDataAttributes } from '../../../constants/gtm';

const ImageBlock = (props) => {
  const {
    backgroundColor,
    fields,
    rendering: { componentName = '' },
  } = props;
  const { items, heading } = fields;
  return (
    <section
      className={`card-block image-block image-block__${items?.length} ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <h2 className="achievement-title">
              <TextSitecoreField field={heading} />
            </h2>
          </div>
        </div>
        <div className="row justify-content-center">
          {items &&
            items?.map((item, id) => {
              /** Image should come inside Value ONLY - DO not delete this comment */
              return !isEmpty(item?.fields?.image?.value) ? (
                <div key={`${id}-${item?.id}`} className="image-wrapper">
                  <UtilityIcon
                    key={item?.id}
                    img={item.fields?.image}
                    altText={item?.fields?.title?.value}
                    ctaUrl={item?.fields?.link?.value?.href}
                    ctaTarget={'_blank'}
                    componentName={componentName}
                  />
                </div>
              ) : null;
            })}
        </div>
      </div>
    </section>
  );
};

ImageBlock.defaultProps = {
  fields: {},
  backgroundColor: 'on-primary',
  title: '',
  items: [],
};

ImageBlock.propTypes = {
  /** Heading 2 (Section title for SEO) */
  title: PropTypes.string,

  /** Color Blocking: Light (Default) or Dark */
  backgroundColor: PropTypes.string,

  /** Cards (Exactly 3)*/
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      img: PropTypes.string,
      altText: PropTypes.string,
      ctaUrl: PropTypes.string,
      ctaTarget: PropTypes.string,
    })
  ),
};

export default ImageBlock;
