import { useState, useEffect } from 'react';
import { getBrowserWindow, isSCExperienceEditorActive } from '../utils/HelperUtils';

export const useWindowPathName = () => {
  const [xpWindowPathName, setxpWindowPathName] = useState(undefined);
  const windowVal = getBrowserWindow;
  useEffect(() => {
    if (windowVal && !isSCExperienceEditorActive) {
      setxpWindowPathName(windowVal?.location?.pathname);
    }
  }, []);
  return xpWindowPathName;
};
