import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { suggestions } from '../../SearchField/suggestions';

import './searchBar.scss';
import { isBrowserDocumentAvailable } from '../../../../utils/HelperUtils';
import { ButtonDataAttributes } from '../../../../constants/gtm';

// eslint-disable-next-line react/display-name
const SearchBar = React.forwardRef(
  (
    {
      setSearchOptions,
      setIsSearchSuggestions,
      setHeaderSuggestions,
      handleClickAway,
      showSearch,
      setShowSearch,
      isExpanded,
      setIsExpanded,
      mobNavBtn,
      setMobNavBtn,
      ...props
    },
    ref
  ) => {
    const searchButtonRef = useRef();

    const [query, setQuery] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    useImperativeHandle(ref, () => ({
      focusSearchButton: () => {
        if (searchButtonRef.current) {
          searchButtonRef.current.focus();
        }
      },
    }));
    const { componentName = 'CountrySiteHeader' } = props;

    const handleIconClick = () => {
      setIsExpanded(!isExpanded);
      setSearchOptions(!isExpanded);
      setShowSearch(false);
      setIsSearchSuggestions(false);
      setQuery('');
      mobNavBtn && setMobNavBtn(!mobNavBtn);
    };

    const handleInputChange = (event) => {
      event.persist();
      const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
      setQuery(value);
      setIsTyping(true);
      if (value.length >= 3) {
        setIsSearchSuggestions(true);
        setShowSearch(true);
        const filteredSuggestions = suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
        const highlightedSuggestions = filteredSuggestions.map((suggestion) => {
          const highlightedSuggestion = highlightMatch(suggestion, value);
          return highlightedSuggestion;
        });

        setHeaderSuggestions(highlightedSuggestions);
      } else if (value.length === 0) {
        setShowSearch(false);
      } else {
        setIsSearchSuggestions(false);
        setHeaderSuggestions([]);
      }
    };

    const handleQueryBlur = () => {
      setIsTyping(false);
    };

    const handleSearchClick = () => {};

    useEffect(() => {
      if (isBrowserDocumentAvailable) {
        document?.addEventListener('click', handleClickAway);
        return () => document?.removeEventListener('click', handleClickAway);
      }
    }, []);

    // highlight the matching text
    const highlightMatch = (text, value) => {
      // Add value parameter
      if (typeof text !== 'string') {
        return text;
      }

      const queryWithoutSpaces = value.replace(/\s/g, '');
      const queryRegex = new RegExp(`(${queryWithoutSpaces.split('').join('\\s*')})`, 'gi');
      const matches = text.toLowerCase().match(queryRegex);
      if (matches) {
        const parts = text.split(queryRegex);
        return (
          <>
            {parts &&
              parts?.map((part, index) =>
                matches.includes(part.toLowerCase()) ? (
                  <span key={index} className="highlight">
                    {part.replace(new RegExp(queryWithoutSpaces, 'gi'), (match) => `${match}`)}
                  </span>
                ) : (
                  part
                )
              )}
          </>
        );
      }
      return text;
    };

    // hide when click away
    const searchBarRef = useRef(null);

    return (
      <div ref={searchBarRef} className={'searchBarWrapper white__background'}>
        <div className={`searchBarInputWrapper ${isExpanded ? 'expanded' : ''} `}>
          <div
            className={`searchBarInput ${isExpanded ? 'expanded' : ''} ${
              showSearch ? 'close_visible' : ''
            } `}
          >
            <input
              type="text"
              value={query}
              aria-label="Search"
              placeholder="What are you searching for?"
              onChange={handleInputChange}
              onKeyDown={props.handleSearch}
              onBlur={handleQueryBlur}
            />
            {showSearch && (
              <button
                ref={searchButtonRef}
                className="searchBarBtnSearch"
                onClick={() => {
                  handleSearchClick(query);
                }}
              >
                Search
              </button>
            )}
            <button className="searchBarBtnClose desktop" onClick={handleIconClick}>
              Close
            </button>
          </div>

          {isExpanded ? (
            <>
              <button
                className="searchBarButton"
                onClick={() => handleSearchClick(query)}
                {...ButtonDataAttributes({
                  name: componentName,
                  text: '',
                  variant: 'icon',
                  arrow: false,
                  dataItemName: '',
                })}
              >
                Search
              </button>

              <button
                className="searchBarBtnClose mobile"
                onClick={handleIconClick}
                {...ButtonDataAttributes({
                  name: componentName,
                  text: '',
                  variant: 'icon',
                  arrow: false,
                  dataItemName: '',
                })}
              >
                Mobile Toggle
              </button>
            </>
          ) : (
            <button
              className="searchBarButton"
              onClick={handleIconClick}
              {...ButtonDataAttributes({
                name: componentName,
                text: '',
                variant: 'icon',
                arrow: false,
                dataItemName: '',
              })}
            >
              Search
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default SearchBar;

SearchBar.propTypes = {
  scrolled: PropTypes.bool,
  onSearch: PropTypes.func,
  setSearchOptions: PropTypes.func.isRequired,
  setIsSearchSuggestions: PropTypes.func.isRequired,
  setHeaderSuggestions: PropTypes.func.isRequired,
  handleClickAway: PropTypes.func.isRequired,
  showSearch: PropTypes.bool.isRequired,
  setShowSearch: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func,
  mobNavBtn: PropTypes.bool,
  setMobNavBtn: PropTypes.func,
  mobMenuShow: PropTypes.bool,
};
