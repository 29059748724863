import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';

const ArticleHeader = ({ title, subTitle, company, companyLogo, time, skill, cssClass }) => {
  return (
    <div className={`article-header ${cssClass ? cssClass : ''}`}>
      <h1 className="title">
        <TextSitecoreField field={title}></TextSitecoreField>
      </h1>
      {cssClass !== 'job' && (
        <h2 className="sub-title">
          <TextSitecoreField field={subTitle}></TextSitecoreField>
        </h2>
      )}
      {cssClass === 'job' && (
        <div className="company">
          {company}
          {companyLogo && <ImageSitecoreField field={companyLogo} />}
        </div>
      )}
      {(time || skill) && (
        <div className="time-skill-block">
          {time && <div className="time">{time}</div>}
          {skill && <div className="skill">{skill}</div>}
        </div>
      )}
    </div>
  );
};

ArticleHeader.defaultProps = {
  title: '',
  subTitle: '',
};

ArticleHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default ArticleHeader;
