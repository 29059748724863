import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../../../scripts/breakpoints';
import './about.scss';
import Button from '../../../../../../globals/buttons/Button';
import TextSitecoreField from '../../../../../sitecorefields/TextSitecoreField';
import ButtonHref from '../../../../../../globals/buttons/ButtonHref';
import ImageSitecoreField from '../../../../../sitecorefields/ImageSitecoreField';
import { NavTopDataAttributes } from '../../../../../../constants/gtm';

export default function AboutComponent(props) {
  const { data, handleMobileMenu, componentName = 'SiteHeader' } = props;
  const { fields = {}, name = '' } = data;
  const { Descriptions, ExploreMoreLink, LinksCollections, TalkToUsLink } = fields;

  const contentData = LinksCollections;
  const limit = 3;

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <div className="subNavigationComponent ">
      {contentData && (
        <div className={`subNavComponent aboutus  ${isMobile ? 'mobileNav' : ''} `}>
          <div className="subNavComponent__inner">
            <div className="subNavComponent__left">
              {isMobile && (
                <a href={fields?.Link?.value?.href} className="subNavComponent__component-name">
                  {name}
                </a>
              )}
              <div className="subNavComponent__title">
                <TextSitecoreField field={Descriptions} />
              </div>
              <div className="subNavComponent__cta">
                <div className="subNavComponent__solutions-cta">
                  <ButtonHref
                    text={ExploreMoreLink?.value?.text}
                    href={ExploreMoreLink?.value?.href}
                    variant="secondary-button"
                    fontSize="small"
                    arrow={true}
                    linkType={ExploreMoreLink?.value?.linktype}
                    linkFields={ExploreMoreLink}
                    dataItemName={name}
                    componentName="SiteHeader"
                  />
                </div>
                {/* {!isMobile && (
                  <div className="subNavComponent__contact_cta">
                    <ButtonHref
                      fontSize="small"
                      variant="primary-button"
                      arrow={false}
                      text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                      href={TalkToUsLink?.value?.href}
                      linkType={TalkToUsLink?.value?.linktype}
                      linkFields={TalkToUsLink}
                      componentName="SiteHeader"
                      dataItemName={name}
                    />
                  </div>
                )} */}
              </div>
            </div>
            <div className="subNavComponent__right">
              <div className={`subNavComponent__list ${isMobile ? 'mobile' : ''}`}>
                {LinksCollections &&
                  LinksCollections?.slice(0, limit).map((item, index) => {
                    const { fields = {}, background } = item;
                    const { Image, ctaText, Description, ctaLink } = fields;
                    return !isMobile ? (
                      <React.Fragment key={`about__item--${index}`}>
                        <a
                          href={ctaLink?.value?.href || '/'}
                          className={'card link_card'}
                          {...NavTopDataAttributes({
                            name: componentName,
                            itemLevel: 'L1',
                            dataNavItemName: ctaText?.value || '',
                            dataLinkText: '',
                            dataLinkImageUrl: '',
                          })}
                        >
                          <div className="card-body" data-background={background}>
                            <div
                              className={`card-body--icon ${
                                Image?.value?.src ? Image?.value?.src : 'imagevalueisemptyfromsc'
                              }`}
                            >
                              <ImageSitecoreField field={Image} />
                            </div>
                            <h3>
                              <TextSitecoreField field={ctaText} />
                            </h3>
                            <p>
                              <TextSitecoreField field={Description} />
                            </p>
                            <Button
                              text={ctaLink?.value?.text}
                              alignment="left"
                              variant="text-button"
                              fontSize="medium"
                              arrow={true}
                              dataItemName={ctaText?.value || ctaLink?.value?.text}
                              linkType={ctaLink?.value?.linktype}
                              linkFields={ctaLink}
                              componentName={componentName}
                              BtnFunction={() => {
                                location.href = ctaLink?.value?.href;
                              }}
                            />
                          </div>
                        </a>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`about__item--${index}`}>
                        <Button
                          variant="text-button"
                          type={true}
                          icon={Image?.value?.src ? Image?.value?.src : 'imagevalueisemptyfromsc'}
                          cssClass=""
                          arrow={true}
                          text={ctaText?.value || ctaLink?.value?.text}
                          href={ctaLink?.value?.href}
                          linkType={ctaLink?.value?.linktype}
                          linkFields={ctaLink}
                          componentName={componentName}
                          dataItemName={ctaText?.value || ctaLink?.value?.text}
                          BtnFunction={() => {
                            location.href = ctaLink?.value?.href;
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>

            {isMobile && (
              <div className="subNavComponent__contact_cta mobile">
                <ButtonHref
                  fontSize="small"
                  variant="primary-button"
                  arrow={false}
                  text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                  href={TalkToUsLink?.value?.href}
                  linkType={TalkToUsLink?.value?.linktype}
                  linkFields={TalkToUsLink}
                  componentName={componentName}
                  dataItemName="About Us"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

AboutComponent.defaultProps = {
  data: {
    fields: {
      Description: {
        value: '',
      },
      ExploreMoreLink: {
        value: {
          href: '',
          text: '',
        },
      },
      LinksCollections: [
        {
          fields: {
            ctaText: {
              value: '',
            },
            Description: {
              value: '',
            },
            ctaLink: {
              value: {
                href: '',
                text: '',
              },
            },
            Image: {
              value: {
                src: '',
              },
            },
          },
        },
      ],
      TalkToUsLink: {
        value: {
          href: '',
          text: '',
        },
      },
    },
    name: 'About Us',
  },
  handleMobileMenu: () => {},
};
AboutComponent.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({
      Description: PropTypes.shape({
        value: PropTypes.string,
      }),
      ExploreMoreLink: PropTypes.shape({
        value: PropTypes.shape({
          href: PropTypes.string,
          text: PropTypes.string,
        }),
      }),
      LinksCollections: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.shape({
            ctaText: PropTypes.shape({
              value: PropTypes.string,
            }),
            Description: PropTypes.shape({
              value: PropTypes.string,
            }),
            ctaLink: PropTypes.shape({
              value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
              }),
            }),
            Image: PropTypes.shape({
              value: PropTypes.shape({
                src: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      TalkToUsLink: PropTypes.shape({
        value: PropTypes.shape({
          href: PropTypes.string,
          text: PropTypes.string,
        }),
      }),
    }),
    name: PropTypes.string,
  }),
  handleMobileMenu: PropTypes.func,
};
