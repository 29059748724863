import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIcon } from '../../../state/common';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import Pagination from '../Navigation/Pagination/Pagination';
import ResponsivePicture from '../../../globals/icons/ResponsivePicture';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { fetchCardBlockLargeList } from '../../../service';
import { getContentTypeTags } from '../../../utils/HelperUtils';
import TagInsight from '../../../globals/buttons/TagInsight';

import { withTranslation } from 'react-i18next';

const CardInsightEventListing = (props) => {
  const {
    fields,
    t,
    rendering: { componentName = '' },
  } = props;
  const { tagsToShow } = fields;
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [showTags, setShowTags] = useState();
  const [data, setData] = useState();
  const allfiltoptionswithduplicates = useSelector(
    (state) => state.masterReducer.allfiltoptionswithduplicates
  );

  useEffect(() => {
    if (tagsToShow.length > 0) {
      const dataTags = tagsToShow.map((tag) =>
        allfiltoptionswithduplicates
          .flat()
          .filter((item) => item.filt.toUpperCase() === tag.name.toUpperCase())
          .map((item) => ({ name: item.name, id: item.id }))
      );
      const eleTags = dataTags.flat();
      const elems = eleTags.filter(function (a) {
        let key = a.name + '|' + a.id;
        if (!this[key]) {
          this[key] = true;
          return true;
        }
      }, Object.create(null));
      setShowTags(elems.slice(0, 2));
    }
  }, [allfiltoptionswithduplicates]);

  const sendDataToParent = async (index) => {
    setPageNumber(index);
    const offSet = index - 1;
    const result = await fetchCardBlockLargeList('types=event', 3, offSet, 'en', false, false);
    setTotalItems(result.data.totalCount);
    setData(result.data.searchResult);
  };
  useEffect(() => {
    sendDataToParent(pageNumber);
  }, []);

  return (
    <section
      className={'card-block events '}
      {...ComponentDataAttributes('CardInsightEventListing')}
    >
      <div className="container-xl">
        <div className="row card-wrapper">
          {data &&
            data.map((item, id) => {
              return (
                <Link
                  key={id}
                  to={item?.url}
                  className="card card__insights card__event event__listing"
                  componentName={'CardInsightEventListing'}
                  dataItemName={item?.title}
                >
                  <div className="img-wrapper">
                    <ResponsivePicture
                      imageSmall={item?.image?.src}
                      imageMedium={item?.image?.src}
                      imageLarge={item?.image?.src}
                    />
                  </div>
                  <div className="card-body event__listing__card__body">
                    <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
                    <div className="card-content event__listing__card__content">
                      {item?.subTitle && <div className="sub-title">{item?.subTitle}</div>}
                      <h3 className="title">{item?.title}</h3>
                      <p>{item?.content}</p>
                    </div>
                    <div className="cta__wrap event__listing__cta">
                      <ButtonHref
                        variant="text-button"
                        fontSize="small"
                        text={t('Read-More')}
                        arrow={true}
                        href={item?.url}
                        componentName={'CardInsightEventListing'}
                        dataItemName={item?.title}
                      />
                    </div>
                    <div className="related-solutions event__listing__solution">
                      {showTags &&
                        showTags?.map((tag, index) => (
                          <TagInsight
                            key={`${index}-${tag?.name}`}
                            cssClass={tag?.cssClass}
                            fontSize="small"
                            icon={tag?.name && getIcon(tag.name.replace(/\s/g, '').toLowerCase())}
                            text={tag?.name}
                          />
                        ))}
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
      {totalItems && (
        <div className={`col columns columns__1 cards cards__${totalItems}`}>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            currentPage={pageNumber}
            sendDataToParent={sendDataToParent}
          />
        </div>
      )}
    </section>
  );
};

CardInsightEventListing.defaultProps = {
  fields: {},
  t: () => {},
};

CardInsightEventListing.propTypes = {
  t: PropTypes.func,
};
export default withTranslation()(CardInsightEventListing);
