import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TopBottomContainer = (props) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <Placeholder name="jss-top" rendering={props.rendering} />
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <Placeholder name="jss-bottom" rendering={props.rendering} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopBottomContainer;
