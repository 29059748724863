import React from 'react';
import PropTypes from 'prop-types';
import SidebarCardListSpeakers from '../SidebarCards/SidebarCardListSpeakers';
import { ComponentDataAttributes } from '../../../constants/gtm';

const GuestSpeakerHost = ({ ...props }) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { Title, Members } = fields;
  return (
    <section className="article-block podcast" {...ComponentDataAttributes(componentName)}>
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <SidebarCardListSpeakers title={Title} items={Members} componentName={componentName} />
          </div>
        </div>
      </div>
    </section>
  );
};

GuestSpeakerHost.defaultProps = {
  fields: {
    Title: '',
    Members: [],
  },
};

GuestSpeakerHost.propTypes = {
  fields: PropTypes.shape({
    Title: PropTypes.string,
    Members: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default GuestSpeakerHost;
