/* eslint-disable */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { constructLanguageRedirectionURL, getBrowserWindow } from '../../../utils/HelperUtils';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

/**
 * @description - to list accordion items
 * @param {*} props - props
 * @returns {HTML} - accordion html
 */
const CountryLinkAccordionItem = (props) => {
  const { region, active, onAccordionClick, index } = props;
  const { regionName, countries = [] } = region;
  const [origin, setOrigin] = useState();

  useEffect(() => {
    setOrigin(getBrowserWindow && window?.location?.hostname);
  }, []);

  return (
    <div className={`accordion-block ${active ? 'active' : ''}`}>
      <button
        className="icon"
        onClick={() => (active ? onAccordionClick() : onAccordionClick(index))}
        aria-label={`toggle accordion ${regionName}`}
        aria-expanded={active}
      ></button>
      <div
        className="accordion-title"
        onClick={() => (active ? onAccordionClick() : onAccordionClick(index))}
        role="presentation"
      >
        {regionName}
      </div>
      <div className="accordion-content clearfix">
        <div className="row">
          {countries &&
            countries.map((country, Cindex) => {
              const { countryName, languages } = country;
              const countryClass = countryName?.toLowerCase() === 'global' ? 'col-md-7' : 'col-md-4';
              return (
                <div className={`${countryClass} country-list`} key={Cindex}>
                  <span className="country-name">{`${countryName} | ` + ' '}</span>
                  <ul className="countries">
                    {country &&
                      languages &&
                      languages?.values &&
                      languages?.values?.map((language, lIndex) => {
                        const { name, regionalIsoCode } = language;
                        const redirectionURL = constructLanguageRedirectionURL(
                          countryName,
                          regionalIsoCode
                        );
                        return (
                          <li key={`${lIndex}-${name || 'name'}`}>
                            {
                              <>
                                <a
                                  className="country-language"
                                  href={redirectionURL}
                                  target={'_blank'}
                                  {...OtherLinkDataAttributes({
                                    name: 'LanguageSelector',
                                    text: name,
                                    dataItemName: countryName,
                                  })}
                                >
                                  {name}
                                </a>
                              </>
                            }
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

CountryLinkAccordionItem.defaultProps = {
  region: {},
  title: '',
  onAccordionClick: () => {},
  active: false,
  index: 0,
};

CountryLinkAccordionItem.propTypes = {
  region: PropTypes.shape({}),
  title: PropTypes.string,
  onAccordionClick: PropTypes.func,
  active: PropTypes.bool,
  index: PropTypes.number,
};

export default CountryLinkAccordionItem;
