import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import { ComponentDataAttributes } from '../../../constants/gtm';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const BannerImage = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    Content,
    backgroundColor,
    backgroundImage,
    backgroundImageMobile,
    ctaLink,
    ctaStyle,
    ctaText,
    frostedGlass,
    optCtaLink,
    optCtaStyle,
    optCtaText,
    optShowCTAIcon,
    showCTAIcon,
    ctaIconPosition,
    optCtaIconPosition,
    title,
    frostedGlassColor,
    textColor,
  } = fields;
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    <section
      className={`banner-section standard banner image ${backgroundColor?.fields?.text?.value} ${
        frostedGlass?.value === true && !isEmpty(frostedGlassColor?.fields?.text?.value)
          ? 'glass'
          : ''
      }`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageMedium={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageLarge={backgroundImage}
        imageXLarge={backgroundImage}
      />
      <div className="container-xl">
        <div className="row content-wrapper">
          <div
            className={`col-lg-12 text-center ${
              frostedGlass?.value === true &&
              !isEmpty(frostedGlassColor?.fields?.text?.value) &&
              (frostedGlassColor?.fields?.text?.value === 'light'
                ? 'frost frost__light'
                : 'frost frost__dark')
            }`}
          >
            {!isMobileScreen ? (
              <h1
                className={`title-image ${
                  textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
              >
                <TextSitecoreField field={title} />
              </h1>
            ) : (
              <h2
                className={`title-image ${
                  textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
              >
                <TextSitecoreField field={title} />
              </h2>
            )}
            <p
              className={`sub-title-image ${
                textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
              }`}
            >
              <TextSitecoreField field={Content} />
            </p>
            <div className="cta__wrap">
              {ctaLink && ctaText && !isEmpty(ctaText?.value) && !isEmpty(ctaLink?.value?.href) && (
                <ButtonHref
                  text={ctaText?.value}
                  href={ctaLink?.value?.href}
                  cssClass={textColor?.fields?.text?.value}
                  variant={ctaStyle ? ctaStyle?.fields?.text?.value : 'primary-button'}
                  arrow={showCTAIcon ? showCTAIcon.value : false}
                  linkType={ctaLink?.value?.linktype}
                  linkFields={ctaLink}
                  iconPosition={
                    ctaIconPosition?.fields?.text?.value === 'right' ? 'icon__right' : 'icon__left'
                  }
                  componentName={componentName}
                />
              )}
              {optCtaLink &&
                optCtaText &&
                !isEmpty(optCtaText?.value) &&
                !isEmpty(optCtaLink?.value?.href) && (
                  <ButtonHref
                    text={optCtaText?.value || optCtaLink?.value?.text}
                    href={optCtaLink?.value?.href}
                    cssClass={textColor?.fields?.text?.value}
                    variant={optCtaStyle ? optCtaStyle?.fields?.text?.value : 'text-button'}
                    arrow={optShowCTAIcon ? optShowCTAIcon?.value : false}
                    linkType={optCtaLink?.value?.linktype}
                    linkFields={optCtaLink}
                    iconPosition={
                      optCtaIconPosition?.fields?.text?.value === 'right'
                        ? 'icon__right'
                        : 'icon__left'
                    }
                    componentName={componentName}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BannerImage.defaultProps = {
  fields: {
    frostedGlass: {
      value: 'dark',
    },
    backgroundImageMobile: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    title: {
      value: '',
    },
    frostedGlassColor: {},
    textColor: {
      fields: {
        text: {
          value: 'black',
        },
      },
    },
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    showCTAIcon: {
      value: false,
    },
    ctaLink: {
      value: {
        href: '',
      },
    },
    ctaStyle: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: '',
        },
      },
    },
    ctaText: {
      value: '',
    },
    ctaIcon: {
      value: {},
    },
    ctaIconPosition: null,
    optShowCTAIcon: {
      value: false,
    },
    optCtaIcon: {
      value: {},
    },
    optCtaIconPosition: null,
    optCtaLink: {
      value: {
        href: '',
      },
    },
    optCtaText: {
      value: '',
    },
    optCtaStyle: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: '',
        },
      },
    },
    Content: {
      value: '',
    },
    backgroundColor: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'secondary',
        },
      },
    },
  },
};

BannerImage.propTypes = {
  fields: PropTypes.shape({
    frostedGlass: PropTypes.shape({}),
    backgroundImageMobile: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    title: PropTypes.shape({}),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    showCTAIcon: PropTypes.shape({}),
    ctaLink: PropTypes.shape({}),
    ctaStyle: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    ctaIcon: PropTypes.shape({}),
    ctaIconPosition: PropTypes.shape({}),
    optShowCTAIcon: PropTypes.shape({}),
    optCtaIcon: PropTypes.shape({}),
    optCtaIconPosition: PropTypes.shape({}),
    optCtaLink: PropTypes.shape({ value: PropTypes.shape({}) }),
    optCtaText: PropTypes.shape({}),
    optCtaStyle: PropTypes.shape({}),
    Content: PropTypes.shape({}),
    backgroundColor: PropTypes.shape({}),
  }),
};

export default BannerImage;
