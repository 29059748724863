import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { TagDataAttributes } from '../../constants/gtm';

const TagInsight = ({
  text,
  icon,
  fontSize,
  variant,
  cssClass,
  iconPosition,
  onClick,
  componentName,
  dataItemName,
}) => {
  return (
    <button
      className={`button width-max tag ${variant} ${cssClass} ${fontSize}`}
      onClick={onClick}
      {...TagDataAttributes({
        name: componentName,
        dataItemName: dataItemName,
        linkText: text,
        tagIconUrl: icon,
      })}
    >
      {icon && (
        <img
          src={mediaApi.updateImageUrl(icon)}
          className={`icon ${iconPosition}`}
          alt={text ? text : ''}
        />
      )}
      {text}
    </button>
  );
};

TagInsight.defaultProps = {
  variant: 'article',
  cssClass: '',
  fontSize: '',
  icon: '',
  text: '',
};

TagInsight.propTypes = {
  /** White, Black, Outline or Outline On-Secondary */
  cssClass: PropTypes.string,
  /** Article, Outline, or Solid */
  variant: PropTypes.string,
  /** Font Size (Default Medium) */
  fontSize: PropTypes.oneOf(['medium', 'small']),
  /** Optional */
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default TagInsight;
