import React from 'react';
import PropTypes from 'prop-types';
import { TagDataAttributes } from '../../constants/gtm';
import { breakpoints } from '../../scripts/breakpoints';
import ImageSitecoreField from '../../components/sitecorefields/ImageSitecoreField';
import { isObject } from 'lodash';
import { useMediaQuery } from 'react-responsive';

const Tag = ({
  text,
  icon,
  icon2,
  fontSize,
  variant,
  cssClass,
  onClick,
  dataItemName = '',
  componentName = '',
  isLatestPillsColor = '',
}) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    text && (
      <button
        className={`button width-max tag ${variant} ${cssClass} ${fontSize} ${isLatestPillsColor}`}
        onClick={onClick}
        {...TagDataAttributes({
          name: componentName || 'Tag',
          dataItemName: isObject(dataItemName)
            ? dataItemName?.props?.field?.value
            : dataItemName?.value || dataItemName,
          linkText: isObject(text) ? text?.props?.field?.value : text?.value || text,
          tagIconUrl: isObject(icon) ? icon?.props?.field?.src : icon?.src || icon?.src,
        })}
      >
        {isMobile
          ? icon2 && <ImageSitecoreField field={icon2} className="icon" />
          : icon && <ImageSitecoreField field={icon} className="icon" />}
        {text}
      </button>
    )
  );
};

Tag.defaultProps = {
  variant: '',
  cssClass: '',
  fontSize: 'medium',
  icon: {},
  text: '',
  dataItemName: '',
  componentName: 'Tag',
};

Tag.propTypes = {
  /** White, Black, Outline or Outline On-Secondary */
  cssClass: PropTypes.string,
  /** Article, Outline, or Solid */
  variant: PropTypes.string,
  /** Font Size (Default Medium) */
  fontSize: PropTypes.oneOf(['medium', 'small']),
  /** Optional */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  dataItemName: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  componentName: PropTypes.string,
};

export default Tag;
