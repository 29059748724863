import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { NavigationSocialLinkFooterDataAttributes } from '../../../constants/gtm';
import { isEmpty } from 'lodash';

const SocialIcon = ({ img, altText, tooltip, ctaUrl }) => (
  <a
    href={ctaUrl}
    className="social-icon"
    target="_blank"
    rel="noopener noreferrer"
    title={tooltip}
    {...NavigationSocialLinkFooterDataAttributes({
      name: 'FooterSocialConnect',
      dataNavItemLevel: 'L0',
      dataNavItemName: '',
      linkText: altText || '',
      imageUrl: ctaUrl || '',
    })}
  >
    {img && !isEmpty(img?.value) && <ImageSitecoreField field={img} alt={altText} />}
  </a>
);

SocialIcon.defaultProps = {
  img: {},
  altText: '',
  tooltip: '',
  ctaUrl: '',
};

SocialIcon.propTypes = {
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  tooltip: PropTypes.string,
  ctaUrl: PropTypes.string,
};

export default SocialIcon;
