import React, { useState } from 'react';
import { ButtonDataAttributes, ComponentDataAttributes } from '../../../constants/gtm';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import TextBox from '../../core/ContactUs_form/TextBoxContact';
import TextAreaBox from '../../core/ContactUs_form/TextAreaContact';
import { useForm } from 'react-hook-form';
import Button from '../../../globals/buttons/Button';
import axios from 'axios';
import Checkbox from '../../../globals/forms/CheckboxFn';
import {
  isSCExperienceEditorActive,
  getBrowserWindow,
  currentLanguage,
} from '../../../utils/HelperUtils';
import { isEmpty } from 'lodash';

function HubSpotNewsletterForm(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { fields, componentName = '' } = props;

  const [cbChecking, setCbChecking] = useState(false);
  const [cbError, setCbError] = useState('');
  const [resData, setResdata] = useState('');
  const [formChanged, setFormChanged] = useState(false);

  const {
    Header,
    Description,
    firstColumnFormFields,
    ctaText,
    ctaArrow,
    ctaStyle,
    hubspotFormId,
    hubspotPortalId,
  } = fields;

  const validateCb = () => {
    if (cbChecking === false) {
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow && !window.dataLayerFired) {
          window?.dataLayer?.push({
            event: 'form_error',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'HubspotNewsletterForm',
            form_error: 'Please check privacy policy',
          });
          window.dataLayerFired = true;
        }
      }
      setCbError('Please check privacy policy');
      return false;
    }
    if (cbChecking === true) {
      setCbError('');
      return true;
    }
  };

  // For GTM error
  if (errors && !isEmpty(errors) && !isSCExperienceEditorActive) {
    if (getBrowserWindow) {
      window?.dataLayer?.push({
        event: 'form_error',
        form_id: hubspotFormId?.value || '',
        form_name: componentName || 'HubspotNewsletterForm',
        form_error: errors?.email?.message,
      });
    }
  }

  const url = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/HubSpot/FormSubmit`;
  let parseData;
  async function apiPost(postdata) {
    if (validateCb()) {
      try {
        parseData = {
          FormId: hubspotFormId?.value,
          PortalID: hubspotPortalId?.value,
          language: currentLanguage() || 'en',
          formData: `{"fields" : ${JSON.stringify(postdata)}}`,
        };

        const apiCall = await axios.post(url, parseData);
        const apiStatus = await JSON.parse(apiCall?.data?.message);
        setResdata(apiStatus?.inlineMessage);
        reset();
      } catch (error) {}
    }
  }

  const onSubmit = (data) => {
    let newFormatdata = [];
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          newFormatdata.push({ name: `${key}`, value: `${data[key]}` });
        }
      }
    }
    newFormatdata ? apiPost(newFormatdata) : '';
    if (validateCb()) {
      if (!isSCExperienceEditorActive) {
        window?.dataLayer?.push({
          event: 'form_submit',
          form_id: hubspotFormId?.value || '',
          form_name: componentName || 'HubspotNewsletterForm',
        });
      }
    }
  };
  const handleInputChange = (event) => {
    if (!formChanged) {
      setFormChanged(true);
      if (!isSCExperienceEditorActive) {
        if (getBrowserWindow) {
          window?.dataLayer?.push({
            event: 'form_start',
            form_id: hubspotFormId?.value || '',
            form_name: componentName || 'HubspotNewsletterForm',
          });
        }
      }
    }
  };

  return (
    <>
      {!resData ? (
        <div className="container-xl" {...ComponentDataAttributes(componentName)}>
          <div className="row">
            <div className="col">
              <div className="footer-rightside">
                <div className="row footer-newsletter">
                  <div className="col">
                    <div className="title newsLetter">
                      <TextSitecoreField field={Header} />
                    </div>

                    <div>
                      <div className="pretext">
                        <RichTextSitecoreField field={Description} />
                      </div>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="newsletter-form-container">
                          <div className="form-block">
                            {firstColumnFormFields &&
                              firstColumnFormFields?.map((item) => {
                                let label = item?.fields?.Label?.value;
                                let name = item?.fields?.hubspotFieldMapping?.value;
                                let isRequired = item?.fields?.isRequiredField?.value;
                                const regax =
                                  name === 'email'
                                    ? /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                    : new RegExp(
                                        item?.fields?.validationRegex?.fields?.text?.value
                                      );
                                switch (item?.fields?.fieldType?.fields?.text?.value) {
                                  case 'text':
                                    return (
                                      <TextBox
                                        Label={label}
                                        type="text"
                                        name={name}
                                        reg={{
                                          ...register(`${name}`, {
                                            required: isRequired
                                              ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                              : '',
                                            maxLength: item?.fields?.maxLength?.value,
                                            pattern: {
                                              value: regax,
                                              message: `${item?.fields?.validationErrorMessage?.value}`,
                                            },
                                          }),
                                        }}
                                        placeholder={item?.fields?.placeholderText?.value}
                                        isRequiredField={isRequired}
                                        errors={errors[`${name}`]}
                                        handleInputChange={handleInputChange}
                                      />
                                    );
                                  case 'richtext':
                                    return (
                                      <TextAreaBox
                                        Label={label}
                                        type="text"
                                        name={name}
                                        placeholder={item?.fields?.placeholderText?.value}
                                        reg={{
                                          ...register(`${name}`, {
                                            required: isRequired
                                              ? `${item?.fields?.requiredFieldErrorMessage?.value}`
                                              : '',
                                            maxLength: item?.fields?.maxLength?.value,
                                            pattern: {
                                              value: item?.fields?.validationRegex?.value,
                                              message: `${item?.fields?.validationErrorMessage?.value}`,
                                            },
                                          }),
                                        }}
                                        isRequiredField={isRequired}
                                        errors={errors[`${name}`]}
                                        handleInputChange={handleInputChange}
                                      />
                                    );
                                  default:
                                    return '';
                                }
                              })}
                          </div>

                          <div className="newsLetter_left">
                            <div className="form-footer">
                              {fields &&
                                fields?.consentText &&
                                !isEmpty(fields?.consentText?.value) && (
                                  <Checkbox
                                    id="newletter-checkbox"
                                    className={'contact-checkbox-label'}
                                    text={<RichTextSitecoreField field={fields?.consentText} />}
                                    onClick={(e) => {
                                      setCbChecking(e.target.checked);
                                    }}
                                  />
                                )}
                              {cbError && <span className="error-message">{cbError}</span>}
                            </div>
                            <div className="form-button">
                              {ctaText && ctaText?.value && (
                                <button
                                  type="sumbit"
                                  className="button  text-button small icon__right signpost"
                                  {...ButtonDataAttributes({
                                    name: componentName,
                                    text: ctaText?.value,
                                    variant: 'text-button',
                                    arrow: true,
                                    dataItemName: 'Newsletter',
                                  })}
                                >
                                  <div className="arrow-icon"></div>
                                  {ctaText?.value}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-xl">
          <div className="row">
            <div className="col">
              <div className="footer-rightside">
                <div className="row footer-newsletter">
                  <div className="col">
                    <div className="title newsLetter">
                      <TextSitecoreField field={Header} />
                    </div>
                    <div style={{ marginTop: '1.25rem' }}>
                      <TextSitecoreField field={resData} />
                      {resData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HubSpotNewsletterForm;
