import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * Renders a Rich Text Sitecore field component.
 * @param {Object} props - The props object containing the field and other props.
 * @returns {JSX.Element} - The RichText component with the field and other props.
 */
const RichTextSitecoreField = (props) => {
  const { field, ...rest } = props;
  return <RichText field={field} {...rest} />;
};

export default RichTextSitecoreField;
