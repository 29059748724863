import React from 'react';
import PropTypes from 'prop-types';
import CardFooterActionDual from '../Cards/CardFooterActionDual';
import SidebarCardSubscribe from '../SidebarCards/SidebarCardSubscribe';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CalloutBarFooterAction = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  return (
    <section
      className={'calloutbar-section footer dual'}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="calloutbar-body container-xl">
        <div className="row">
          <div className="col-lg-12 card-wrapper">
            <CardFooterActionDual fields={fields} componentName={componentName} />
            <SidebarCardSubscribe props={fields} componentName={componentName} />
          </div>
        </div>
      </div>
    </section>
  );
};

CalloutBarFooterAction.defaultProps = {
  fields: {},
};

CalloutBarFooterAction.propTypes = {
  fields: PropTypes.shape({}),
};
export default CalloutBarFooterAction;
