import React from 'react';
import PropTypes from 'prop-types';
import { default as IconClose } from './../../../static/icons/icon--close.svg';
import { ComponentDataAttributes } from '../../../constants/gtm';
import ReactPlayer from 'react-player';
const ModalPopup = ({ data = {}, showModal, closeModal, headerHeight }) => {
  return (
    <center>
      <div
        className={`videoCarousel_modal ${showModal?.show ? 'is-active' : ''}`}
        style={{ marginTop: `calc(${headerHeight}px + 2rem)` }}
        {...ComponentDataAttributes('VideoCarousel')}
      >
        <div className="videoCarousel_modal__background" onClick={closeModal}></div>
        <div className="videoCarousel_modal__content">
          <div className="videoCarousel_modal__inner">
            <div className="videoCarousel_modal__header">
              <button
                className="videoCarousel_modal__close"
                onClick={() => {
                  closeModal();
                }}
              >
                <img src={IconClose} alt="close" />
              </button>
              <h4 className="videoCarousel_modal__title">{showModal?.data?.VideoTitle?.value}</h4>
            </div>
            <div className="videoCarousel_modal__body">
              <ReactPlayer
                className="react-player"
                url={showModal?.data?.VideoURL?.value?.url}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </center>
  );
};

export default ModalPopup;

ModalPopup.defaultProps = {
  showModal: false,
  closeModal: () => {},
  setShowModal: () => {},
};

ModalPopup.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  setShowModal: PropTypes.func,
};
