import { enumReducerType } from '../common';

export const masterReducer = (
  state = {
    allcontenttypes: [],
    allfiltoptionswithduplicates: [],
    carouselshowtags: [],
  },
  action
) => {
  switch (action.type) {
    case enumReducerType.SET_CONTENT_TYPE_LIST:
      return {
        ...state,
        allcontenttypes: action.payload,
      };
    case enumReducerType.SET_FILTER_OPTION_WITH_DUPLICATE_DATA:
      return {
        ...state,
        allfiltoptionswithduplicates: [...state.allfiltoptionswithduplicates, action.payload],
      };
    case enumReducerType.SET_CAROUSEL_SHOW_TAG_LIST:
      return {
        ...state,
        carouselshowtags: action.payload,
      };
    default:
      return state;
  }
  return state;
};
