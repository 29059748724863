import React from 'react';
import PropTypes from 'prop-types';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';

const RichTextArea = ({ richText, customCssClass = 'rich-text' }) => (
  <div className={customCssClass}>
    <RichTextSitecoreField field={richText} />
  </div>
);

RichTextArea.defaultProps = {
  richText: <div></div>,
};

RichTextArea.propTypes = {
  /** WYSIWIG for Content Editor */
  richText: PropTypes.string,
};

export default RichTextArea;
