import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enumReducerType } from '../../../state/common';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { fetchCardBlockLargeList } from '../../../service';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import Button from '../../../globals/buttons/Button';
import IconFilter from '../../../static/icons/icon--filter-currentColor.svg';
import IconFilterWhite from '../../../static/icons/icon--filter-white.svg';
import IconSearch from '../../../static/icons/icon--search.svg';
import SearchBarCardBlockCardListing from '../SearchBarCardBlockCardListing/SearchBarCardBlockCardListing';
import './filterContent.scss';
import { getBrowserWindow, isSCExperienceEditorActive } from '../../../utils/HelperUtils';
import { withTranslation } from 'react-i18next';
const FilterContent = ({ title, totalItems, sortOptions, limit, offset, t, ...filters }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const dispatch = useDispatch();
  const [filterToggle, setFilterToggle] = useState(true);
  const [filterdd, setFilterdd] = useState([]);
  const [sortRelevance, setSortRelevance] = useState(false);
  const [sortPopular, setSortPopular] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('*');
  const [selectedSortOption, setSelectedSortOption] = useState(
    sortOptions[1]?.fields?.text?.value.toLowerCase()
  );
  const selectedfilts = useSelector((state) => state.filterReducer.selectedfilts);
  const selectedsearchterm = useSelector((state) => state.filterReducer.searchterm);
  const [searchToggle, setSearchToggle] = useState(false);
  const [filterGtm, setFilterGtm] = useState('');
  const [openSearchContent, setOpenSearchContent] = useState(selectedsearchterm);
  const [mobileFilterToggle, setMobileFilterToggle] = useState(searchToggle);
  const handleSortOptionChange = (event) => {
    setSelectedSortOption(event.target.value);
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'insight_sort',
          sort_order: event.target.value,
        });
      }
    }
  };
  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };
  const handleMobileSearchToggle = () => {
    setSearchToggle(!searchToggle);
    setFilterToggle(false);
    setMobileFilterToggle(searchToggle);
  };
  const handleSearchToggle = () => {
    setSearchToggle(!searchToggle);
    setOpenSearchContent('');
  };
  const handleResetSearch = () => {
    setSelectedSortOption(sortOptions[1]?.fields?.text?.value.toLowerCase());
    setSortPopular(false);
    setSortRelevance(false);
    setSearchKeyword('*');
    setFilterdd(filters.filters);
    setSearchToggle(true);
    setOpenSearchContent('');
    setMobileFilterToggle(false);
  };
  const buildPostService = async () => {
    const filtsList = [...new Set(selectedfilts.map((item) => item.key))].filter(
      (item) => item !== undefined
    );

    const InsightsFilters = filtsList
      .map(
        (item) =>
          `${item?.toLowerCase().replace(/\s/g, '')}=${selectedfilts
            .filter((curr) => curr.key === item)
            .map((currItem) =>
              currItem.key === 'Regions' || currItem.key === 'Types'
                ? currItem?.filt
                : currItem?.filt?.flat()
            )
            .join(',')}`
      )
      .join('|');
    const filterForGtm = filtsList
      .map(
        (item) =>
          `${item?.toLowerCase().replace(/\s/g, '')}=${selectedfilts
            .filter((curr) => curr.key === item)
            .map((currItem) => currItem.name)
            .join(',')}`
      )
      .join('|');
    setFilterGtm(filterForGtm);

    const result = await fetchCardBlockLargeList(
      InsightsFilters,
      limit,
      offset,
      'en',
      sortPopular,
      sortRelevance,
      searchKeyword
    );
    const type = enumReducerType.SET_SEARCHED_LARGE_LIST_DATA;
    const payload = result.data;
    const action = { type, payload };
    dispatch(action);

    const insightAction = {
      type: enumReducerType.SET_FILTER_PAYLAOD_INSIGHT,
      payload: InsightsFilters,
    };
    dispatch(insightAction);
  };

  useEffect(() => {
    setSearchKeyword(selectedsearchterm);
    setOpenSearchContent(selectedsearchterm);
    setSortRelevance(true);
  }, [selectedsearchterm]);
  useEffect(() => {
    buildPostService();
  }, [selectedfilts, sortRelevance, sortPopular, searchKeyword]);

  useEffect(() => {
    if (selectedSortOption === 'popular') {
      setSortPopular(true);
      setSortRelevance(false);
    } else if (selectedSortOption === 'relevant') {
      setSortPopular(false);
      setSortRelevance(true);
    } else if ('recent') {
      setSortPopular(false);
      setSortRelevance(false);
    }
  }, [selectedSortOption]);
  useEffect(() => {
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        if (filterGtm !== '') {
          let parts = filterGtm.split('|');
          const filters = {};
          // Dynamically map each part to the correct filter
          parts.forEach((part) => {
            const [key, value] = part.split('=');
            filters[key] = value.split(',').join(',');
          });
          if (parts) {
            window?.dataLayer?.push({
              event: 'insights_filter',
              filter1: filters.solutions || 'NA', // Solutions
              filter2: filters.regions || 'NA', // Regions
              filter3: filters.topics || 'NA', // Topics
              filter4: filters.types || 'NA', // Types
            });
          }
        }
      }
    }
  }, [filterGtm]);

  useEffect(() => {
    setFilterdd(filters?.filters);
  }, []);

  return (
    <section className="filter-content">
      <div className="container-xl">
        {title && (
          <div>
            <span className="title">{title}</span>
          </div>
        )}
        {isMobile && (
          <>
            <div className={'search_filter__item'}>
              {!searchToggle && !mobileFilterToggle && (
                <div className="filters_toggle" onClick={handleFilterToggle}>
                  {filterToggle ? (
                    <Button
                      cssClass="filtwhite"
                      text="Filter"
                      iconPosition="icon__left"
                      icon={IconFilterWhite}
                      variant="secondary-button"
                      fontSize="small"
                      addMoreClass="search"
                    />
                  ) : (
                    <Button
                      text="Filter"
                      iconPosition="icon__left"
                      icon={IconFilter}
                      variant="secondary-button"
                      fontSize="small"
                      addMoreClass="search"
                    />
                  )}
                </div>
              )}
              {!searchToggle && !mobileFilterToggle && (
                <Button
                  text=""
                  iconPosition="icon__left"
                  icon={IconSearch}
                  variant="text-button"
                  fontSize="small"
                  className="content-search-icon"
                  BtnFunction={handleMobileSearchToggle}
                  addMoreClass="search"
                />
              )}
            </div>
            {searchToggle && <SearchBarCardBlockCardListing setSearchToggle={setSearchToggle} />}
          </>
        )}
        {filterToggle && (
          <div className="search_header search_filter">
            {isMobile ? (
              filterToggle && (
                <div className="search_filter_wrapper">
                  {filterdd?.map((i) => (
                    <FilterDropdown
                      key={i.id}
                      filterItem={i?.fields?.name?.value}
                      filterName={i?.name}
                      filterSecondName={i?.fields?.secondName?.value}
                      options={i?.fields?.values}
                      offset={offset}
                    />
                  ))}
                  {!isMobile && (
                    <Button
                      text=""
                      iconPosition="icon__left"
                      icon={IconSearch}
                      variant="text-button"
                      fontSize="small"
                      className="content-search-icon"
                    />
                  )}
                </div>
              )
            ) : (
              <div className="search_filter_wrapper">
                {filterdd?.map((i) => (
                  <FilterDropdown
                    key={i.id}
                    filterItem={i?.fields?.name?.value}
                    filterName={i?.name}
                    filterSecondName={i?.fields?.secondName?.value}
                    options={i?.fields?.values}
                    offset={offset}
                  />
                ))}
                <Button
                  BtnFunction={handleSearchToggle}
                  text=""
                  iconPosition="icon__left"
                  icon={IconSearch}
                  variant="text-button"
                  fontSize="small"
                  className="content-search-icon"
                />
              </div>
            )}
          </div>
        )}
        {!isMobile && openSearchContent !== '' && openSearchContent !== undefined && (
          <div className="search-title-main">
            <p className="search-title">
              {t('you-searched-for')} <label className="search-title-link">{searchKeyword}</label>
            </p>
            <span className="resetseach button text-button" onClick={handleResetSearch}>
              {t('reset-search')}
            </span>
          </div>
        )}
        {isMobile && openSearchContent !== '' && openSearchContent !== undefined && (
          <div className="search-title-main">
            <p className="search-title">
              {t('you-searched-for')} <label className="search-title-link">{searchKeyword}</label>
            </p>
            <span className="resetseach button text-button" onClick={handleResetSearch}>
              {t('reset-search')}
            </span>
          </div>
        )}
        <div className="search_footer">
          {!isMobile && searchToggle && (
            <SearchBarCardBlockCardListing setSearchToggle={setSearchToggle} />
          )}
          <div className="search_values">
            <div className="search_count">
              <span>{t('result-count')?.replace('{0}', totalItems)}</span>
            </div>
            <div className="col search_sort">
              <div className="search_dropdown sort_order">
                <label htmlFor="sort">{t('most')}</label>
                <select id="sort" value={selectedSortOption} onChange={handleSortOptionChange}>
                  {sortOptions.map((option) => (
                    <option key={option?.name.toLowerCase()} value={option?.name.toLowerCase()}>
                      {option?.fields?.text?.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FilterContent.defaultProps = {
  title: '',
  solutions: [],
  regions: [],
  topics: [],
  types: [],
  sortOptions: [
    { label: 'Relevant', value: 'relevant' },
    { label: 'Recent', value: 'recent' },
    { label: 'Popular', value: 'popular' },
  ],
};

FilterContent.propTypes = {
  title: PropTypes.string,
  solutions: PropTypes.array,
  regions: PropTypes.array,
  topics: PropTypes.array,
  types: PropTypes.array,
  sortOptions: PropTypes.array,
};

export default withTranslation()(FilterContent);
