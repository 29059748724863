import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../../../scripts/breakpoints';
import './solutions.scss';
import Button from '../../../../../../globals/buttons/Button';
import Tag from '../../../../../../globals/buttons/Tag';
import TextSitecoreField from '../../../../../sitecorefields/TextSitecoreField';
import ButtonHref from '../../../../../../globals/buttons/ButtonHref';
import ImageSitecoreField from '../../../../../sitecorefields/ImageSitecoreField';
import { NavTopDataAttributes } from '../../../../../../constants/gtm';
import HeaderSubTag from '../SubTag';

export default function SolutionsComponent(props) {
  const {
    data,
    mobileMenuScrollTop,
    onSubSubnavOpen,
    handleMobileMenu,
    componentName = 'SiteHeader',
  } = props;
  const { fields = {} } = data;
  const { Descriptions, ExploreMoreLink, Tabs, TalkToUsLink } = fields;
  const [activeLink, setActiveLink] = useState(null);
  const limit = 6;
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });
  const contentData = Tabs && Tabs.find(({ id }) => id === activeLink);

  const handleShowComponent = (id) => {
    setActiveLink(id);
  };

  return (
    <div className="subNavigationComponent solutions">
      {!isMobile ? (
        <div className="subNavigationComponent__container">
          <div className={'solutions_navigation__links'}>
            {Tabs &&
              Tabs?.map((link, index) => {
                const { fields = {}, id } = link;
                const { TabName, TabIcon, TabIconHover } = fields;
                return (
                  <React.Fragment key={`solutions_nav__item--${index}`}>
                    <div
                      className={`solutions_navigation__item ${activeLink === id ? 'active' : ''}`}
                    >
                      <HeaderSubTag
                        text={TabName?.value}
                        cssClass="solutions_navigation__item-link solutions"
                        fontSize="medium"
                        hoverIcon={TabIconHover?.value?.src}
                        icon={TabIcon?.value?.src}
                        componentName={componentName}
                        onClick={(event) => {
                          event.preventDefault();
                          handleShowComponent(id);
                        }}
                        isActive={`${activeLink === id ? 'active' : ''}`}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
          </div>

          <div className="solutions_navigation__cta">
            <div className="solutions_navigation__solutions-cta">
              <ButtonHref
                text={ExploreMoreLink?.value?.text}
                href={ExploreMoreLink?.value?.href}
                variant="secondary-button"
                fontSize="small"
                arrow={true}
                linkType={ExploreMoreLink?.value?.linktype}
                linkFields={ExploreMoreLink}
                dataItemName={ExploreMoreLink?.value?.text}
                componentName={componentName}
              />
            </div>
            {/* <div className="solutions_navigation__contact_cta">
              <ButtonHref
                fontSize="small"
                variant="primary-button"
                arrow={false}
                text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                href={TalkToUsLink?.value?.href}
                linkType={TalkToUsLink?.value?.linktype}
                linkFields={TalkToUsLink}
                componentName={componentName}
                dataItemName={TalkToUsLink?.value?.text}
              />
            </div> */}
          </div>
        </div>
      ) : (
        <div className="subNavigationComponent ">
          <div className={'subNavComponent mobileNav'}>
            <div className="subNavComponent__inner">
              <div className="subNavComponent__left">
                {isMobile && (
                  <a href={fields?.Link?.value?.href} className="subNavComponent__component-name">
                    {data?.name}
                  </a>
                )}
                <div className="subNavComponent__title">
                  {Descriptions?.value && Parser(Descriptions?.value)}
                </div>
              </div>

              <div className="subNavComponent__cta">
                <div className="subNavComponent__solutions-cta">
                  <ButtonHref
                    text={ExploreMoreLink?.value?.text}
                    href={ExploreMoreLink?.value?.href}
                    variant="secondary-button"
                    fontSize="small"
                    arrow={true}
                    cssClass="explore-more-link"
                    linkType={ExploreMoreLink?.value?.linktype}
                    linkFields={ExploreMoreLink}
                    dataItemName={data?.name}
                    componentName={componentName}
                  />
                </div>
              </div>

              <div className="subNavComponent__right">
                <div
                  className={`subNavComponent__list ${isMobile ? 'mobile' : ''} links_${
                    Tabs?.length
                  }`}
                >
                  {Tabs &&
                    Tabs?.map((link, index) => {
                      const { TabName, TabIcon } = link?.fields;
                      const { id } = link;
                      return (
                        <React.Fragment key={`solutions_nav__item--${index}`}>
                          <Button
                            text={TabName?.value}
                            variant="text-button"
                            iconPosition="icon__left"
                            alignment="left"
                            arrow={true}
                            BtnFunction={(event) => {
                              event.preventDefault();
                              mobileMenuScrollTop();
                              handleShowComponent(id);
                              onSubSubnavOpen(true);
                            }}
                            dataItemName={TabName?.value}
                            icon={TabIcon?.value.src || ''}
                            type="mobile-header"
                          />
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="subNavComponent__contact_cta">
                <ButtonHref
                  variant="primary-button"
                  text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                  fontSize="small"
                  arrow={false}
                  linkType={TalkToUsLink?.value?.linktype}
                  linkFields={TalkToUsLink}
                  dataItemName={data?.name}
                  componentName={componentName}
                  href={TalkToUsLink?.value?.href}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {contentData && (
        <div
          className={`subNavComponent solutions ${isMobile ? 'subSubNavComponent mobileNav' : ''} `}
        >
          <div className="subNavComponent__inner">
            <div className="subNavComponent__left">
              {!isMobile && (
                <div className={`subNavComponent__icon ${contentData?.fields?.Image?.value?.src}`}>
                  <ImageSitecoreField field={contentData?.fields?.Image} />
                  {contentData?.fields?.TabName?.value}
                </div>
              )}
              <div className="subNavComponent__title solutions">
                <TextSitecoreField field={contentData?.fields?.TabName} />
              </div>
              <div className="subNavComponent__description">
                <p>
                  <TextSitecoreField field={contentData?.fields?.Description} />
                </p>
              </div>
              <div className="subNavComponent__cta">
                <Button
                  text={contentData?.fields?.ctaLink?.value?.text}
                  alignment="left"
                  variant="text-button"
                  fontSize="medium"
                  arrow={true}
                  href={contentData?.fields?.ctaLink && contentData?.fields?.ctaLink?.value?.href}
                  linkType={
                    contentData?.fields?.ctaLink && contentData?.fields?.ctaLink?.value?.linktype
                  }
                  linkFields={contentData?.fields?.ctaLink}
                  componentName={componentName}
                  dataItemName={contentData?.fields?.TabName?.value}
                  BtnFunction={() => {
                    location.href = contentData?.fields?.ctaLink?.value?.href;
                  }}
                />
              </div>
            </div>
            <div className="subNavComponent__right">
              <div
                className={`subNavComponent__list ${isMobile ? 'mobile' : ''} links_${
                  contentData?.fields?.LinksCollections?.length
                }`}
              >
                {contentData?.fields?.LinksCollections?.slice(0, limit).map((item, index) => {
                  const { name = '', description = '' } = item;
                  const { ctaLink, ctaText } = item?.fields;
                  return !isMobile ? (
                    <React.Fragment key={`solutions__item--${index}`}>
                      <a
                        href={ctaLink?.value?.href || '/'}
                        className={'card subnav link_card'}
                        {...NavTopDataAttributes({
                          name: componentName,
                          itemLevel: 'L2',
                          dataNavItemName: name,
                          dataLinkText: ctaLink?.value?.href || '/',
                          dataLinkImageUrl: '',
                        })}
                      >
                        <div className="card-body">
                          <div className="arrow-icon" />
                          <h3>{ctaText?.value}</h3>
                          {description && <p>{description}</p>}
                        </div>
                      </a>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`solutions__item--${index}`}>
                      <Button
                        text={name}
                        variant="text-button"
                        alignment="left"
                        arrow={true}
                        href={ctaLink?.value?.href}
                        linkType={ctaLink?.value?.linktype}
                        linkFields={ctaLink}
                        componentName={componentName}
                        dataItemName={name}
                        BtnFunction={() => {
                          location.href = ctaLink?.value?.href;
                        }}
                      />
                    </React.Fragment>
                  );
                })}
                {isMobile && (
                  <div className="solutions_navigation__contact_cta mobile">
                    <ButtonHref
                      fontSize="small"
                      variant="primary-button"
                      arrow={false}
                      text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                      href={TalkToUsLink?.value?.href}
                      linkType={TalkToUsLink?.value?.linktype}
                      linkFields={TalkToUsLink}
                      componentName={componentName}
                      dataItemName={TalkToUsLink?.value?.text}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

SolutionsComponent.defaultProps = {
  data: {
    fields: {
      Descriptions: {
        value: '',
      },
      ExploreMoreLink: {
        value: {
          href: '',
          text: '',
          linktype: '',
        },
      },
      LinksCollections: [],
      Tabs: [],
      TalkToUsLink: {
        value: {
          href: '',
          text: '',
          linktype: '',
        },
      },
    },
  },
  mobileMenuScrollTop: () => {},
  onSubSubnavOpen: () => {},
  handleMobileMenu: () => {},
};

SolutionsComponent.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({
      Descriptions: PropTypes.shape({
        value: PropTypes.string,
      }),
      ExploreMoreLink: PropTypes.shape({
        value: PropTypes.shape({
          href: PropTypes.string,
          text: PropTypes.string,
          linktype: PropTypes.string,
        }),
      }),
      LinksCollections: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          url: PropTypes.string,
        })
      ),
      Tabs: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          fields: PropTypes.shape({
            TabName: PropTypes.shape({
              value: PropTypes.string,
            }),
            Description: PropTypes.shape({
              value: PropTypes.string,
            }),
            ctaLink: PropTypes.shape({
              value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
                linktype: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      TalkToUsLink: PropTypes.shape({
        value: PropTypes.shape({
          href: PropTypes.string,
          text: PropTypes.string,
          linktype: PropTypes.string,
        }),
      }),
    }),
  }),
  mobileMenuScrollTop: PropTypes.func,
  onSubSubnavOpen: PropTypes.func,
  handleMobileMenu: PropTypes.func,
};
