import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import CountryLinkAccordionItem from './CountryLinkAccordionItem';

import './countrylinkaccordion.scss';
import { ComponentDataAttributes } from '../../../constants/gtm';

/**
 * @description - to render accordion
 * @param {*} param0 -Input props.
 * @returns {HTML} -  accordion html
 */
const CountryLinkAccordion = ({ cssClass, offset, linkItems, componentName = '' }) => {
  const { items } = linkItems;
  const [openAccordion, setOpenAccordion] = useState();

  /**
   * @description - to handle accordion open
   * @param {*} index -index
   * @return {undefined}
   */
  const handleAccordion = (index) => {
    setOpenAccordion(index);
  };

  return (
    <section
      className={`accordion-section-advanced ${cssClass}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="accordion-container">
        <div className="row">
          <div className={offset ? 'col-lg-10 offset-lg-1' : 'col-lg-12'}>
            <div className="row">
              <div className="col">
                {Array.isArray(items) &&
                  items.map((item, index) => {
                    return (
                      <CountryLinkAccordionItem
                        region={item}
                        active={index === openAccordion ? true : false}
                        onAccordionClick={handleAccordion}
                        key={`${item?.regionName}-${index}`}
                        index={index}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CountryLinkAccordion.defaultProps = {
  cssClass: '',
  offset: '',
  linkItems: {
    items: [],
  },
  showAccordion: false,
  doShowAccordion: () => {},
  t: () => {},
  componentName: '',
};

CountryLinkAccordion.propTypes = {
  cssClass: PropTypes.string,
  offset: PropTypes.string,
  linkItems: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  showAccordion: PropTypes.bool,
  doShowAccordion: PropTypes.func,
  t: PropTypes.func,
  componentName: PropTypes.string,
};

export default withTranslation()(CountryLinkAccordion);
