/**
 * This suggestions export is to show the design team what the suggestions
 * will look like in the search field. It will be replaced with a call to
 * the API to get the suggestions.
 */

export const suggestions = [
  'Attract retain',
  'Attract insights',
  'Attract',
  'Attract talent',
  'Attract value',
  'Attract marketing',
  'Attract source',
  'Attract solutions',
  'Jobs in healthcare industry',
  'How to write a resume',
  'Common interview questions and answers',
  'Tips for job interviews',
  'Entry-level jobs in IT',
  'Accounting jobs near me',
  'Professional development courses',
  'Remote work opportunities',
  'Benefits of temporary staffing',
  'How to negotiate salary',
  'Top industries for job growth',
  'Construction jobs in [city]',
  'Tips for working with a staffing agency',
  'Engineering jobs in [city]',
  'How to prepare for a job fair',
  'Administrative assistant jobs near me',
  'Job search strategies',
  'Skills in high demand',
  'Manufacturing jobs in [city]',
  'How to succeed in a new job',
];
