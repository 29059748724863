import React, { lazy, Suspense, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { chartTypeCompiler } from '../../../utils/HelperUtils';
import './dataBlock.scss';
import { content } from './data';
import { getBarAlt } from './shape';

const DataBlock = (props) => {
  const { size, chartType } = props;

  const [chartData, setChartData] = useState([]);
  const [chartT, setChartT] = useState(null);

  let ChartComponent;

  switch (chartTypeCompiler(chartType)) {
    case 'bar':
      ChartComponent = lazy(() => import('./BarChart/BarChart'));
      break;
    case 'Bar alt':
      ChartComponent = lazy(() => import('./BarAlt/BarAlt'));
      break;
    case 'bubble':
      ChartComponent = lazy(() => import('./BubbleChart/BubbleChart'));
      break;
    case 'Doughnut':
      ChartComponent = lazy(() => import('./DoughnutChart/DoughnutChart'));
      break;
    case 'line':
      ChartComponent = lazy(() => import('./LineChart/LineChart'));
      break;
    case 'linear-gauge':
      ChartComponent = lazy(() => import('./LinearGauge/LinearGauge'));
      break;
    default:
      ChartComponent = lazy(() => import('./BarChart/BarChart'));
      break;
  }
  useEffect(() => {
    if (chartT) {
      const resultData = getBarAlt(props, chartT);
      setChartData(resultData);
    }
  }, [chartT]);
  useEffect(() => {
    setChartT(props?.fields?.type?.value);
  }, []);

  return (
    <section>
      {chartT && (
        <div className="data-block__container">
          {
            <div className="data-block__title-container">
              <h4 className="data-block__title">{chartData?.title}</h4>
              {(chartData?.description || chartData?.descLabel) && (
                <div className="data-block__desc-container">
                  {chartData?.descLabel && (
                    <label className="data-block__label">{chartData?.descLabel}</label>
                  )}
                  {chartData?.description && (
                    <p className="data-block__text">{chartData?.description}</p>
                  )}
                </div>
              )}
            </div>
          }
          <div className="data-block__chart-container">
            <Suspense fallback={<div className="data-block__fallback">Loading Chart...</div>}>
              {ChartComponent && chartData && (
                <ChartComponent
                  data={chartData?.data}
                  size={size}
                  color={chartData?.color}
                  backgroundColor={chartData?.backgroundColor}
                  useGradient={chartData?.useGradient}
                />
              )}
            </Suspense>
          </div>
        </div>
      )}
    </section>
  );
};

DataBlock.defaultProps = {
  color: 'blue-violet',
  size: 'M',
  chartType: 'linear-gauge',
  data: content.find((o) => o.type === 'linear-gauge').data,
};

DataBlock.propTypes = {
  title: PropTypes.string,
  descLabel: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.oneOf(['blue-violet', 'green-blue']),
  size: PropTypes.oneOf(['Tiny', 'XXS', 'XS', 'S', 'M', 'L', 'XL']),
  chartType: PropTypes.oneOf(['bar', 'Bar alt', 'bubble', 'doughnut', 'line', 'linear-gauge'])
    .isRequired,
  useGradient: PropTypes.bool,
  data: PropTypes.object.isRequired,
  cta: PropTypes.element,
};

export default DataBlock;
