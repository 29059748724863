import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../globals/forms/Checkbox';
import Button from '../../../globals/buttons/Button';
import IconClose from '../../../static/icons/icon--close.svg';
import '../FilterDropdown/filterdropdown.scss';
import { enumReducerType } from '../../../state/common';
import { fetchSearchResult } from '../../../service';
import {
  getBrowserWindow,
  isBrowserDocumentAvailable,
  isSCExperienceEditorActive,
} from '../../../utils/HelperUtils';

const SearchResultFilterDropdown = ({ filterItem, options = [], checked, handleAPICall }) => {
  const [dataOptions, setDataOptions] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  const [sortRelevance] = useState('false');
  const [sortPopular] = useState('false');
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [count, setCount] = useState(0);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showclearBtn, setShowClearBtn] = useState(false);
  const filterDropdownRef = useRef(null);
  const filterDropdownTitleRef = useRef(null);
  const filterDropdownCountRef = useRef(null);
  const duplicateSelectedFilts = useSelector(
    (state) => state.masterReducer.allfiltoptionswithduplicates
  );
  const selectedsearchfilts = useSelector((state) => state.searchfilterReducer.selectedsearchfilts);
  const dispatch = useDispatch();
  const unique_array = () => {
    const set_array_all = options.map((option) =>
      option?.fields?.subTag?.fields?.title?.value
        ? {
            filt: filterItem,
            parentid: option.id,
            parentname: option.name,
            name: option?.fields?.subTag?.fields?.title?.value,
            id:
              filterItem === 'Regions' || filterItem === 'Types'
                ? option?.fields?.subTag?.fields?.title?.value?.toLowerCase()
                : option?.fields?.subTag?.id,
          }
        : {
            filt: filterItem,
            parentid: option.id,
            parentname: option.name,
            name: option?.fields?.title?.value,
            id:
              filterItem === 'Regions' || filterItem === 'Types'
                ? option?.fields?.title?.value?.toLowerCase()
                : option?.id,
          }
    );
    const type = enumReducerType.SET_FILTER_OPTION_WITH_DUPLICATE_DATA;
    const payload = set_array_all;
    const action = { type, payload };
    dispatch(action);
    setDataOptions([...new Map(set_array_all.map((item) => [item.id, item])).values()]);
  };

  const buildPostService = async () => {
    const filtsList = [...new Set(selectedsearchfilts.map((item) => item.key))];
    const InsightsFilters = filtsList
      .slice(1, 2)
      .map(
        (item) =>
          `${item?.toLowerCase().replace(/\s/g, '')}=${selectedsearchfilts
            .filter((curr) => curr.key === item)
            .map((currItem) =>
              currItem.key === 'Regions' || currItem.key === 'Types'
                ? currItem?.filt
                : currItem?.filt?.flat()
            )
            .join(',')}`
      )
      .join('|');
    if (InsightsFilters !== '' && !isApiCalled) {
      const insightAction = {
        type: enumReducerType.SET_SEARCH_FILTER_PAYLAOD_INSIGHT,
        payload: InsightsFilters,
      };
      dispatch(insightAction);
      setIsDefault(true);
      setIsApiCalled(true);
    }
  };

  const handleCheckboxChange = (isChecked, option) => {
    const parentsIdList = duplicateSelectedFilts
      .flat()
      .filter((item) => item?.filt === option?.filt)
      .filter((item) => item?.id === option?.id)
      .map((curr) => curr?.parentid);
    let type = enumReducerType.SET_SEARCH_FILTER_SELECTED_DATA;
    if (isChecked) {
      setCount(count + 1);
    } else {
      setCount(count - 1);
      type = enumReducerType.REMOVE_SEARCH_FILTER_SELECTED_DATA;
    }
    const payload =
      filterItem === 'Regions' || filterItem === 'Types'
        ? { ...option, key: option?.filt, filt: option?.id }
        : { ...option, filt: parentsIdList, key: option?.filt };
    const action = { type, payload };
    dispatch(action);
    /** GTM data layer events - Do not delete or override */

    // if (!isSCExperienceEditorActive) {
    //   if (getBrowserWindow) {
    //     if (filterItem !== '') {
    //       window?.dataLayer?.push({
    //         event: 'insights_filter',
    //         filterItem: option?.name || '',
    //       });
    //     }
    //   }
    // }
  };

  const handleClickAway = (event) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
      setShowFilterOptions(false);
    }
  };

  // When you click the clear all button, uncheck all checkboxes and update count
  const handleClearAll = () => {
    const checkboxes = filterDropdownRef.current.querySelectorAll(
      '.filter_options__Dropdown .dropdown__item input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox, index) => {
      if (checkbox.checked) {
        handleCheckboxChange(false, dataOptions[index]);
        checkbox.checked = false;
      }
    });
    setCount(0);
    const checkboxesAll = filterDropdownRef.current.querySelectorAll(
      '.filter_options__Dropdown .dropdown__item_all input[type="checkbox"]'
    );
    checkboxesAll.length > 0 && (checkboxesAll[0].checked = false);
  };

  // When you click the clear all button, uncheck all checkboxes and update count
  const handleCheckAll = () => {
    const checkboxes = filterDropdownRef.current.querySelectorAll(
      '.filter_options__Dropdown .dropdown__item input[type="checkbox"]'
    );
    const checkboxesAll = filterDropdownRef.current.querySelectorAll(
      '.filter_options__Dropdown .dropdown__item_all input[type="checkbox"]'
    );
    checkboxesAll.length > 0 &&
      checkboxes.forEach((checkbox, index) => {
        if (checkboxesAll[0].checked) {
          handleCheckboxChange(true, dataOptions[index]);
          checkbox.checked = true;
        } else {
          handleCheckboxChange(false, dataOptions[index]);
          checkbox.checked = false;
        }
      });
    if (checkboxesAll.length > 0) {
      checkboxesAll[0].checked ? setCount(dataOptions.length) : setCount(0);
    }
  };
  useEffect(() => {
    filterDropdownTitleRef.current.setAttribute('data-filter-count', `${count}`);
    filterDropdownCountRef.current.innerText = count;
    count > 0 ? setShowClearBtn(true) : setShowClearBtn(false);
  }, [count]);

  useEffect(() => {
    if (selectedsearchfilts.InsightsFilters !== '' && !isDefault) buildPostService();
  }, [selectedsearchfilts]);
  useEffect(() => {
    if (isBrowserDocumentAvailable) {
      document.addEventListener('mousedown', handleClickAway);
      unique_array();

      return () => {
        document.removeEventListener('mousedown', handleClickAway);
      };
    }
  }, []);

  return (
    <div
      ref={filterDropdownRef}
      className={[
        'filterDropdown',
        showFilterOptions === false ? 'dropdown_closed' : 'dropdown_open',
        options && options.length === 0 ? 'disabled' : '',
      ].join(' ')}
    >
      <div
        ref={filterDropdownTitleRef}
        className={['filterDropdownTitle', options && options.length === 0 ? 'disabled' : ''].join(
          ' '
        )}
        onClick={() => setShowFilterOptions((prevState) => !prevState)}
      >
        <span className="filter__title">{filterItem}</span>
      </div>

      <div className="filter_options__Dropdown">
        <div className="filter_options__Dropdown__top">
          <div className="count_selected">
            <strong>
              <span ref={filterDropdownCountRef}>0</span>&nbsp;{filterItem}
            </strong>
            &nbsp;selected
          </div>
          <div className="clear_all">
            {showclearBtn && (
              <Button
                text="Clear all"
                icon={IconClose}
                fontSize="small"
                variant="secondary-button"
                cssClass=""
                BtnFunction={handleClearAll}
              />
            )}
          </div>
        </div>
        <div className="filter_options__Dropdown__bottom">
          {options && options.length > 0 ? (
            filterItem === 'Types' ? (
              <div key={`${filterItem}-alltypes`} className={'dropdown__item_all checkall'}>
                <Checkbox
                  id="all_types"
                  text="All Types"
                  checked={checked[0]}
                  onChange={handleCheckAll}
                />
              </div>
            ) : filterItem === 'Solutions' ? (
              <div
                key={`${filterItem}-allsolutions`}
                className={'dropdown__item_all checkall all-solutions'}
              >
                <Checkbox
                  id="all_solutions"
                  text="All Solutions"
                  checked={checked[0]}
                  onChange={handleCheckAll}
                />
              </div>
            ) : (
              ''
            )
          ) : (
            <div className={'dropdown__item'}>No {filterItem} available</div>
          )}

          {dataOptions &&
            dataOptions?.map((option, index) => (
              <div
                key={`${filterItem}-${index}`}
                className={`dropdown__item ${option?.name
                  ?.toLowerCase()
                  .replace(/&+/g, '')
                  .replace(/\s+/g, '-')}`}
              >
                <Checkbox
                  id={`${filterItem}-${index}`}
                  text={option.name}
                  checked={checked[index]}
                  onChange={(event) => {
                    handleCheckboxChange(event.target.checked, option);
                  }}
                  value={`${option?.name?.toLowerCase().replace(/&+/g, '').replace(/\s+/g, '-')}`}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SearchResultFilterDropdown;

SearchResultFilterDropdown.defaultProps = {
  filterItem: 'Filter',
  options: [],
  checked: [],
};

SearchResultFilterDropdown.propTypes = {
  filterItem: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  checked: PropTypes.arrayOf(PropTypes.bool),
};
