import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useDispatch } from 'react-redux';
import CardInsightsCarouselFull from '../../../core/Cards/CardInsightsCarouselFull';
import CardInsightsFeatured from '../../../core/Cards/CardInsightsFeatured';
import { enumReducerType } from '../../../../state/common';
import { isEmpty } from 'lodash';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';

// This is a duplicate Carousel comonent for DataBlockInsight. Don't remove it -- By Sumanta

const CustomCarouselDataBlockInsight = (props) => {
  const [showTags, setShowTags] = useState([]);
  const { data } = props;
  const dispatch = useDispatch();
  const { title, type, items, fields } = data;

  const {
    carouselTitle,
    featuredInsight,
    tagsToShow,
    backgroundTextColor,
    insightitems,
    isShowGlobalButton,
  } = fields;
  let slidesToShow = 0;
  switch (items && items.length) {
    case 1:
      slidesToShow = 1;
      break;
    case 2:
      slidesToShow = 2;
      break;
    default:
      slidesToShow = 3.2;
  }

  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    variableWidth: true,
    swipeToSlide: true,
    arrows: true,
    slide: '.card',
    accessibility: true,
    // afterChange: () => hideAriaHiddenTiles(),
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">
          {i < 9 ? '0' : ''}
          {i + 1}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">
          {insightitems?.length < 10 ? '0' : ''}
          {insightitems && insightitems.length + featuredInsight.length}
        </span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1.39,
          slidesToScroll: 1,
          rows: 2,
        },
      },
    ],
  };
  useEffect(() => {
    tagsToShow &&
      tagsToShow.map((item) => {
        const type = enumReducerType.SET_CAROUSEL_SHOW_TAG_LIST;
        let payload = [];
        switch (item?.fields?.name?.value.replace(/\s/g, '').toLowerCase()) {
          case 'lineofbusiness':
            setShowTags(fields.lineOfBusiness);
            payload = fields.lineOfBusiness;
            break;
          case 'solutions':
            setShowTags(fields.solutions);
            payload = fields.solutions;
            break;
          default:
            setShowTags([]);
        }
        const action = { type, payload };
        dispatch(action);
      });
  }, [tagsToShow]);
  return (
    <section
      className={`carousel datablock_carousel_slick standard insights cards__${
        insightitems && insightitems.length
      } ${type} ${
        isEmpty(backgroundTextColor) ? 'secondary' : backgroundTextColor?.fields?.text?.value
      }`}
    >
      <div className="container-xl">
        <div className="row section-header">
          <div className="col-8">
            {type === 'component' ? (
              <h2
                className={`title ${
                  backgroundTextColor?.fields?.text?.value === 'primary'
                    ? 'on-primary'
                    : 'on-secondary'
                }`}
              >
                <TextSitecoreField field={carouselTitle} />
                {title}
              </h2>
            ) : (
              <h3
                className={`title ${
                  backgroundTextColor?.fields?.text?.value === 'primary'
                    ? 'on-primary'
                    : 'on-secondary'
                }`}
              >
                <TextSitecoreField field={carouselTitle} />
                {title}
              </h3>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col carousel-wrapper">
            <Slider {...settingsSliderNav}>
              {featuredInsight &&
                featuredInsight.length > 0 &&
                showTags &&
                featuredInsight.map((item1, index1) => (
                  <CardInsightsCarouselFull
                    key={index1}
                    title={item1?.fields?.Title?.value}
                    subTitle={item1?.fields?.subTitle?.value}
                    contentTags={item1?.fields?.contentType}
                    date={item1?.fields?.publicationDate?.value}
                    text={item1?.fields?.Content?.value}
                    isShowGlobalButton={isShowGlobalButton ? isShowGlobalButton.value : false}
                    image={item1.fields?.image}
                    item={item1}
                    fields={fields}
                    backgroundColor={
                      backgroundTextColor?.fields?.text?.value === 'primary'
                        ? 'on-primary'
                        : 'on-secondary'
                    }
                  />
                ))}
              {insightitems &&
                insightitems.length > 0 &&
                insightitems.map((item, index2) => (
                  <CardInsightsFeatured
                    key={index2}
                    item={item}
                    date={item.fields.publicationDate}
                    title={item?.fields?.Title}
                    text={item.text}
                    ctaText={item.ctaText}
                    backgroundColor={
                      backgroundTextColor?.fields?.text?.value === 'primary'
                        ? 'on-primary'
                        : 'on-secondary'
                    }
                    contentTags={item?.fields?.contentType}
                    tagsToShowList={tagsToShow}
                  />
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

CustomCarouselDataBlockInsight.defaultProps = {
  // eslint-disable-next-line quotes
  title: "How we've helped our customers",
  backgroundTextColor: 'secondary',
  type: 'component',
  items: [],
  fields: {},
};

CustomCarouselDataBlockInsight.propTypes = {
  title: PropTypes.string,
  backgroundTextColor: PropTypes.string,
  type: PropTypes.oneOf(['component', 'sub-component']),
  /** Cards */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      ctaText: PropTypes.string,
    })
  ),
};

export default CustomCarouselDataBlockInsight;
