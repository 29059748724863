/**
 * ErrorBoundary component that catches errors in its child components and displays a fallback UI instead of crashing the whole app.
 * @component
 * @example
 * return (
 *   <ErrorBoundary>
 *     <MyComponent />
 *   </ErrorBoundary>
 * )
 */
import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }
  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <div className="card my-5">
          <div className="card-header">
            <p>There was an error in loading this page.</p>
          </div>
          <div className="card-body">
            <details className="error-details">
              {errorInfo && errorInfo?.componentStack?.toString()}
            </details>
          </div>
        </div>
      );
    }
    // next code block goes here
    return this.props.children;
  }
}
