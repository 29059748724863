import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../../../scripts/breakpoints';
import './insights.scss';
import Button from '../../../../../../globals/buttons/Button';
import TextSitecoreField from '../../../../../sitecorefields/TextSitecoreField';
import ButtonHref from '../../../../../../globals/buttons/ButtonHref';
import ImageSitecoreField from '../../../../../sitecorefields/ImageSitecoreField';
import { NavTopDataAttributes } from '../../../../../../constants/gtm';
import { isEmpty } from 'lodash';

export default function InsightsComponent(props) {
  const { data, handleMobileMenu, componentName = 'SiteHeader' } = props;
  const { fields = {}, name } = data;
  const { Descriptions, ExploreMoreLink, LinksCollections, TalkToUsLink } = fields;
  const contentData = LinksCollections;

  const limit = 4;

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <div className={'subNavigationComponent'}>
      {contentData && (
        <div className={`subNavComponent insights ${isMobile ? 'mobileNav' : ''} `}>
          <div className="subNavComponent__inner">
            <div className="subNavComponent__left">
              {isMobile && (
                <a href={fields?.Link?.value?.href} className="subNavComponent__component-name">
                  {name}
                </a>
              )}
              <div className="subNavComponent__title">
                <TextSitecoreField field={Descriptions} />
              </div>
              <div className="subNavComponent__cta">
                {ExploreMoreLink &&
                  !isEmpty(ExploreMoreLink?.value?.text) &&
                  !isEmpty(ExploreMoreLink?.value?.href) && (
                    <div className="subNavComponent__solutions-cta">
                      <ButtonHref
                        text={ExploreMoreLink?.value?.text}
                        href={ExploreMoreLink?.value?.href}
                        variant="secondary-button"
                        fontSize="small"
                        arrow={true}
                        linkType={ExploreMoreLink?.value?.linktype}
                        linkFields={ExploreMoreLink}
                        componentName={componentName}
                        dataItemName={name}
                      />
                    </div>
                  )}
                {/* {!isMobile && (
                  <div className="subNavComponent__contact_cta">
                    <ButtonHref
                      text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                      href={TalkToUsLink?.value?.href}
                      linkType={TalkToUsLink?.value?.linktype}
                      linkFields={TalkToUsLink}
                      fontSize="small"
                      componentName={componentName}
                      dataItemName={name}
                    />
                  </div>
                )} */}
              </div>
            </div>
            <div className="subNavComponent__right">
              <div className={`subNavComponent__list ${isMobile ? 'mobile' : ''}`}>
                {contentData &&
                  contentData?.slice(0, limit)?.map((item, index) => {
                    const { fields } = item;
                    const { Image, ctaText, ctaLink } = fields;
                    return !isMobile ? (
                      <React.Fragment key={`insights__item--${index}`}>
                        <a
                          href={ctaLink?.value?.href || '/'}
                          className={'card link_card'}
                          {...NavTopDataAttributes({
                            name: componentName,
                            itemLevel: 'L1',
                            dataNavItemName: ctaText?.value || '',
                            dataLinkText: '',
                            dataLinkImageUrl: '',
                          })}
                        >
                          <div className="card-image">
                            <ImageSitecoreField field={Image} />
                          </div>
                          <div className="card-body">
                            <h3>
                              <TextSitecoreField field={ctaText} />
                            </h3>
                            <Button
                              text={ctaLink?.value?.text}
                              alignment="left"
                              variant="text-button"
                              cssClass="white"
                              fontSize="medium"
                              arrow={true}
                              href={ctaLink?.value?.href}
                              linkType={ctaLink?.value?.linktype}
                              linkFields={ctaLink}
                              componentName={componentName}
                              dataItemName={ctaText?.value}
                              BtnFunction={() => {
                                location.href = ctaLink?.value?.href;
                              }}
                            />
                          </div>
                        </a>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`insights__item--${index}`}>
                        <Button
                          variant="text-button"
                          arrow={true}
                          text={ctaText?.value || ctaLink?.value?.text}
                          href={ctaLink?.value?.href}
                          linkType={ctaLink?.value?.linktype}
                          linkFields={ctaLink}
                          componentName={componentName}
                          dataItemName={ctaText?.value || ctaLink?.value?.text}
                          BtnFunction={() => {
                            location.href = ctaLink?.value?.href;
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
            {isMobile && (
              <div className="subNavComponent__contact_cta mobile ">
                <ButtonHref
                  fontSize="small"
                  variant="primary-button"
                  arrow={false}
                  text={TalkToUsLink?.value?.text ? TalkToUsLink?.value?.text : 'Talk to us'}
                  href={TalkToUsLink?.value?.href}
                  linkType={TalkToUsLink?.value?.linktype}
                  linkFields={TalkToUsLink}
                  componentName={componentName}
                  dataItemName="Insights"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

InsightsComponent.defaultProps = {
  data: {
    fields: {
      Descriptions: {
        value: '',
      },
      ExploreMoreLink: {
        value: {
          text: '',
          href: '',
        },
      },
      LinksCollections: [
        {
          url: '',
          fields: {
            Image: {
              value: {
                src: '',
                alt: '',
              },
            },
            ctaText: {
              value: '',
            },
            ctaLink: {
              value: {
                text: '',
                href: '',
              },
            },
          },
        },
      ],
      TalkToUsLink: {
        value: {
          text: '',
          href: '',
        },
      },
    },
  },
};

InsightsComponent.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({
      Descriptions: PropTypes.shape({
        value: PropTypes.string,
      }),
      ExploreMoreLink: PropTypes.shape({
        value: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      }),
      LinksCollections: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          fields: PropTypes.shape({
            Image: PropTypes.shape({
              value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
              }),
            }),
            ctaText: PropTypes.shape({
              value: PropTypes.string,
            }),
            ctaLink: PropTypes.shape({
              value: PropTypes.shape({
                text: PropTypes.string,
                href: PropTypes.string,
              }),
            }),
          }),
        })
      ),
      TalkToUsLink: PropTypes.shape({
        value: PropTypes.shape({
          text: PropTypes.string,
          href: PropTypes.string,
        }),
      }),
    }),
  }),
  handleMobileMenu: PropTypes.func,
};
