import React from 'react';
import PropTypes from 'prop-types';
import { ButtonDataAttributes, ComponentDataAttributes } from '../../../constants/gtm';
import './formsidepanelcalloutbar.scss';
import { useSelector } from 'react-redux';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { getFooterCallOutBarStylesFromBackgroundColor } from '../../../utils/HelperUtils';
import { isEmpty } from 'lodash';
import ButtonHref from '../../../globals/buttons/ButtonHref';

const FormSidePanelCalloutBar = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    formBackGroundColor,
    Title,
    SubTitle,
    ctaText,
    ctaLink,
    optCtaIconPosition,
    ctaArrow,
    ctaIcon,
  } = fields;
  const btnCss =
    getFooterCallOutBarStylesFromBackgroundColor(formBackGroundColor?.fields?.text?.value) ===
    'primary'
      ? 'violet'
      : 'white';
  const countryData = useSelector((state) => state.filterReducer?.countryData);
  return (
    (isEmpty(countryData) || countryData?.countryClick === false) && (
      <section
        className={'calloutbar-section footer dual global-calloutbar'}
        {...ComponentDataAttributes(componentName)}
      >
        <div className="calloutbar-body container-xl">
          <div className="row">
            <div className="col-xl-12 col-lg-12 card-wrapper">
              <div
                className={`card sidebar sidebar__subscribe ${getFooterCallOutBarStylesFromBackgroundColor(
                  formBackGroundColor?.fields?.text?.value
                )}`}
              >
                <p className="title">
                  <TextSitecoreField field={Title} />
                </p>
                <p className="sub-title">
                  <TextSitecoreField field={SubTitle} />
                </p>
                <div
                  className={`${
                    getFooterCallOutBarStylesFromBackgroundColor(
                      formBackGroundColor?.fields?.text?.value
                    ) === 'secondary' ||
                    getFooterCallOutBarStylesFromBackgroundColor(
                      formBackGroundColor?.fields?.text?.value
                    ) === 'violet'
                      ? 'submitted-message'
                      : 'submitted-message-black'
                  } hs-main-font-element`}
                ></div>
                <div className="newsLetter_left">
                  {ctaText?.value && (
                    <ButtonHref
                      href={ctaLink && ctaLink?.value?.href}
                      arrow={ctaArrow ? ctaArrow?.value : false}
                      linkType={ctaLink && ctaLink?.value?.linktype}
                      linkFields={ctaLink && ctaLink}
                      componentName={componentName}
                      variant="primary-button"
                      text={ctaText?.value}
                      cssClass={`button primary-button medium ${btnCss} icon__right signpost contact-submit`}
                      linktype={ctaLink?.value?.linktype}
                      icon={ctaIcon && ctaIcon?.value?.src}
                      iconPosition={
                        optCtaIconPosition ? `icon__${optCtaIconPosition?.fields?.text?.value}` : ''
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};

FormSidePanelCalloutBar.defaultProps = {
  fields: {},
};

FormSidePanelCalloutBar.propTypes = {
  fields: PropTypes.shape({}),
};
export default FormSidePanelCalloutBar;
