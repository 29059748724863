import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardServices from '../Cards/CardServices';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';

const CardBlockSolutions = (props) => {
  const {
    type = 'component',
    fields,
    rendering: { componentName = '' },
  } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  const toggleAccordion = (item) => {
    setActiveIndex((prevIndex) => (prevIndex === item.id ? -1 : item.id));
  };

  return (
    <section
      className={`card-block solutions solutions__callout ${
        fields?.ctaBackgroundColor?.fields?.text?.value
      } ${type === 'component' ? 'component' : 'sub-component'}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row header-wrapper service">
          <div className="col section-header">
            <h2 className="title-service">
              <TextSitecoreField field={fields?.title} />
            </h2>
            {fields?.subTitle?.value && (
              <p className="sub-title-service">
                <TextSitecoreField field={fields?.subTitle} />
              </p>
            )}
          </div>
          {fields?.ctaText?.value && (
            <div className="cta-wrapper col-lg-4 content-end">
              <ButtonHref
                text={fields?.ctaLink?.value?.rext || fields?.ctaText?.value}
                href={fields?.ctaLink?.value?.href}
                variant="secondary-button"
                arrow={fields?.ctaArrow?.value}
                linkType={fields?.ctaLink?.value?.linktype}
                linkFields={fields?.ctaLink}
                componentName={componentName}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="content-wrapper service col">
            {fields?.services &&
              fields?.services?.map((item) => (
                <CardServices
                  key={item?.id}
                  title={item?.fields?.title}
                  text={item?.fields?.description}
                  icon={item?.fields?.image}
                  altText={item?.fields?.title}
                  ctaText={item?.fields?.linkTxt?.value}
                  href={item?.fields?.link?.value?.href}
                  cssClass={'service'}
                  isActive={activeIndex === item.id}
                  onShow={() => toggleAccordion(item)}
                  linkType={item?.fields?.link?.value?.linktype}
                  linkFields={item?.fields?.link}
                  componentName={componentName}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default CardBlockSolutions;

CardBlockSolutions.propTypes = {
  type: PropTypes.string,
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    subTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        linktype: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
    ctaBackgroundColor: PropTypes.shape({
      fields: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
    services: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string,
          }),
          description: PropTypes.shape({
            value: PropTypes.string,
          }),
          image: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
          linkTxt: PropTypes.shape({
            value: PropTypes.string,
          }),
          link: PropTypes.shape({
            value: PropTypes.shape({
              href: PropTypes.string,
              linktype: PropTypes.string,
              text: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
  rendering: PropTypes.shape({
    componentName: PropTypes.string,
  }),
};
CardBlockSolutions.defaultProps = {
  type: 'component',
  fields: {
    title: {
      value: '',
    },
    subTitle: {
      value: '',
    },
    ctaText: {
      value: '',
    },
    ctaLink: {
      value: {
        href: '',
        linktype: '',
        text: '',
      },
    },
    ctaBackgroundColor: {
      fields: {
        text: '',
      },
    },
    services: [
      {
        id: 0,
        fields: {
          title: {
            value: '',
          },
          description: {
            value: '',
          },
          image: {
            value: {
              src: '',
            },
          },
          linkTxt: {
            value: '',
          },
          link: {
            value: {
              href: '',
              linktype: '',
              text: '',
            },
          },
        },
      },
    ],
  },
  rendering: {
    componentName: '',
  },
};
