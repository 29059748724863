/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
//import { useCookies } from 'react-cookie';

import CountryLinkAccordion from './CountryLinkAccordion';
import TypeAheadDropDownAdvanced from '../../../globals/dropdown/TypeAheadDropDownAdvanced';

import './siteheaderlanguage.scss';
import { constructLanguageRedirectionURL, getBrowserWindow } from '../../../utils/HelperUtils';
import { ComponentDataAttributes } from '../../../constants/gtm';

/**
 * @description - to show countrylinks
 * @param {*} param0
 * @returns {HTML} - countrylinks
 */
const SiteHeaderLanguage = (props) => {
  const {
    linkItems,
    t,
    sitecoreContext,
    rendering: { componentName = '' },
  } = props;
  const { items, selected } = linkItems;
  const [countryList, setCountryList] = useState([]);
  const [showAccordion, setShowAccordion] = useState(false);

  const country_code = sitecoreContext?.Country?.code;

  useEffect(() => {
    prepareCountryLinks();
  }, [items]);

  /**
   * Prepares the country links to be displayed in the site header language component.
   * @function
   * @returns {void}
   */
  const prepareCountryLinks = () => {
    let countries = [];
    const itemList = [];
    Array.isArray(items) &&
      items.forEach((region) => {
        countries = [...countries, ...region?.countries];
      });

    countries.forEach((country, eindex) => {
      const { countryName = '', languages } = country;
      const text = (
        <div key={`${eindex}-${countryName}`}>
          <span>{countryName}</span>
          {languages &&
            languages?.values &&
            languages?.values?.map((language, index) => {
              const { name = '', regionalIsoCode = '' } = language;
              return (
                <div key={`${index}-${name}`}>
                  {'|'}
                  <a
                    href={constructLanguageRedirectionURL(countryName, regionalIsoCode)}
                    key={index}
                    onClick={() => {
                      getBrowserWindow &&
                        window?.open(constructLanguageRedirectionURL(countryName, regionalIsoCode));
                    }}
                    target={'_blank'}
                  >
                    {name}
                  </a>
                </div>
              );
            })}
        </div>
      );

      itemList.push({
        value: countryName,
        text: text,
      });
    });
    setCountryList(itemList);
  };

  const doShowAccordion = () => setShowAccordion(true);
  const doHideAccordion = () => setShowAccordion(false);

  return (
    <section className={`country-selector-section`}
    {...ComponentDataAttributes('LanguageSelector')} >
      <div className="country-links">
        <div key="body">
          <div className="country-search">
            <TypeAheadDropDownAdvanced
              items={countryList}
              textField="text"
              valueField="value"
              label={t('select-country-language')}
              floatingLabel={t('search-country-label')}
              seletedItem={
                Array.isArray(selected) && selected.length > 0 ? selected[0]?.country : ''
              }
              onItemSelect={() => {}}
              brandName={t('app')}
              dropdown={true}
              onClear={doShowAccordion}
              onSearch={doHideAccordion}
              noDataMessage={t('countrylinks-no-result-found')}
            />
          </div>
          <CountryLinkAccordion
            linkItems={linkItems}
            showAccordion={showAccordion}
            doShowAccordion={doShowAccordion}
            componentName={'LanguageSelector'}
          />
        </div>
      </div>
    </section>
  );
};

SiteHeaderLanguage.defaultProps = {
  sitecoreContext: {},
  rendering: {},
  linkItems: {},
  linkItemsManpower: {},
  t: () => {},
};

SiteHeaderLanguage.propTypes = {
  sitecoreContext: PropTypes.shape({}),
  rendering: PropTypes.shape({}),
  linkItems: PropTypes.shape({}),
  linkItemsManpower: PropTypes.shape({}),
  t: PropTypes.func,
};

export default withTranslation()(SiteHeaderLanguage);
