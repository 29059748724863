import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import { isEmpty } from 'lodash';

const CardBenefit = ({ cssClass, data, componentName = '' }) => {
  const { fields = {} } = data;
  const { description, image, link, linkTxt, title } = fields;
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  const visible = !isMobileScreen && isEmpty(image?.value) ? 'visible' : '';
  return (
    <div
      className={`card flex-fitcontent benefit ${cssClass}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="card-body">
        <div className={`card-img ${visible}`}>
          {image && (
            <>
              <ImageSitecoreField field={image} className="icon" />
            </>
          )}
        </div>
        <h3 className="title">
          <TextSitecoreField field={title} />
        </h3>
        <p className="description">
          <TextSitecoreField field={description} />
        </p>
        {linkTxt?.value && (
          <div className="cta__wrap justify-content-center">
            <ButtonHref
              variant="text-button"
              text={<TextSitecoreField field={linkTxt} />}
              href={link?.value?.href}
              componentName={componentName || 'SourcingBenefits'}
              dataItemName={title?.value}
              aria-label={linkTxt?.value}
              linkFields={link}
              linkType={link?.value?.linktype}
            />
          </div>
        )}
      </div>
    </div>
  );
};

CardBenefit.defaultProps = {
  data: {
    fields: {
      description: '',
      title: { value: '' },
      text: '',
      image: { value: '' },
      icon: true,
      linkTxt: { value: '' },
      link: { value: '' },
    },
  },
  cssClass: '',
  componentName: '',
};

CardBenefit.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.shape({
      title: PropTypes.shape({}),
      text: PropTypes.string,
      img: PropTypes.shape({}),
      icon: PropTypes.bool,
      ctaText: PropTypes.shape({}),
      cssClass: PropTypes.shape({}),
    }),
  }),
};

export default CardBenefit;
