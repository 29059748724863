import React from 'react';

function TextBoxContact(props) {
  const { type, name, reg, errors, Label, isRequiredField, placeholder, handleInputChange } = props;
  return (
    <div className={'form-block'}>
      {Label && <label htmlFor={Label}>{`${Label}${isRequiredField ? '*' : ''}`}</label>}

      <input
        className={`hs-input ${errors?.message === undefined ? '' : 'invalid error'}`}
        type={type}
        name={name}
        {...reg}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
      {errors && errors.type === 'required' && <p className="error-msg">{errors.message}</p>}
      {errors && errors.type === 'pattern' && <p className="error-msg">{errors.message}</p>}
    </div>
  );
}

export default TextBoxContact;
