import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Tabs from '../../core/SubNav/SubNavTabs';
import Tab from '../../core/SubNav/SubNavTabItem';
import Panel from '../SubNav/SubNavPanel';
import AccordionTab from '../Accordion/AccordionTab';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { mapBackgroundColor } from '../../../utils/HelperUtils';

const ExpandableGrid = ({ subTitle, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { title, solutions, backgroundTextColor } = fields;
  const backgroundColor = backgroundTextColor?.fields?.text?.value
    ? backgroundTextColor?.fields?.text?.value.toLowerCase()
    : 'primary';

  return (
    <section
      className={`subnav-section solutions ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row section-header">
          <div className="col-lg-12 grid-title">
            <h2>
              <TextSitecoreField field={title}></TextSitecoreField>
            </h2>
            {subTitle && <p>{<TextSitecoreField field={subTitle}></TextSitecoreField>}</p>}
          </div>
        </div>
        {isDesktop && (
          <Tabs defaultActiveTab={solutions && solutions[0] && solutions[0].name}>
            {solutions &&
              solutions.map((item) => {
                return (
                  <Tab key={item.id} name={item.displayName} icon={item.fields.tabIcon.value.src}>
                    <Panel
                      title={item?.fields?.title}
                      text={item?.fields?.Description}
                      id={item?.fields?.Link?.value?.id}
                      ctaText={item?.fields?.Link?.value?.text}
                      ctaLink={item?.fields?.Link?.value?.href}
                      linkHeading={item?.fields?.linkHeading}
                      ctaLinkType={item?.fields?.Link?.value?.linktype}
                      linkFields={item?.fields?.Link}
                      links={item?.fields?.linkCollection}
                      backgroundColor={backgroundColor}
                      cssClass=""
                    />
                  </Tab>
                );
              })}
          </Tabs>
        )}
        {isMobile && <AccordionTab items={solutions} backgroundColor={backgroundColor} />}
      </div>
    </section>
  );
};

ExpandableGrid.defaultProps = {
  fields: {
    title: '',
    solutions: [],
  },
  subTitle: '',
  backgroundColor: '',
};

ExpandableGrid.defaultProps = {
  fields: PropTypes.shape({
    solutions: PropTypes.array,
    title: PropTypes.string,
  }),
  subTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundTextColor: PropTypes.shape({
    fields: PropTypes.object,
  }),
};

export default ExpandableGrid;
