import React from 'react';
import PropTypes from 'prop-types';
import ImageSitecoreField from '../../../../src/components/sitecorefields/ImageSitecoreField';
import TextSitecoreField from '../../../../src/components/sitecorefields/TextSitecoreField';
import './backButton.scss';

const BackButton = ({ onClick, cssClass, backBtnText, backBtnIcon }) => (
  <button className={`button back-button ${cssClass}`} aria-label="Back button" onClick={onClick}>
    <div className="back-button__icon">
      <ImageSitecoreField field={backBtnIcon}></ImageSitecoreField>
    </div>
    <div className="back-button__text">
      <TextSitecoreField field={backBtnText}></TextSitecoreField>
    </div>
  </button>
);

BackButton.defaultProps = {
  cssClass: '',
  backBtnText: '',
  backBtnIcon: '',
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  cssClass: PropTypes.string,
  backBtnText: PropTypes.string,
  backBtnIcon: PropTypes.string,
};

export default BackButton;
