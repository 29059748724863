import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { enableDebug } from '@sitecore-jss/sitecore-jss-react';
import AppRoot from './AppRoot';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from './temp/config';
import i18ninit from './i18n';
import {
  getGlobalLanguageFromCookie,
  getLanguageConfig,
  getLocation,
  isSCExperienceEditorActive,
  setCountryLanguageChoiceToCookie,
  setGlobalLanguageToCookie,
} from './utils/HelperUtils';
import { getDefaultLanguage } from './constants';
function getLanguageFromURL(url) {
  const { isGlobal, language, country } = getLanguageConfig(url);
  let languageToSC = 'en';
  if (isGlobal) {
    if (language) {
      // mpg.com/es - this means the user has directly entered clicked the url with a language suffix.
      setGlobalLanguageToCookie(language);
      languageToSC = language;
    } else {
      // mpg.com - this means the user just visited the global site without any language suffix.
      // We need to detect the country and set the language accordingly.
      const globalLanguage = getGlobalLanguageFromCookie();
      if (!globalLanguage) {
        getLocation()
          .then((res) => {
            // set the country and the default langauge for the user in cookies.
            const language = getDefaultLanguage(res.code);
            setGlobalLanguageToCookie(language);
            window.location.reload();
          })
          .catch(() => {
            languageToSC = 'en';
          });
      } else {
        languageToSC = globalLanguage;
      }
    }
  } else {
    // this flow is for the country site.
    setCountryLanguageChoiceToCookie(country, language);
    languageToSC = language;
  }
  return languageToSC;
}
import { Provider } from 'react-redux';
import { configStore } from './state/store/configStore';

if (process.env.REACT_APP_DEBUG) {
  enableDebug(process.env.REACT_APP_DEBUG);
}

/* eslint-disable no-underscore-dangle */

let renderFunction = (rootElement, component) => {
  const root = createRoot(rootElement);
  root.render(component);
};

let initLanguage = config.defaultLanguage;

if (!isSCExperienceEditorActive) {
  /**
   * Parses the language from the URL path and assigns it to the languageToSC variable.
   *
   * @returns {string} The language code extracted from the URL path.
   */
  const url = window?.location?.pathname.replace('/', '');
  let languageToSC = getLanguageFromURL(url);
  initLanguage = languageToSC;
}

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document?.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
  renderFunction = hydrateRoot;

  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

/*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
// Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
// to avoid needing to re-run GraphQL queries after the SSR page loads
const initialGraphQLState =
  __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : null;

const graphQLClient = GraphQLClientFactory(config.graphQLEndpoint, false, initialGraphQLState);

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18ninit(initLanguage).then(() => {
  // HTML element to place the app into
  const rootElement = document?.getElementById('root');
  const store = configStore();
  renderFunction(
    rootElement,
    <Provider store={store}>
      <div style={{ minHeight: '500px', position: 'relative' }}>
        <AppRoot
          path={window?.location?.pathname}
          Router={BrowserRouter}
          graphQLClient={graphQLClient}
          ssrState={__JSS_STATE__}
        />
      </div>
    </Provider>
  );
});
