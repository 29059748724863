export const COOKIE_EXPIRY_PERIOD = 30;
/**
 * An object containing keys for various cookie names used in the application.
 * @typedef {Object} CookieKey
 * @property {string} LANGUAGE - The key for the language cookie.
 * @property {string} LANGUAGE_NAME - The key for the language name cookie.
 * @property {string} REGION - The key for the region cookie.
 * @property {string} REGION_ICON - The key for the region icon cookie.
 * @property {string} GLOBAL - The key for the global cookie.
 * @property {string} COUNTRY - The key for the country cookie.
 * @property {string} SC_LANG - The key for the Sitecore language cookie.
 */

/**
 * An object containing keys for various cookie names used in the application.
 * @type {CookieKey}
 */
export const CookieKey = Object.freeze({
  LANGUAGE: 'language',
  LANGUAGE_NAME: 'LanguageName',
  REGION: 'region',
  COUNTRY_ICON: 'countryIcon',
  GLOBAL: 'global',
  COUNTRY: 'country',
  SC_LANG: 'sc_lang',
});
