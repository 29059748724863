import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  getBrowserWindow,
  getLocationContactus,
  isSCExperienceEditorActive,
} from '../../utils/HelperUtils';
import Cookies from 'js-cookie';

function Select({ title, cssClass, id, labelText, options, errorMsg }) {
  let newOptions = [];
  const [currentCountry, setCurrentCountry] = useState(Cookies.get('countryCode'));

  if (!isSCExperienceEditorActive) {
    if (!Cookies.get('countryCode')) {
      getLocationContactus()
        .then()
        .catch((e) => {});
    }
  }
  const newOption =
    options &&
    options.map((option) => {
      return { displayName: option?.displayName, value: option?.fields?.languages?.value };
    });

  newOptions.push(newOption);

  const filtData =
    newOptions &&
    newOptions[0]?.map((item) => ({
      displayName: item.displayName,
      value: Object.values(item.value)[0],
    }));

  let newSavedData =
    filtData &&
    filtData.filter((item) => item?.displayName?.toLowerCase() === currentCountry?.toLowerCase());

  let existingData;

  if (newSavedData && newSavedData.length > 0 && newSavedData[0].value.includes('-')) {
    existingData = `${newSavedData[0].value.split('-')[0]}/${newSavedData[0].value.split('-')[1]}`;
  } else existingData = '';

  const [selectData, setSelectData] = useState(existingData);

  const handleChange = (e) => {
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        let revVal = e?.target?.value?.toLowerCase()?.split('/')?.reverse()?.join('/');
        setSelectData(revVal);
        let urlSuffix =
          window.location.href?.split('/')[window.location.href?.split('/').length - 1];
        location.replace(`${window.location.origin}/${revVal}/${urlSuffix}`);
        let index = e?.target?.selectedIndex;
        let optionElement = e?.target?.childNodes[index];
        let option = optionElement.getAttribute('label');
        if (Cookies.get('countryCode')) {
          Cookies.remove('countryCode');
        }
        Cookies.set('countryCode', option);
      }
    }
  };

  return (
    <div className="form-block-wrapper">
      {title && <div className={`title ${cssClass}`}>{title}</div>}
      <div className={`form-block ${selectData ? 'select-active' : ''}`}>
        <select id={id} onChange={handleChange} value={selectData ? selectData : 'en/US'}>
          {filtData &&
            filtData.map((option) => {
              if (option.value !== undefined) {
                const optVal =
                  option?.value?.split('-') !== undefined
                    ? `${option?.value?.split('-')[0]}/${option?.value?.split('-')[1]}`
                    : '';
                return (
                  optVal !== '' && (
                    <option key={option.displayName} value={optVal} label={option?.displayName}>
                      {option?.displayName}
                    </option>
                  )
                );
              }
            })}
        </select>
        {errorMsg && <div className="error-msg">{errorMsg}</div>}
      </div>
    </div>
  );
}

Select.defaultProps = {
  id: '',
  labelText: '',
  options: [],
  errorMsg: '',
};

Select.propTypes = {
  /** Field identifier  */
  id: PropTypes.string,

  /** Label text */
  labelText: PropTypes.string,

  /** Title for input field */
  title: PropTypes.string,

  /** Options to display in the drop down */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,
};

export default Select;
