import DataBlock from '../../core/DataBlock/DataBlock';

export default DataBlock;

// import React from 'react';
// import DataBlockGrid from '../../core/DataBlockGrid/DataBlockGrid';

// const DataBlock = (props) => {
//   const { fields = {} } = props;
//   const { chart } = fields;
//   const ChartArray = chart;
//   const primaryData = ChartArray ? ChartArray[0] : [];
//   const primaryDataLabel = primaryData?.fields?.dataSet?.map((item) => {
//     return item?.fields?.name?.value;
//   });
//   const primaryDataValue = primaryData?.fields?.dataSet?.map((item) => {
//     return item?.fields?.value?.value;
//   });
//   const primaryDataStructure = {
//     title: primaryData?.fields?.title?.value,
//     descLabel: primaryData?.fields?.dateLabel?.value,
//     description: primaryData?.fields?.dateValue?.value,
//     //   chartType: primaryData?.fields?.type?.value,
//     chartType: primaryData?.fields?.type?.value,

//     data: {
//       labels: primaryDataLabel,
//       datasets: [
//         {
//           label: primaryData?.fields?.dateLabel?.value,
//           data: primaryDataValue,
//           borderRadius: 100,
//           barPercentage: 1.0,
//           categoryPercentage: 0.8,
//         },
//       ],
//       yValues: {
//         min: -4,
//         max: 9,
//         stepSize: 1,
//       },
//     },
//   };

//   const secondaryData = ChartArray ? ChartArray[1] : [];
//   const secondaryDataLabel = secondaryData?.fields?.dataSet?.map((item) => {
//     return item.fields.name.value;
//   });
//   const secondaryDataValue = secondaryData?.fields?.dataSet?.map((item) => {
//     return item?.fields?.value?.value;
//   });
//   const secondaryDataStructure = {
//     title:
//       secondaryData?.fields?.countryName?.value +
//       ' ' +
//       secondaryData?.fields?.workforceSupplyLabel?.value,
//     descLabel: secondaryData?.fields?.totalWorkforceLabel?.value,
//     description: secondaryData?.fields?.workforceSupplyValue?.value,
//     // chartType: secondaryData?.fields?.type?.value,
//     chartType: secondaryData?.fields?.type?.value,

//     data: {
//       labels: secondaryDataLabel,
//       datasets: [
//         {
//           label: 'Net Secondary Education',
//           fill: 'start',
//           data: secondaryDataValue,
//           borderWidth: 0,
//           borderColor: 'transparent',
//         },
//       ],
//     },
//   };
//   const tertiaryData = ChartArray ? ChartArray[2] : [];
//   const tertiaryDataLabel = [];
//   tertiaryDataLabel.push(
//     tertiaryData?.fields?.regulationLabel?.value,
//     tertiaryData?.fields?.maturityLabel?.value
//   );

//   const regulationLevels = tertiaryData?.fields?.regulationLevels[0]?.fields?.name?.value;
//   const regulationLevelsValue = tertiaryData?.fields?.regulationLevels[0]?.fields?.value?.value;

//   const maturityLevels = tertiaryData?.fields?.maturityLevels[0]?.fields?.name?.value;
//   const maturityLevelsValue = tertiaryData?.fields?.maturityLevels[0]?.fields?.value?.value;

//   const tertiaryDataStructure = {
//     color: 'blue-violet',
//     chartType: tertiaryData?.fields?.type?.value,
//     data: {
//       labels: tertiaryDataLabel,
//       datasets: [
//         {
//           label: regulationLevels,
//           data: regulationLevelsValue,
//         },
//         {
//           label: maturityLevels,
//           data: maturityLevelsValue,
//         },
//       ],
//       xValues: {
//         min: 0,
//         max: 100,
//       },
//     },
//   };
//   const statisticData = ChartArray ? ChartArray[3] : [];
//   const statisticDataStructure = {
//     type: statisticData?.fields?.Type?.value,
//     color: statisticData?.fields?.color?.value,
//     data: {
//       preText: statisticData?.fields?.preText?.value,
//       statisticValue: statisticData?.fields?.statisticValue?.value,
//       postText: statisticData?.fields?.postText?.value,
//     },
//     secondaryData: {},
// };
//   const DataBlockChartData = {
//     title: props?.fields?.title?.value,
//     description: props?.fields?.description?.value,
//     ctaText: props?.fields?.ctaText?.value,
//     ctaLink: props?.fields?.ctaLink?.value?.href,
//     primaryData: primaryDataStructure,
//     secondaryData: secondaryDataStructure,
//     tertiaryData: tertiaryDataStructure,
//     statisticData: statisticDataStructure,
//   };

//   return (
//     <>
//       <DataBlockGrid props={DataBlockChartData} />
//     </>
//   );
// };

// export default DataBlock;

// DataBlock.defaultProps = {
//   fields: {
//     chart: [],
//   },
//   chart: [],
// };
