import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import ActionBarSocialFollow from './../ActionBars/ActionBarSocialFollow_contact';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const CalloutBarContactDetails = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    Email,
    followUsTitle,
    Phone,
    ctaArrow,
    ctaStyle,
    ctaLink,
    ctaText,
    followUs,
    paragraphText,
    Content,
    title,
  } = fields;

  const secondaryText = paragraphText || Content;
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });
  const countryData = useSelector((state) => state.filterReducer?.countryData);
  return (
    !isEmpty(countryData) &&
    countryData?.countryClick === true && (
      <section
        className={`calloutbar-section contact ${
          !isEmpty(countryData) && countryData?.countryClick === true ? 'countryContact' : ''
        } `}
        {...ComponentDataAttributes(componentName)}
      >
        <div className="calloutbar-body container-xl">
          <div className="row content-wrapper">
            <div className="col-xl-8 col-lg-7">
              <div className="divider">
                {isMobile ? (
                  <h3 className="h2 title__contact">{<TextSitecoreField field={title} />}</h3>
                ) : (
                  <h2 className="h2 title__contact">{<TextSitecoreField field={title} />}</h2>
                )}

                {/* <h2 className="h2 title__contact">{<TextSitecoreField field={title} />}</h2> */}

                {secondaryText && (
                  <div className="spacer">{<RichTextSitecoreField field={secondaryText} />}</div>
                )}
                {ctaLink?.value?.href && ctaText?.value && (
                  <ButtonHref
                    text={<TextSitecoreField field={ctaText} /> || ctaText?.value}
                    arrow={ctaArrow?.value}
                    variant="primary-button"
                    href={ctaLink?.value?.href}
                    linkType={ctaLink?.value?.linktype}
                    componentName={componentName}
                    linkFields={ctaLink}
                  />
                )}
              </div>
            </div>
            <div className="col">
              <div className="contact-details">
                {Phone && (
                  <div className="phone_contact">
                    <div>{<RichTextSitecoreField field={Phone} />}</div>
                  </div>
                )}
                {Email && (
                  <div className="email_contact">
                    <div>{<RichTextSitecoreField field={Email} />}</div>
                  </div>
                )}
              </div>
              {followUs && (
                <div className="social-wrapper">
                  <h3 className="social-title-contact">
                    {<TextSitecoreField field={followUsTitle} />}
                  </h3>
                  <ActionBarSocialFollow items={followUs} componentName={componentName} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  );
};

CalloutBarContactDetails.defaultProps = {
  componentName: '',
  fields: {},
};

CalloutBarContactDetails.propTypes = {
  componentName: PropTypes.string,
  fields: PropTypes.shape({}),
};

export default CalloutBarContactDetails;
