import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CardServiceGroup from '../Cards/CardServiceGroup';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const CardBlockServiceGroups = (props) => {
  const {
    fields = {},
    backgroundColor,
    rendering: { componentName = '' },
  } = props;
  const { services, subTitle, title, ctaText, ctaArrow, ctaLink } = fields;

  const [activeIndex, setActiveIndex] = useState(-1);
  const toggleAccordion = (item) => {
    setActiveIndex((prevIndex) => (prevIndex === item.id ? -1 : item.id));
  };

  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });

  const cardCss = isMobileScreen ? 'content-wrapper-servicegroup' : '';

  return (
    <section
      className={`card-block services services__${services && services.length} ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="section-header col-lg-8">
            <div className="title">
              {title?.value && (
                <h1 className="title-service mb-0">
                  <TextSitecoreField field={title} />
                </h1>
              )}
              {subTitle?.value && (
                <p className="sub-title-service">
                  <TextSitecoreField field={subTitle} />
                </p>
              )}
            </div>
          </div>

          {ctaText?.value && (
            <div className="cta-wrapper col-lg-4">
              <ButtonHref
                text={<TextSitecoreField field={ctaText} /> || ctaText?.value}
                href={ctaLink && ctaLink?.value?.href}
                variant="secondary-button"
                arrow={ctaArrow ? ctaArrow?.value : false}
                linkType={ctaLink && ctaLink?.value?.linktype}
                linkFields={ctaLink && ctaLink}
                componentName={componentName}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className={`content-wrapper ${cardCss} col`}>
            {services &&
              services.map((item, index) => (
                <CardServiceGroup
                  key={item?.id}
                  title={item?.fields?.title}
                  subTitle={item?.fields?.subTitle}
                  text={item?.fields?.description}
                  icon={item?.fields?.image?.value}
                  ctaText={item?.fields?.linkTxt?.value}
                  altText={item?.fields?.image?.value?.alt}
                  isActive={activeIndex === item.id}
                  onShow={() => toggleAccordion(item)}
                  link={item?.fields?.link?.value?.href}
                  linkType={item?.fields?.link?.value?.linktype}
                  linkFields={item?.fields?.link}
                  componentName={componentName}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

CardBlockServiceGroups.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    services: [],
    ctaText: { value: '' },
  },
  backgroundColor: 'on-primary',
};

CardBlockServiceGroups.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string,
      altText: PropTypes.string,
      cssClass: PropTypes.string,
    })
  ),
  fields: PropTypes.shape({}),
};

export default CardBlockServiceGroups;
