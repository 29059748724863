import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbClass from '../Breadcrumb/Breadcrumb';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import Select from '../../../globals/forms/Select';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const BannerContactForm = ({ variant = 'solutions', ...props }) => {
  const {
    fields,
    Breadcrumb,
    rendering: { componentName },
  } = props;

  const {
    Content,
    backgroundColor,
    backgroundTextColor,
    backgroundImage,
    backgroundImageMobile,
    countrySelection,
    frostedGlass,
    frostedGlassColor,
    textColor,
    title,
  } = fields;
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  const breadcrumbItems =
    Breadcrumb && Array.isArray(Breadcrumb) && Breadcrumb[0]?.props?.fields?.data?.item;

  return (
    <section
      className={`banner-section contact-banner standard banner banner__${variant} ${
        frostedGlass.value === true && !isEmpty(frostedGlassColor?.fields?.text?.value)
          ? 'glass'
          : ''
      }`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageMedium={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageLarge={backgroundImage}
        imageXLarge={backgroundImage}
      />
      <div className="container-xl">
        <div className="row content-wrapper">
          <div
            className={`col-xl-8 col-lg-7 col-md-7 contact-banner-form ${
              isMobileScreen
                ? ''
                : frostedGlass?.value === true &&
                  !isEmpty(frostedGlassColor?.fields?.text?.value) &&
                  (frostedGlassColor?.fields?.text?.value === 'light'
                    ? 'frost frost__light'
                    : 'frost frost__dark')
            }`}
          >
            {breadcrumbItems && (
              <BreadcrumbClass
                items={breadcrumbItems}
                cssClass={`${
                  textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
                componentName={componentName}
                // textColor={`${
                //   textColor?.fields?.text?.value === 'white' ? 'secondary' : 'primary'
                // }`}
              />
            )}
            <h1
              className={`title ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              }`}
            >
              <TextSitecoreField field={title} />
            </h1>
            <p
              className={`sub-title ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              }`}
            >
              <TextSitecoreField field={Content} />
            </p>
            <div className="form__wrap">
              <Select
                labelText="Label"
                options={countrySelection}
                cssClass={`${
                  backgroundColor?.fields?.text?.value === 'primary' ? 'on-primary' : 'on-secondary'
                }`}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const BannerContactFormContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'Breadcrumb' }])(BannerContactForm)
);

BannerContactForm.defaultProps = {
  fields: {
    countrySelection: [
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          redirectUrl: {
            value: {
              href: '',
            },
          },
          languages: {
            value: '',
          },
        },
      },
    ],
    backgroundImageMobile: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    frostedGlass: {
      value: 'dark',
    },
    title: {
      value: '',
    },
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    Content: {
      value: '',
    },
    backgroundColor: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: '',
        },
      },
    },
  },
  Breadcrumb: [],
};

BannerContactForm.propTypes = {
  Breadcrumb: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })
  ),

  fields: PropTypes.shape({
    countrySelection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          redirectUrl: PropTypes.shape({ value: PropTypes.shape({ href: PropTypes.string }) }),
          languages: PropTypes.shape({ value: PropTypes.string }),
        }),
      })
    ),
    backgroundImageMobile: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
    frostedGlass: PropTypes.shape({ value: PropTypes.string }),
    title: PropTypes.shape({ value: PropTypes.string }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
    Content: PropTypes.shape({ value: PropTypes.string }),
    backgroundColor: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      name: PropTypes.string,
      displayName: PropTypes.string,
      fields: PropTypes.shape({ text: PropTypes.shape({ value: PropTypes.string }) }),
    }),
  }),
};

export default BannerContactFormContainer;
