import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SubNavBarLink from './TWSubNavBarLink';
import './TWsubNavBar.scss';
import ButtonHref from '../../../../globals/buttons/ButtonHref';
import { useMediaQuery } from 'react-responsive';

const SubNavBar = (props) => {
  const { fields } = props;
  const { DesktopNavList, MobileNavList, ctaText, ctaLink } = fields;
  const { pathname } = useLocation();

  const [activeIndex, setActiveIndex] = useState(-1);
  const [toggle, toggleMenu] = useState(false);

  // const mobileTrigger = MobileNavList && MobileNavList?.map((item) => item?.fields?.Link?.value?.text)[0];
  const activMmobileTrigger =
    MobileNavList &&
    MobileNavList?.filter((item) => {
      return item?.fields?.Link?.value?.href === pathname;
    });
  const mobileTrigger = activMmobileTrigger && activMmobileTrigger[0]?.fields?.Link?.value?.text;
  // const MobileNavListVal = MobileNavList && MobileNavList?.slice(1, MobileNavList?.length + 1);
  const MobileNavListVal =
    MobileNavList &&
    MobileNavList &&
    MobileNavList?.filter((item) => {
      return item?.fields?.Link?.value?.href !== pathname;
    });

  let desktopWidth = useMediaQuery({ maxWidth: 783 });

  let navVal = desktopWidth ? MobileNavListVal : DesktopNavList;

  return (
    <section className={'navbar-section violet'}>
      <div className="container-div">
        <button
          className={`mobile-trigger ${toggle ? 'open' : 'close'}`}
          onClick={() => toggleMenu(!toggle)}
        >
          {mobileTrigger}
        </button>
        <div className={`row navbar-wrapper ${toggle ? 'open' : 'close'}`}>
          <div className="navbar-links">
            {navVal &&
              navVal.map((item) => (
                <SubNavBarLink
                  key={item.id}
                  ctaText={item?.fields?.Link?.value?.text}
                  isActive={item?.fields?.Link?.value?.href === pathname}
                  onShow={() => {
                    setActiveIndex(item.id);
                    window.href = item?.fields?.Link?.value?.href;
                  }}
                  linkItem={item?.fields?.Link}
                />
              ))}
          </div>
          {ctaText?.value && (
            <div className="navbar-cta">
              <ButtonHref
                text={ctaText?.value}
                href={ctaLink?.value?.href}
                variant="primary-button"
                cssClass="white"
                fontSize="small"
                arrow={fields?.ctaArrow?.value}
                linkType={ctaLink?.value?.linktype}
                linkFields={ctaLink}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

SubNavBar.defaultProps = {
  DesktopNavList: [],
  mobileTrigger: '',
  ctaText: {},
};

SubNavBar.propTypes = {
  DesktopNavList: PropTypes.array,
  mobileTrigger: PropTypes.string,
  ctaText: PropTypes.object,
};

export default SubNavBar;
