import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './navigation.scss';

import { useMediaQuery } from 'react-responsive';
import { breakpoints } from './../../../../scripts/breakpoints';

import SolutionsComponent from './SubNavigation/Solutions/Solutions';
import NavLink from '../../../../globals/navLink';
import { checkHref, isBrowserDocumentAvailable } from '../../../../utils/HelperUtils';
export default function Navigation({ menuItems = [], ...props }) {
  const { onSubnavOpen, onSubnavClose, mobileMenuScrollTop, handleMobileMenu } = props;
  const { onSubSubnavOpen } = props;
  const [showMenu, setShowMenu] = useState();
  const [subNavOpen, setSubNavOpenLocal] = useState(false);
  const [subSubNavOpen, setSubSubNavOpenLocal] = useState(false);

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });

  const toggleSubNavOpen = (menuItemIndex) => {
    if (activeMenuItem === menuItemIndex) {
      setSubNavOpenLocal(false);
      setActiveMenuItem(null);
      onSubnavClose(true);
    } else {
      setSubNavOpenLocal(true);
      setActiveMenuItem(menuItemIndex);
      onSubnavOpen(true);
    }
  };
  const navigationRefs = useRef(menuItems?.map(() => React.createRef()));
  const subNavigationRefs = useRef(menuItems?.map(() => React.createRef()));

  useEffect(() => {
    if (isBrowserDocumentAvailable) {
      const handleDocumentClick = (event) => {
        // Check if the click event occurred outside of a navigation item or its submenu
        const clickedInsideNavigation = navigationRefs?.current?.some(
          (navigationRef) =>
            navigationRef?.current && navigationRef?.current?.contains(event.target)
        );
        const clickedInsideSubNavigation = subNavigationRefs?.current?.some(
          (subNavigationRef) =>
            subNavigationRef?.current && subNavigationRef?.current?.contains(event.target)
        );
        if (!isMobile) {
          if (!clickedInsideNavigation && !clickedInsideSubNavigation) {
            setActiveMenuItem(null);
            onSubnavClose(true);
          }
        }
      };
      document?.addEventListener('click', handleDocumentClick);
      return () => document?.removeEventListener('click', handleDocumentClick);
    }
  }, []);

  const clickedComponent = (item) => {
    return (
      <SolutionsComponent
        data={item}
        mobileMenuScrollTop={mobileMenuScrollTop}
        onSubSubnavOpen={onSubSubnavOpen}
        handleMobileMenu={handleMobileMenu}
      />
    );
  };

  return isMobile ? (
    <nav className={'mobileNavigation white__background'}>
      <span className="main_menu_scrollto"></span>
      <ul className="mobileNavigation__menu">
        {menuItems &&
          menuItems?.map((item, index) => {
            return (
              <React.Fragment key={`key_${item.title}_${index}`}>
                <li
                  ref={navigationRefs.current[index]}
                  key={index}
                  className={`mobileNavigation__item ${
                    item?.fields?.linkCollection?.length > 0 ? 'has_subnav' : ''
                  } ${activeMenuItem === index ? 'active' : ''}`}
                >
                  {item?.fields?.linkCollection.length > 0 ? (
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        toggleSubNavOpen(index);
                        mobileMenuScrollTop();
                      }}
                    >
                      {item.displayName !== '' ? item?.displayName : item?.name}
                    </a>
                  ) : (
                    <a>
                      <NavLink
                        url={item?.fields?.Link}
                        componentName="CountrySiteHeader"
                        itemLevel="L0"
                        dataNavItemName={item?.name}
                        dataLinkText={checkHref(item?.fields?.Link)}
                        dataLinkImageUrl=""
                      >
                        {item.displayName !== '' ? item?.displayName : item?.name}
                      </NavLink>
                    </a>
                  )}
                </li>
                {subNavOpen && activeMenuItem === index && item && (
                  <div
                    ref={subNavigationRefs.current[index]}
                    className={`mobileNavigation__submenu-wrapper 
                      ${subNavOpen ? 'open' : ''} 
                      ${subSubNavOpen ? 'sub_open' : ''}
                    `}
                  >
                    <ul className="mobileNavigation__submenu">{clickedComponent(item)}</ul>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </ul>
    </nav>
  ) : (
    <nav className={'CountryheaderNavigation white__background'}>
      <ul className="CountryheaderNavigation__menu">
        {menuItems &&
          menuItems?.map((item, index) => (
            <React.Fragment key={`key_${item.name}_${index}`}>
              <li
                ref={navigationRefs.current[index]}
                key={index}
                className={'CountryheaderNavigation__item'}
              >
                {/* <button type="button" aria-haspopup="menu" onMouseEnter={() => {setShowMenu(true)}} onMouseLeave={() => {setShowMenu(false)} }>
                {item?.name}
                </button> */}
                <span
                  onMouseEnter={() => {
                    // setShowMenu();
                    setShowMenu(index);
                  }}
                  onClick={() => setShowMenu(index)}
                >
                  <NavLink
                    url={item?.fields?.Link}
                    componentName="CountrySiteHeader"
                    itemLevel="L0"
                    dataNavItemName={item?.name}
                    dataLinkText={item?.fields?.Link}
                    dataLinkImageUrl=""
                  >
                    {item.displayName !== '' ? item?.displayName : item.name}
                  </NavLink>
                </span>
                {item?.fields?.linkCollection && item?.fields?.linkCollection?.length > 0 && (
                  <ul
                    onMouseLeave={() => {
                      setShowMenu();
                    }}
                    className={`dropdown ${index === showMenu ? 'show' : ''}`}
                  >
                    {item?.fields?.linkCollection &&
                      item?.fields?.linkCollection?.map((items, index) => (
                        <li key={index} className="menu-items">
                          <NavLink
                            url={items?.fields?.Link}
                            componentName="CountrySiteHeader"
                            itemLevel="L1"
                            dataNavItemName={items?.name}
                            dataLinkText={checkHref(items?.fields?.Link)}
                            dataLinkImageUrl=""
                          >
                            {items.displayName !== '' ? items?.displayName : items?.name}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                )}
              </li>

              {navigationRefs.current[index].current &&
                subNavOpen &&
                activeMenuItem === index &&
                item.name && (
                  <div
                    ref={subNavigationRefs.current[index]}
                    className={`CountryheaderNavigation__submenu-wrapper ${
                      subNavOpen ? 'open' : ''
                    }`}
                  >
                    <ul className="CountryheaderNavigation__submenu">{clickedComponent(item)}</ul>
                  </div>
                )}
            </React.Fragment>
          ))}
      </ul>
    </nav>
  );
}

Navigation.propTypes = {
  mobMenuShow: PropTypes.bool,
  scrolled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      subNav: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    })
  ),
  onSubnavOpen: PropTypes.func,
  onSubnavClose: PropTypes.func,
};
