import IconTopics from '../static/icons/icon--decision-tree.svg';
import IconSolution from '../static/icons/icon--print.svg';
import IconDevelop from '../components/core/SiteHeader/Navigation/SubNavigation/Solutions/images/icon--develop--purple.svg';
import IconAttract from '../components/core/SiteHeader/Navigation/SubNavigation/Solutions/images/icon--attract--purple.svg';
import IconManage from '../components/core/SiteHeader/Navigation/SubNavigation/Solutions/images/icon--manage--purple.svg';
import IconTransform from '../components/core/SiteHeader/Navigation/SubNavigation/Solutions/images/icon--transform--purple.svg';

export const enumReducerType = {
  SET_FILTER_SELECTED_DATA: 'SET_FILTER_SELECTED_DATA',
  REMOVE_FILTER_SELECTED_DATA: 'REMOVE_FILTER_SELECTED_DATA',
  SET_SEARCHED_LARGE_LIST_DATA: 'SET_SEARCHED_LARGE_LIST_DATA',
  SET_COUNTRY_DATA: 'SET_COUNTRY_DATA',
  SET_FILTER_PAYLAOD_INSIGHT: 'SET_FILTER_PAYLAOD_INSIGHT',

  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  SET_SEARCH_FILTER_SELECTED_DATA: 'SET_SEARCH_FILTER_SELECTED_DATA',
  REMOVE_SEARCH_FILTER_SELECTED_DATA: 'REMOVE_SEARCH_FILTER_SELECTED_DATA',
  SET_SEARCHRESULT_SEARCHED_LIST_DATA: 'SET_SEARCHRESULT_SEARCHED_LIST_DATA',
  SET_SEARCH_FILTER_PAYLAOD_INSIGHT: 'SET_SEARCH_FILTER_PAYLAOD_INSIGHT',

  SET_FILTER_OPTION_WITH_DUPLICATE_DATA: 'SET_FILTER_OPTION_WITH_DUPLICATE_DATA',
  SET_CONTENT_TYPE_LIST: 'SET_CONTENT_TYPE_LIST',
  SET_CAROUSEL_SHOW_TAG_LIST: 'SET_CAROUSEL_SHOW_TAG_LIST',
};

export const getIcon = (iconName) => {
  switch (iconName) {
    case 'manage-optimize':
      return IconManage;
    case 'solution':
      return IconSolution;
    case 'develop':
      return IconDevelop;
    case 'attract&retain':
      return IconAttract;
    case 'transform':
      return IconTransform;
    case 'topics':
      return IconTopics;
    default:
      return IconSolution;
  }
};
