import React from 'react';
import PropTypes from 'prop-types';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import TextSitecoreField from './../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from './../../sitecorefields/ImageSitecoreField';
import RichTextSitecoreField from './../../sitecorefields/RichTextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import VideoModal from '../Modal/VideoModal';
import { isEmpty } from 'lodash';
import ReactPlayer from 'react-player';

const FeaturetteStandard = ({ cssClass, backgroundColor, ...props }) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const {
    featuretteVariant,
    title,
    paragraphText,
    subTitle,
    backgroundImage,
    align,
    width,
    ctaLink,
    ctaArrow,
    ctaText,
    mediaSource,
  } = fields;

  let widthToLower = width?.fields?.text?.value.toLowerCase()
    ? width?.fields?.text?.value.toLowerCase()
    : 'medium';
  let alignValuesToLowerCase = align?.fields?.text?.value.toLowerCase()
    ? align?.fields?.text?.value.toLowerCase()
    : 'right';
  let imgWidth, textWidth;
  let options = `${widthToLower}&${alignValuesToLowerCase}`;
  switch (options) {
    case 'large&right':
      textWidth = 'col-lg-4   offset-lg-1';
      imgWidth = 'col-lg-7';
      break;
    case 'large&left':
      imgWidth = 'col-lg-4  offset-lg-1';
      textWidth = 'col-lg-7';
      break;
    case 'medium&left':
      imgWidth = 'col-lg-3  offset-lg-1';
      textWidth = 'col-lg-8';
      break;
    case 'medium&right':
      imgWidth = 'col-lg-3';
      textWidth = 'col-lg-8   offset-lg-1';
      break;
    default:
      imgWidth = 'col-lg-3';
      textWidth = 'col-lg-8 offset-lg-1';
  }

  return (
    <section
      className={`featurette-section standard ${widthToLower} ${align?.fields?.text?.value} ${cssClass} ${backgroundColor}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div>
        <div className={`row content-wrapper ${align?.fields?.text?.value}`}>
          <div className={`${imgWidth}`}>
            <div className="featurette-img">
              {isEmpty(mediaSource?.value?.href) ? (
                <ImageSitecoreField field={backgroundImage} />
              ) : (
                // <VideoModal
                //   videoPreviewImg={backgroundImage?.value?.src}
                //   videoUrl={mediaSource?.value?.href}
                // />
                <ReactPlayer
                  className="react-player"
                  url={mediaSource?.value?.href}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          </div>
          <div className={`${textWidth}`}>
            <div className={`featurette-body-standard  ${align?.fields?.text?.value}`}>
              {subTitle && (
                <div className="sub-title">{<TextSitecoreField field={subTitle} />}</div>
              )}
              <h3 className="title">{<TextSitecoreField field={title} />}</h3>
              <div className="richtext-wrapper">
                {<RichTextSitecoreField field={paragraphText} />}
              </div>
              {featuretteVariant &&
                featuretteVariant?.fields?.text?.value !== 'value-card' &&
                ctaText?.value && (
                  <div className="cta-wrapper-button">
                    <ButtonHref
                      href={ctaLink?.value?.href}
                      linkType={ctaLink?.value?.linktype}
                      text={<TextSitecoreField field={ctaText} />}
                      cssClass=""
                      variant="text-button"
                      arrow={ctaArrow.value}
                      componentName={componentName}
                      dataItemName={title?.value}
                      linkFields={ctaLink}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturetteStandard.defaultProps = {
  fields: {
    title: {
      value: '',
    },
    paragraphText: {
      value: '',
    },
    subTitle: {
      value: '',
    },
    backgroundImage: {
      value: {
        src: '',
        alt: '',
        width: '',
        height: '',
      },
    },
    align: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'right',
        },
      },
    },
    width: {},
    ctaLink: {
      value: {
        href: '',
      },
    },
    ctaIcon: {
      value: {},
    },
    ctaIconPosition: null,
    ctaArrow: {
      value: false,
    },
    ctaText: {
      value: '',
    },
    ctaStyle: null,
  },
  cssClass: '',
  backgroundColor: '',
};

FeaturetteStandard.propTypes = {
  fields: PropTypes.shape(
    { title: PropTypes.shape({ value: PropTypes.string }) },
    { paragraphText: PropTypes.shape({ value: PropTypes.string }) },
    { subTitle: PropTypes.shape({ value: PropTypes.string }) },
    {
      backgroundImage: PropTypes.shape({
        value: PropTypes.shape(
          { src: PropTypes.string },
          { alt: PropTypes.string },
          { width: PropTypes.string },
          { height: PropTypes.string }
        ),
      }),
    },
    {
      align: PropTypes.shape(
        { id: PropTypes.string },
        { url: PropTypes.string },
        { name: PropTypes.string },
        { displayName: PropTypes.string },
        {
          fields: PropTypes.shape({
            text: PropTypes.shape({ value: PropTypes.string }),
          }),
        }
      ),
    },
    { width: PropTypes.shape({}) },
    { ctaLink: PropTypes.shape({ value: PropTypes.shape({ href: PropTypes.string }) }) },
    { ctaIcon: PropTypes.shape({ value: PropTypes.shape({}) }) },
    { ctaIconPosition: PropTypes.string },
    { ctaArrow: PropTypes.shape({ value: PropTypes.bool }) },
    { ctaStyle: PropTypes.shape({ value: PropTypes.string }) }
  ),
  cssClass: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default FeaturetteStandard;
