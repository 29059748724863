import React from 'react';
import PropTypes from 'prop-types';
import CardOffering from '../../core/Cards/CardOffering';
import Button from '../../../globals/buttons/Button';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import CardOfferingsSolution from '../Cards/CardOfferingsSolution';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { ComponentDataAttributes } from '../../../constants/gtm';
import ButtonHref from '../../../globals/buttons/ButtonHref';

const CardBlockOfferings = (props) => {
  const {
    fields = {},
    t,
    rendering: { componentName = '' },
  } = props;
  const { title, subTitle, ctaText, ctaLink, serviceOffering, solutionOffering, tagsToShow } =
    fields;
  const deliveredByText = t('delivered-by');
  const relatedServicesText = t('related-services');
  const enquirenow = t('enquire-now');

  return (
    <section className="card-block offerings" {...ComponentDataAttributes(componentName)}>
      <div className="container-xl">
        <div className="row header-wrapper">
          <div className="col section-header card-offerings">
            {title && (
              <h2 className="title">
                <TextSitecoreField field={title} />
              </h2>
            )}
            {subTitle && (
              <p className="sub-title">
                <TextSitecoreField field={subTitle} />
              </p>
            )}
          </div>
          {ctaText && (
            <div className="cta-wrapper-offering col-lg-4">
              <ButtonHref
                text={ctaText?.value}
                href={ctaLink?.value?.href}
                variant="secondary-button"
                arrow
                componentName={componentName}
              />
            </div>
          )}
        </div>
        <div className="row card-wrapper">
          <div
            className={`col columns columns__${serviceOffering?.length} cards cards__${serviceOffering?.length}`}
          >
            {serviceOffering &&
              serviceOffering?.map((item) => (
                <CardOffering
                  key={item?.id}
                  title={item?.fields?.Title}
                  text={item?.fields?.Content}
                  tags={tagsToShow}
                  fields={item?.fields}
                  deliveredByText={deliveredByText}
                  relatedServicesText={relatedServicesText}
                  enquirenow={enquirenow}
                  componentName={componentName}
                  ctaUrl={item?.url}
                />
              ))}
            {solutionOffering &&
              solutionOffering?.map((item) => (
                <CardOfferingsSolution
                  key={item?.id}
                  href={item?.fields?.ctaLink?.value?.href}
                  title={item?.fields?.title}
                  ctaText={item?.fields?.ctaText?.value}
                  backgroundColor={(item?.fields?.backgroundColor?.fields?.text?.value).toLowerCase()}
                  componentName={componentName}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

CardBlockOfferings.defaultProps = {
  title: '',
  text: '',
  subTitle: '',
  ctaText: '',
  items: [],
  fields: {},
};

CardBlockOfferings.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  subTitle: PropTypes.string,
  ctaText: PropTypes.string,
  items: PropTypes.array,
  fields: PropTypes.shape({}),
};

export default withTranslation()(withSitecoreContext()(CardBlockOfferings));
