import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isSCExperienceEditorActive, getBrowserWindow } from '../../../utils/HelperUtils';
import './tabs.scss';
import { OtherNavDataAttributes } from '../../../constants/gtm';

const Tabs = (props) => {
  const { defaultActiveTab, children } = props;
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);

    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'Expgrid_show',
          item_name: tabName,
          item_position: children.index,
        });
      }
    }
  };

  const tabs = [];
  const contents = [];
  // memoize the below function
  useMemo(() => {
    React.Children.forEach(children, (child) => {
      const { name, icon } = child.props;
      tabs.push(
        <li key={name} className={`nav-item ${activeTab === name ? 'active' : ''}`}>
          <a
            className={`nav-link ${activeTab === name ? 'active' : ''}`}
            key={name}
            onClick={() => handleTabClick(name)}
            {...OtherNavDataAttributes({
              name: 'ExpendableGrid',
              itemLevel: 'L0',
              dataItemName: name,
              text: name,
              url: '',
              dataNavItemName: name,
              dataLinkImageUrl: '',
            })}
          >
            <img src={icon} alt={name} className="icon" />
            {name}
          </a>
        </li>
      );

      contents.push(
        <div className={`tab-pane ${activeTab === name ? 'active' : ''}`}>
          {child.props.children}
        </div>
      );
    });
  }, [activeTab, children]);

  return (
    <nav className="tabs">
      <ul className="nav nav-tabs">{tabs}</ul>
      <div className="tab-content">{contents}</div>
    </nav>
  );
};

Tabs.defaultProps = {
  defaultActiveTab: '',
  children: [],
};

Tabs.propTypes = {
  defaultActiveTab: PropTypes.string,
  children: PropTypes.array,
};

export default Tabs;
