import React from 'react';
import PropTypes from 'prop-types';

const RankDataBlock = ({ Data }) => {
  // Directly destructure `value` and `description` from `Data`
  const { value, description } = Data;

  return (
    <>
      Global Ranking: {value} {description}
    </>
  );
};

RankDataBlock.defaultProps = {
  value: '0',
  description: '',
};

RankDataBlock.propTypes = {
  Data: PropTypes.shape({
    value: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default RankDataBlock;
