import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  sitecoreHostUrl,
  isBrowserDocumentAvailable,
  getBrowserWindow,
  isSCExperienceEditorActive,
  getLanguageConfig,
} from '../../../utils/HelperUtils';
import { filterReducer } from '../../../state/reducer/filterReducer';
import SearchResultCard from '../SearchResultCard/SearchResultCard';
import './searchBarCardBlockCardListing.scss';
import IconSearch from '../../../../src/static/icons/icon--search.svg';
import { fetchSearchResult } from '../../../service/index';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { enumReducerType } from '../../../state/common';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { withTranslation } from 'react-i18next';
const SearchBarCardBlockCardListing = ({
  t,
  placeholder = t('search-text-plateholder'),
  getQuery,
  setSearchToggle,
  onSearch = () => {},
}) => {
  const searchButtonRef = useRef(null);
  const suggestionRefs = useRef([]);
  const inputRef = useRef(null);

  const [query, setQuery] = useState(getQuery ? getQuery : '');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);
  const [suggestions, setsuggestions] = useState([]);
  const [IsSearchSuggestions, setIsSearchSuggestions] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = getBrowserWindow ? new URLSearchParams(location.search) : '';
  const selectedFilterpayloadinsight = useSelector(
    (state) => state.filterReducer.filterpayloadinsight
  );
  // when someone types in the search field, update the query
  const handleQueryChange = (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    setQuery(value);
    setIsTyping(true);
    // Only show suggestions when there are 3 or more characters
    setShowSuggestions(value.length >= 3);
    if (value.length >= 3) {
      setIsSearchSuggestions(true);
    } else {
      setIsSearchSuggestions(false);
    }
  };

  const handleQueryBlur = () => {
    setIsTyping(false);
  };
  // when someone clicks on a suggestion
  const handleSuggestionClick = (suggestion, navURL) => {
    setActiveSuggestionIndex(-1);
    setQuery(suggestion);
    setShowSuggestions(false);
    onSearch(suggestion);

    if (suggestion !== '') {
      queryParams.set('search', suggestion);
      const type = enumReducerType.SET_SEARCH_TERM;
      const payload = suggestion;
      const action = { type, payload };
      dispatch(action);
    }
  };

  // when someone clicks on the search button
  const handleSearchClick = async (QueryPar) => {
    if (QueryPar !== '') {
      queryParams.set('search', QueryPar);
      const type = enumReducerType.SET_SEARCH_TERM;
      const payload = QueryPar;
      const action = { type, payload };
      dispatch(action);
      const newSearch = `?${queryParams.toString()}`;
      navigate({ search: newSearch });
      setShowSuggestions(false);
      setSearchToggle(false);
    }
    if (!isSCExperienceEditorActive) {
      if (getBrowserWindow) {
        window?.dataLayer?.push({
          event: 'search',
          search_term: QueryPar,
        });
      }
    }
    // const result = await fetchSearchResult('', 10, 0, 'en', false, true, query);
    // const searchResultApi = result?.data?.searchResult ? result?.data?.searchResult : [];
    // setsuggestions(searchResultApi);
  };

  // when press return key on input text field
  const handleSearch = async (event) => {
    const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    if (value.length >= 2) {
      _debounce();
    }

    // Handle scrolling the suggestions list and the offset
    const handleScroll = (offset, actIndex) => {
      const selectedSuggestion = suggestionRefs.current[actIndex];
      // const suggestionsList = selectedSuggestion.parentNode;
      const { scrollTop, clientHeight } = selectedSuggestion;
      const selectedTop = selectedSuggestion.offsetTop - offset;
      const selectedBottom = selectedTop + selectedSuggestion.clientHeight;
      if (selectedBottom > scrollTop + clientHeight) {
        selectedSuggestion.scrollTop = selectedBottom - clientHeight;
      } else if (selectedTop < scrollTop) {
        selectedSuggestion.scrollTop = selectedTop;
      }
    };

    // Handle arrow down key event
    const handleArrowDown = () => {
      event.preventDefault();
      if (activeSuggestionIndex < suggestions.length - 1) {
        // Navigate down the list
        setActiveSuggestionIndex((prev) => {
          handleScroll(-100, prev + 1);
          return prev + 1;
        });
        // handleScroll(-100);
      }
    };

    // Handle arrow up key event
    const handleArrowUp = () => {
      event.preventDefault();
      if (activeSuggestionIndex > -1) {
        if (activeSuggestionIndex === 0) {
          // if we're at the top of the list, go back to the input field
          setActiveSuggestionIndex(-1);
          inputRef.current.focus();
          return;
        }
        // Navigate up the list
        setActiveSuggestionIndex((prev) => prev - 1);
        handleScroll(100);
      }
    };

    // Handle enter key event
    const handleEnter = async () => {
      const result = await fetchSearchResult(
        selectedFilterpayloadinsight,
        10,
        0,
        'en',
        false,
        true,
        query
      );
      const searchResultApi = result?.data?.searchResult ? result?.data?.searchResult : [];
      setsuggestions(searchResultApi?.insightsSearchResult);
    };

    // Handle search with no suggestions
    const handleSearchNoSuggestions = () => {
      // perform search with query
      onSearch(query);
      if (query !== '') {
        queryParams.set('search', query);
        const type = enumReducerType.SET_SEARCH_TERM;
        const payload = query;
        const action = { type, payload };
        dispatch(action);
        const newSearch = `?${queryParams.toString()}`;
        navigate({ pathname: '/site-search', search: newSearch });
      }
    };
  };
  const currentUrl = window?.location?.pathname.replace('/', '');
  const { language } = getLanguageConfig(currentUrl);
  const API_URL = `${process.env.REACT_APP_SITECORE_API_HOST}/api/services/TSInsightsSearch/InsightsLandingSearch?limit=10&offset=0&language=${language}&sortRelevance=true&sortPopular=false&searchKeyword=`;
  const _debounce = debounce(async (time) => {
    await axios.get(`${API_URL}${query}`).then((response) => {
      const searchResultApi = response?.data?.searchResult ? response?.data?.searchResult : [];
      setsuggestions(searchResultApi);
    });
  }, 1000);
  // const _debounce = debounce(async (time) => {
  //   const result = await fetchSearchResult(
  //     selectedFilterpayloadinsight,
  //     10,
  //     0,
  //     'en',
  //     false,
  //     true,
  //     query
  //   );
  //   const searchResultApi = result?.data?.insightsSearchResult
  //     ? result?.data?.insightsSearchResult
  //     : [];
  //   setsuggestions(searchResultApi);
  // }, 4000);
  // update the hasSuggestions state based on the filtered suggestions
  // useEffect(() => {
  //   debounce(async (time) => {
  //     await axios.get(`${API_URL}${query}`).then((response) => {
  //       const searchResultApi = response?.data?.searchResult ? response?.data?.searchResult : [];
  //       setsuggestions(searchResultApi);
  //     });
  //   }, 4000);
  // }, [query]);
  // hide when click away
  const suggestionsRef = useRef(null);
  const handleClickAway = (event) => {
    if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    if (isBrowserDocumentAvailable) {
      document.addEventListener('mousedown', handleClickAway);
      return () => {
        document.removeEventListener('mousedown', handleClickAway);
      };
    }
  }, []);

  // update the hasSuggestions state based on the filtered suggestions
  useEffect(() => {
    if (suggestions) setHasSuggestions(suggestions.length > 0);
  }, [suggestions]);

  return (
    <div ref={suggestionsRef} className="searchFieldWrapper">
      {!isMobile ? (
        <div className="searchInputWrapper">
          <img src={IconSearch} alt="search" />
          <input
            type="text"
            value={query}
            onChange={handleQueryChange}
            onKeyUp={handleSearch}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSearchClick(query);
            }}
            onBlur={handleQueryBlur}
            className={isTyping ? 'typing' : 'search_input'}
            aria-label="Search"
            aria-activedescendant={
              activeSuggestionIndex > -1 ? `suggestion-${activeSuggestionIndex}` : undefined
            }
            aria-owns="suggestions-list"
            placeholder={inputPlaceholder ? inputPlaceholder : 'Search'}
          />
          <div className="searchButtons">
            <button
              ref={searchButtonRef}
              className="search_btn"
              onClick={() => handleSearchClick(query)}
            >
              {t('search-button-text')}
            </button>
            <button className="searchBarBtnClose desktop" onClick={() => setSearchToggle(false)}>
              Close
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="mobileSearchInputWrapper">
            <img src={IconSearch} alt="search" />
            <div className="searchButtons">
              <button
                ref={searchButtonRef}
                className="search_btn"
                onClick={() => handleSearchClick(query)}
              >
                Search
              </button>
              <button className="searchBarBtnClose desktop" onClick={() => setSearchToggle(false)}>
                Close
              </button>
            </div>
          </div>
          <div className="searchInputWrapper">
            <input
              type="text"
              value={query}
              onChange={handleQueryChange}
              onKeyUp={handleSearch}
              onBlur={handleQueryBlur}
              className={isTyping ? 'typing' : 'search_input'}
              aria-label="Search"
              aria-activedescendant={
                activeSuggestionIndex > -1 ? `suggestion-${activeSuggestionIndex}` : undefined
              }
              aria-owns="suggestions-list"
              placeholder={inputPlaceholder ? inputPlaceholder : 'Search'}
            />
          </div>
        </>
      )}
      {showSuggestions && (
        <>
          {hasSuggestions ? (
            <div className="suggestions">
              <ul
                role="listbox"
                id="suggestions-list"
                aria-label="suggestions list"
                className="suggestionsList"
              >
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    id={`suggestion-${index}`}
                    aria-selected={index === activeSuggestionIndex}
                    className={index === activeSuggestionIndex ? 'activeSuggestion' : ''}
                    // onClick={() => handleSuggestionClick(suggestion?.title, suggestion?.url)}
                    ref={(element) => (suggestionRefs.current[index] = element)}
                  >
                    <>
                      <SearchResultCard
                        key={index}
                        id={index}
                        title={suggestion.title}
                        description={suggestion.content}
                        date={suggestion.publicationDate}
                        cardCTA={suggestion?.url}
                      />
                    </>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default withTranslation()(SearchBarCardBlockCardListing);
