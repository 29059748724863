import React from 'react';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

const CardQuote = ({ title, name, backgroundColor, ctaUrl, componentName = '' }) => {
  return (
    <a
      href={ctaUrl}
      className={`card quote ${backgroundColor}`}
      {...OtherLinkDataAttributes({
        name: componentName,
        dataItemName: 'Article Pattern Book',
        text: title?.value,
      })}
    >
      <div className={`card-body ${backgroundColor === 'secondary' ? 'secondary' : 'primary'}`}>
        <h3 className="title-quote">&ldquo;{<TextSitecoreField field={title} />}&rdquo;</h3>
        {name && <p className="name">{<TextSitecoreField field={name} />}</p>}
      </div>
    </a>
  );
};

CardQuote.defaultProps = {
  title: '',
  name: '',
  backgroundColor: '',
  ctaUrl: '',
  componentName: '',
};
CardQuote.PropTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  backgroundColor: PropTypes.string,
  ctaUrl: PropTypes.string,
  componentName: PropTypes.string,
};

export default CardQuote;
