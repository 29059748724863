import React from 'react';
import CardSpeaker from '../Cards/CardSpeaker';
import PropTypes from 'prop-types';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';

const SidebarCardListSpeakers = ({ title, items, componentName }) => {
  return (
    <div className="article-sidebar speakers">
      {title && (
        <h3 className="sidebar-title">
          <TextSitecoreField field={title}></TextSitecoreField>
        </h3>
      )}
      {items && (
        <div className="card-list">
          {items.map((item) => (
            <CardSpeaker
              key={item?.id}
              img={item?.fields?.profileImage}
              name={item?.fields?.authorName}
              jobTitle={item?.fields?.jobTitle}
              Department={item?.fields?.Department}
              ctaUrl={item?.ctaUrl}
              cssClass={item?.cssClass}
              componentName={componentName}
            />
          ))}
        </div>
      )}
    </div>
  );
};

SidebarCardListSpeakers.defaultProps = {
  title: '',
  items: [],
};

SidebarCardListSpeakers.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SidebarCardListSpeakers;
