import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 * A component that displays an overlay loader with spinner and optional text message.
 * @param {Object} props - The props object containing loaderMsg, customClass and showText.
 * @param {string} props.loaderMsg - The text message to display along with the spinner.
 * @param {string} props.customClass - The custom CSS class to apply to the overlay.
 * @param {boolean} props.showText - A flag to determine whether to show the loaderMsg or not.
 * @returns {JSX.Element} - The JSX element representing the OverlayLoader component.
 */
const OverlayLoader = ({ loaderMsg, customClass, showText }) => (
  <div className={`overlay text-center ${customClass}`}>
    <div className="spinner-grow" style={{ width: '0.5rem', height: '0.5rem' }} role="status" />
    <div className="spinner-grow spinner-grow-sm" role="status" />
    <div className="spinner-grow" style={{ width: '1.5rem', height: '1.5rem' }} role="status" />
    <span className={showText ? 'init-loader-text' : 'sr-only'}>{loaderMsg}</span>
  </div>
);

OverlayLoader.propTypes = {
  loaderMsg: PropTypes.string,
  customClass: PropTypes.string,
  showText: PropTypes.bool,
};

OverlayLoader.defaultProps = {
  loaderMsg: '',
  customClass: '',
  showText: false,
};

export default OverlayLoader;
