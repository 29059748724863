import Cookies from 'js-cookie';
import moment from 'moment';
import { COOKIE_EXPIRY_PERIOD, CookieKey } from '../constants/cookie';
import { getCountry } from 'easy-reverse-geocoding';
import { ExperienceEditor } from '@sitecore-jss/sitecore-jss/utils';
import { get, isEmpty, keys } from 'lodash';
import { globalSiteRedirectionMap, languageMap, specialLanguageMap } from '../constants';
import { useLocation } from 'react-router-dom';

export const pageScriptObject = [
  {
    id: '794ab6a5-4cf2-4c0e-826c-2dca99d8a158',
    url: '/data/page-script-folder/gtm-body',
    name: 'GTM Body',
    displayName: 'GTM Body',
    fields: {
      pageScript: {
        value:
          // eslint-disable-next-line
          '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PSP92LB3" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
      },
      addScriptInHeadTag: {
        value: false,
      },
    },
  },
  {
    id: '8a6cb40f-b3e9-412a-aef4-c357ec2a5e1a',
    url: '/data/page-script-folder/gtm-header',
    name: 'GTM Header',
    displayName: 'GTM Header',
    fields: {
      pageScript: {
        value:
          // eslint-disable-next-line quotes
          "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-PSP92LB3');</script>",
      },
      addScriptInHeadTag: {
        value: true,
      },
    },
  },
];

export const checkLinkType = (link) =>
  link?.value?.linktype === 'internal' || link?.linktype === 'internal';
export const checkJSSObject = (obj) => {
  if (isObjNotEmpty(obj)) {
    if (obj.value) {
      const val = obj.value;
      return val.hasOwnProperty('href');
    }
    return obj.hasOwnProperty('href');
  }
  return false;
};
export const isObjNotEmpty = (obj) => obj && Object.keys(obj).length;
export const checkHref = (url) => url?.value?.href || url?.href;
export const showString = (data) => (data && typeof data === 'string' ? data : '');
export const scrollTop = () => {
  if (getBrowserWindow) {
    window && window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

// Get user location(lat,longitude)
export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (!global.navigator || !global.navigator.geolocation) {
      reject('Geolocation is not supported by your browser');
    } else {
      // console.log('Locating...');
      global.navigator.geolocation.getCurrentPosition(
        (position) => {
          const country = getCountry(position.coords.latitude, position.coords.longitude);
          resolve(country);
        },
        (error) => {
          reject(error);
        }
      );
    }
  });
};

export const getLocationContactus = () => {
  return new Promise((resolve, reject) => {
    if (!global.navigator || !global.navigator.geolocation) {
      reject('Geolocation is not supported by your browser');
    } else {
      // console.log('Locating...');
      global.navigator.geolocation.getCurrentPosition(
        (position) => {
          const country = getCountry(position.coords.latitude, position.coords.longitude);
          resolve(country);
          if (!Cookies.get('countryCode')) {
            Cookies.set('countryCode', country?.name);
            window.location.reload();
          }
        },
        (error) => {
          reject(error);
        }
      );
    }
  });
};

/**
 * Constructs a language redirection URL based on the given country name and regional ISO code.
 * @param {string} countryName - The name of the country.
 * @param {string} regionalIsoCode - The regional ISO code.
 * @returns {string} The constructed language redirection URL.
 */
export const constructLanguageRedirectionURL = (countryName = '', regionalIsoCode = 'en-BN') => {
  // construct /bn/en
  let reDirectionUrl = '';
  const regionCode = regionalIsoCode?.includes('-')
    ? regionalIsoCode?.toLocaleLowerCase().split('-')
    : regionalIsoCode;
  /** if CountryName is 'Global' then no split is required. 45708 -ticket regionalIsoCode can be used directly.   */
  if (countryName === 'Global') {
    reDirectionUrl = `../${get(globalSiteRedirectionMap, regionalIsoCode, 'en')}`;
  } else {
    reDirectionUrl = `/${regionCode[1]}/${regionCode[0]}`;
  }

  return reDirectionUrl;
};

export const getLanguageCodeFromCookie = () => {
  return Cookies.get(CookieKey.LANGUAGE);
};

export const getLanguageNameFromCookie = () => {
  return Cookies.get(CookieKey.LANGUAGE_NAME);
};

export const getregionNameFromCookie = () => {
  return Cookies.get(CookieKey.REGION);
};

export const getCountryFlagIconFromCookie = () => {
  return Cookies.get(CookieKey.COUNTRY_ICON);
};

export const getGlobalLanguageFromCookie = () => {
  return Cookies.get(CookieKey.GLOBAL);
};

export const getCountryLanguageFromCookie = (country) => {
  return Cookies.get(country ? `${country}#lang` : CookieKey.COUNTRY);
};

export const getsc_langFromCookie = () => {
  return Cookies.get(CookieKey.SC_LANG);
};
// set

export const setLanguageCodeToCookie = (language) => {
  Cookies.set(CookieKey.LANGUAGE, language, { expires: COOKIE_EXPIRY_PERIOD });
};

export const setLanguageNameToCookie = (languageName) => {
  Cookies.set(CookieKey.LANGUAGE_NAME, languageName, { expires: COOKIE_EXPIRY_PERIOD });
};

export const setRegionToCookie = (region) => {
  Cookies.set(CookieKey.REGION, region, { expires: COOKIE_EXPIRY_PERIOD });
};
export const setCountryFlagIconToCookie = (countryIcon) => {
  Cookies.set(CookieKey.COUNTRY_ICON, countryIcon, { expires: COOKIE_EXPIRY_PERIOD });
};

export const setCountryLanguageChoiceToCookie = (country, language) => {
  Cookies.set(country ? `${country}#lang` : CookieKey.COUNTRY, language, {
    expires: COOKIE_EXPIRY_PERIOD,
  });
};

export const chartTypeCompiler = (chartType) => {
  switch (chartType) {
    case 'bar':
      return 'bar';
    case 'barAlt':
      return 'bar-alt';
    case 'bubble':
      return 'bubble';
    case 'Doughnut':
      return 'Doughnut';
    case 'line':
      return 'line';
    case 'LinearGauge':
      return 'Linear Gauge';
    default:
      return 'bar';
  }
};

export const setGlobalLanguageToCookie = (global) => {
  Cookies.set(CookieKey.GLOBAL, global, { expires: COOKIE_EXPIRY_PERIOD });
};

export const setsc_langToCookie = (sc_lang = 'en') => {
  Cookies.set(CookieKey.SC_LANG, sc_lang, { expires: COOKIE_EXPIRY_PERIOD });
};

/**
 * Checks if Sitecore Experience Editor is active.
 * @returns {boolean} Returns true if Experience Editor is active, false otherwise.
 */
export const isSCExperienceEditorActive = ExperienceEditor.isActive();

/**
 * Returns the browser window object if it exists.
 * @returns {Window|undefined} The browser window object or undefined if it doesn't exist.
 */
export const getBrowserWindow = typeof window !== 'undefined';

/**
 * Returns the browser document object if it exists, otherwise returns undefined.
 * @returns {Document|undefined} The browser document object or undefined.
 */
export const getBrowserDocument = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    return document;
  }
  return undefined;
};
/**
 * Checks if the browser document is available.
 * @returns {boolean} Returns true if the browser document is available, otherwise false.
 */
export const isBrowserDocumentAvailable =
  typeof window !== 'undefined' && typeof document !== 'undefined';

/**
 * Returns the appropriate footer callout bar style based on the provided background color.
 * @param {string} backgroundColor - The background color to determine the style for.
 * @returns {string} - The appropriate style for the footer callout bar.
 */
export const getFooterCallOutBarStylesFromBackgroundColor = (backgroundColor = '') => {
  switch (backgroundColor.toLowerCase()) {
    case 'violet':
    case 'blue':
    case 'green':
      return backgroundColor.toLowerCase();
    case 'black':
      return 'secondary';
    case 'white':
      return 'primary';
    case 'secondary':
      return 'primary';
    case 'primary':
      return 'secondary';
    default:
      return 'violet';
  }
};
export const mapBackgroundColor = (color = '') => {
  let backgroundColor = '';
  if (color.toLowerCase() === 'primary') {
    backgroundColor = 'secondary';
  } else {
    backgroundColor = 'primary';
  }
  return backgroundColor;
};
export const sitecoreHostUrl = process.env.REACT_APP_SITECORE_API_HOST;
export const sitecoreAPIKey = process.env.REACT_APP_SITECORE_API_KEY;
// export const specialLanguageMapEnv = JSON.parse(process.env.REACT_APP_SPECIAL_LANGUAGE_CONFIG);

export const LANGUAGE_REG_EXP = /^\/?(([a-zA-Z]{2}\/[a-zA-Z]{2})|([a-zA-Z]{2}))(\/|$)/g;

/**
 * Parses the language from a given URL.
 * @param {string} url - The URL to parse the language from.
 * @returns {string} - The language code in the format of "languageCode-COUNTRYCODE", or "en" if no language is found.
 */
export const parseLanguageFromURL = (url = '') => {
  const language = url.match(LANGUAGE_REG_EXP);
  let languageToSC = 'en';
  if (language && language.length > 0) {
    const matchedLanguage = language[0];
    const split = matchedLanguage.split('/');
    if (split.length > 1) {
      languageToSC = !isEmpty(split[1])
        ? `${split[1].toLowerCase()}-${split[0].toUpperCase()}`
        : languageMap[`${split[0].toLowerCase()}`];
    } else {
      languageToSC = languageMap[`${matchedLanguage}`];
    }
  }
  return languageToSC;
};

export const getContentTypeTags = (item = {}, fields = {}) => {
  let pubDate = item?.date ? item?.date : item?.publicationDate;
  pubDate = pubDate ? pubDate : item?.fields?.publicationDate?.value;
  const location = useLocation();
  const getCountry = getCountryLangForOT(location.pathname);
  const countryVal =
    getCountry && !isEmpty(getCountry) && getCountry.includes('-')
      ? `${getCountry.split('-')[1]}`
      : '';
  const dateValue = countryVal === 'us' || countryVal === 'ca' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

  return {
    text: pubDate?.includes('0001') ? '' : `${moment(pubDate)?.format(dateValue)}`,
    length: item?.mediaLength,
    CT: item?.contentType ? item?.contentType : item?.fields?.contentType,
    fields: fields,
  };
};

export const getLanguageConfig = (url = '') => {
  const language = url.match(LANGUAGE_REG_EXP);
  if (language && language.length > 0) {
    const matchedLanguage = language[0];
    const split = matchedLanguage.split('/').filter((item) => !isEmpty(item));
    if (split && split.length >= 2) {
      // we have detected a country site here.
      // extract language from the url and return the language code.
      let languageToSC = '';
      if (isEmpty(split[1])) {
        languageToSC = languageMap[`${split[0].toLowerCase()}`];
      } else {
        // check if language is part of a special list, if so map the value from the special object map and return the new languageToSC otherwise return languagetoSC as it was.
        if (keys(specialLanguageMap).includes(matchedLanguage)) {
          languageToSC = get(specialLanguageMap, `${matchedLanguage}`);
        } else {
          languageToSC = `${split[1].toLowerCase()}-${split[0].toUpperCase()}`;
        }
      }
      return { isGlobal: false, language: languageToSC, country: split[0] };
    } else if (split && split.length === 1) {
      // user has directly entered the url with a language suffix.
      return { isGlobal: true, language: languageMap[`${split[0]}`], country: 'Global' };
    }
  }
  return { isGlobal: true, language: undefined, country: 'Global' };
};

// eslint-disable-next-line
export const getCountryLanguageToDisplay = (
  isMobileScreen = false,
  isGlobal,
  country,
  language = 'en'
) => {
  const storiedCountryLanguageCookieValue =
    getCountryLanguageFromCookie(country) || getGlobalLanguageFromCookie();
  if (storiedCountryLanguageCookieValue) {
    if (isMobileScreen) {
      return storiedCountryLanguageCookieValue.split('-')[0].toUpperCase();
    }
    if (isGlobal) {
      return storiedCountryLanguageCookieValue.split('-')[0].toUpperCase();
    }
    const splitStr = language?.split('-');
    return !isEmpty(splitStr) && splitStr[1] && splitStr[0]
      ? `${splitStr[1].toUpperCase()} | ${splitStr[0].toUpperCase()}`
      : storiedCountryLanguageCookieValue.toUpperCase();
  }
  return 'EN';
};

export const currentLanguage = () => {
  if (getBrowserWindow) {
    const { country } = getLanguageConfig(window?.location?.pathname);
    return getCountryLanguageFromCookie(country) || getGlobalLanguageFromCookie();
  }
  return 'en';
};

export const languageTodisplayOnFooter = (str = '') => {
  if (getBrowserWindow) {
    return str && str.split('-')[0].toUpperCase();
  }
  return 'EN';
};

export const filterKeyEnter = (handler) => {
  return (e) => {
    if (e.keyCode === 13) {
      handler(e);
    }
  };
};
const barAltChart = (data = {}) => {
  const { Chart: primaryData = {} } = data;
  const primaryDataLabel =
    primaryData &&
    primaryData?.fields?.dataSet?.map((item) => {
      return item?.fields?.name?.value;
    });
  const primaryDataValue =
    primaryData &&
    primaryData?.fields?.dataSet?.map((item) => {
      return item?.fields?.value?.value;
    });
  const barAltDataStructure = {
    title: primaryData?.fields?.title?.value,
    descLabel: primaryData?.fields?.dateLabel?.value,
    description: primaryData?.fields?.dateValue?.value,
    chartType: primaryData?.fields?.type?.value,

    data: {
      labels: primaryDataLabel,
      datasets: [
        {
          label: primaryData?.fields?.dateLabel?.value,
          data: primaryDataValue,
          borderRadius: 100,
          barPercentage: 1.0,
          categoryPercentage: 0.8,
        },
      ],
      yValues: {
        min: Math.min(...primaryDataValue) - 2,
        max: Math.max(...primaryDataValue) + 2,
        stepSize: 1,
      },
    },
  };
  return barAltDataStructure;
};

const bar = (data = {}) => {
  const { Chart = {} } = data;
  const primaryDataLabel =
    Chart &&
    Chart?.fields?.dataSet?.map((item) => {
      return item?.fields?.name?.value;
    });
  const primaryDataValue =
    Chart &&
    Chart?.fields?.dataSet?.map((item) => {
      return item?.fields?.value?.value;
    });
  const barDataStructure = {
    title: Chart?.fields?.title?.value,
    chartType: Chart?.fields?.type?.value.toLowerCase(),
    data: {
      datasets: [
        {
          barPercentage: 1.0,
          borderRadius: 100,
          categoryPercentage: 0.8,
          data: primaryDataValue,
          label: Chart?.fields?.dateLabel?.value,
        },
      ],
      labels: primaryDataLabel,
      yValues: {
        min: 0,
        max: Math.max(...primaryDataValue) + 2,
      },
    },
  };
  return barDataStructure;
};

const bubble = (data = {}) => {
  const { Chart = {} } = data;
  const primaryDataLabel =
    Chart &&
    Chart?.fields?.dataSet?.map((item) => {
      return item?.fields?.title?.value;
    });

  let xAxis = Chart?.fields?.dataSet?.map((item) => item?.fields?.xAxis?.value);
  let yAxis = Chart?.fields?.dataSet?.map((item) => item?.fields?.yAxis?.value);

  const primaryDataValue =
    Chart &&
    Chart?.fields?.dataSet?.map((item) => {
      return {
        data: [
          {
            r: item?.fields?.rAxis?.value,
            x: item?.fields?.xAxis?.value,
            y: item?.fields?.yAxis?.value,
          },
        ],

        label: item?.fields?.title?.value,
      };
    });

  const bubbleDataStructure = {
    title: Chart?.fields?.title?.value,
    chartType: Chart?.fields?.Type?.value?.toLowerCase(),
    descLabel: Chart?.fields?.description?.value,
    data: {
      datasets: primaryDataValue,
      labels: primaryDataLabel,
      xLabel: Chart?.fields?.xAxisLabel?.value,
      xValues: {
        max: Math.max(...xAxis) + 10,
        min: 0,
      },
      yLabel: Chart?.fields?.yAxisLabel?.value,
      yValues: {
        max: Math.max(...yAxis) + 10,
        min: 0,
      },
    },
  };
  return bubbleDataStructure;
};

const doughnut = (data = {}) => {
  if (!isEmpty(data)) {
    let D_newChartData = {
      chartType: data?.Chart?.fields?.type?.value,
      data: {
        labels: data?.Chart?.fields?.dataSet.map((item) => item?.fields?.name?.value),
        datasets: [
          {
            label: data?.Chart?.fields?.hoverText?.value,
            fill: 'start',
            data: data?.Chart?.fields?.dataSet.map((item) => item?.fields?.value?.value),
            borderWidth: 0,
            borderColor: 'transparent',
          },
        ],
      },
      descLabel: data?.Chart?.fields?.descLabel?.value,
      description: data?.Chart?.fields?.description?.value,
      title: data?.Chart?.fields?.title?.value,
    };
    return D_newChartData;
  }
};

const linear_gauge = (Data = {}) => {
  if (!isEmpty(Data)) {
    let xMaxVal = Data?.Chart?.fields?.subList
      ?.map((item) => item?.fields?.dataSet?.map((item2) => item2.fields?.value?.value))
      .flat(1);
    let xMaxArr = xMaxVal.map((e) => parseInt(e, 10));
    let xMax = Math.max(...xMaxArr);
    let l_newChartData = {
      chartType: Data?.Chart?.fields?.type?.value,
      data: {
        labels: Data?.Chart?.fields?.subList?.map((item) => item.fields?.Title?.value),
        datasets: Data?.Chart?.fields?.subList
          ?.map((item) =>
            item?.fields?.dataSet?.map((item2) => {
              return {
                label: item2.fields?.name?.value,
                data: [parseInt(item2.fields?.value?.value, 10)],
              };
            })
          )
          .flat(1),
        xValues: {
          min: 0,
          max: xMax + 1,
        },
      },
    };
    return l_newChartData;
  }
};

const line = (data = {}) => {
  const { Chart = {} } = data;
  const primaryDataLabel =
    Chart &&
    Chart?.fields?.dataSet?.map((item) => {
      return item?.fields?.name?.value;
    });
  const primaryDataValue =
    Chart &&
    Chart?.fields?.dataSet?.map((item) => {
      return item?.fields?.value?.value;
    });
  let xMin = Math.min(...primaryDataValue) <= 50 ? 0 : 50;

  let Label = Chart?.fields?.dateLabel?.value.map((e) => Number(e));
  const lineDataStructure = {
    title: Chart?.fields?.title?.value,
    chartType: Chart?.fields?.type?.fields?.type?.value?.toLowerCase(),
    descLabel: Chart?.fields?.description?.value,
    data: {
      datasets: [
        {
          borderWidth: 2,
          data: primaryDataValue,
          label: Chart?.fields?.dateLabel?.value,
          spanGaps: true,
        },
      ],

      labels: primaryDataLabel,

      xMaxTicks: 7,
      yDataSuffix: '%',
      yValues: {
        min: xMin,
        max: 100,
        stepSize: 10,
      },
    },
  };
  return lineDataStructure;
};

const Number = (origData) => {
  let N_newChartData = {
    chartType: origData?.fields?.type?.value,
    data: {
      preText: origData?.fields?.preText?.value,
      statisticValue: origData?.fields?.statisticValue?.value,
      postText: origData?.fields?.postText?.value,
    },
  };
  return N_newChartData;
};

export const wrapChartData = (data = {}) => {
  let chartType = '';
  if (!isEmpty(data)) {
    if (!isEmpty(data?.Chart?.fields?.type?.fields)) {
      chartType = data?.Chart?.fields?.type?.fields?.type?.value;
    } else if (!isEmpty(data?.Chart?.fields?.Type)) {
      chartType = data?.Chart?.fields?.Type?.value;
    } else if (!isEmpty(data?.Chart)) {
      chartType = data?.Chart?.fields?.type?.value;
    } else if (!isEmpty(data?.fields?.Chart)) {
      chartType = data?.fields?.Chart?.fields?.type?.value;
    } else if (!isEmpty(data?.Chart)) {
      chartType = data?.Chart?.fields?.type?.fields?.type?.value;
    } else {
      chartType = '';
    }
  }

  switch (chartType) {
    case 'Bar':
      return bar(data);
      break;
    case 'Bar alt':
      return barAltChart(data);
    case 'Bubble':
      return bubble(data);
    case 'Doughnut':
      return doughnut(data);
    case 'line':
      return line(data);
    case 'Linear Gauge':
      return linear_gauge(data);
    case 'number':
      return Number(data);
    default:
      return null;
  }
};
export const isDataExists = (data) => data && data.length;

export const getCountryLangForOT = (url = '') => {
  const language = url?.match(LANGUAGE_REG_EXP);
  if (language && language.length > 0) {
    const urlSplit = language[0]?.split('/');
    let filteredValues = urlSplit.filter(function (element) {
      return element !== '';
    });
    let languageForOT;
    if (filteredValues?.length >= 2) {
      languageForOT = filteredValues[1] + '-' + filteredValues[0];
    } else {
      languageForOT = filteredValues[0];
    }
    return languageForOT;
  }
  return 'en';
};
export const cookiePolicy = () => {
  if (document.getElementById('ot-sdk-btn')) document.getElementById('ot-sdk-btn').click();
};
