import { createStore, combineReducers } from 'redux';
import { filterReducer, masterReducer, searchfilterReducer } from '../reducer';
import { getBrowserWindow } from '../../utils/HelperUtils';

export const configStore = () => {
  const myStore = createStore(
    combineReducers({ filterReducer, masterReducer, searchfilterReducer })
  );
  if (getBrowserWindow) {
    window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_();
  }
  return myStore;
};
