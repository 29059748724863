import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionTabItem from './AccordionTabItem';

const AccordionTab = ({ items, backgroundColor }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const toggleAccordion = (item) => {
    setActiveIndex((prevIndex) => (prevIndex === item.id ? -1 : item.id));
  };

  return (
    <div className="accordion-section">
      {items &&
        items?.map((item) => (
          <AccordionTabItem
            key={item?.id}
            name={item?.name}
            title={item?.fields?.title}
            text={item?.fields?.Description}
            ctaText={item?.fields?.Link?.value?.text}
            ctaLink={item?.fields?.Link?.value?.href}
            linkHeading={item?.fields?.linkHeading}
            links={item?.fields?.linkCollection}
            iconSrc={item?.fields?.tabIcon?.value?.src}
            cssClass={item?.cssClass}
            isActive={activeIndex === item.id}
            backgroundColor={backgroundColor}
            onShow={() => toggleAccordion(item)}
          />
        ))}
    </div>
  );
};

AccordionTab.defaultProps = {
  items: [],
};

AccordionTab.propTypes = {
  items: PropTypes.array,
};

export default AccordionTab;
