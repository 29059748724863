import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import './sitefooter.scss';
/**
 *@description -  Render AppLink Component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const renderAppLink = (obj) => (
  <a
    href={obj?.fields?.url?.value?.href}
    target="_blank"
    rel="noopener noreferrer"
    className={obj.name === 'apple' ? 'applink-one' : 'applink-two'}
    aria-label={obj?.fields?.ctaText?.value}
    title={obj?.fields?.ctaText?.value}
  >
    <img className="apps-image" src={obj?.fields?.svgImage?.value?.href} />
  </a>
);
/**
 *@description -  Site Footer Apps Component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const SiteFooterApps = (props) => {
  const { fields = {} } = props;
  return (
    <>
      <div className="sub-footer-apps">
        <div className=" row applinks">
          <div className="col-xl-4 col-lg-4 col-sm-12"></div>
          <div className="col-xl-4 col-lg-5 col-sm-12 d-flex align-items-left justify-content-left apps-tablet">
            {fields?.items?.map(
              (obj) => (obj.name === 'apple' || obj.name === 'android') && renderAppLink(obj)
            )}
          </div>
          <div className="col-xl-4 col-lg-3 col-sm-12 d-flex align-items-center justify-content-end trust-seal pr-0">
            {fields?.items?.map((obj) => obj.name === 'Trust' && renderAppLink(obj))}
          </div>
        </div>
      </div>
    </>
  );
};
SiteFooterApps.defaultProps = {
  fields: {},
};
SiteFooterApps.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};
export default SiteFooterApps;
