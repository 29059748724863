import React from 'react';

function Checkbox(props) {
  const { reg, errors, id, className, Label, isRequiredField, checked, onClick } = props;
  return (
    <div className="form-block checkbox formChk">
      <input type="checkbox" id={id} onClick={onClick} {...reg} />
      <label className={className} htmlFor={id}>
        {Label} {isRequiredField ? '*' : ''}
      </label>

      {!checked && errors && errors.type === 'required' && (
        <p className="error-msg">{errors.message}</p>
      )}
    </div>
  );
}

export default Checkbox;
