import React, { useEffect, useState } from 'react';
import DataBlockStatistic from './DataBlockStatistic';
import DataBlock from './DataBlock/DataBlock';
import CardsforFrame from './CardsforFrame';
import RankDataBlock from './RankDataBlock';
import './FramDataBlock.scss';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../../scripts/breakpoints';
import TextSitecoreField from '../../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../../sitecorefields/ImageSitecoreField';
import axios from 'axios';
import { sitecoreHostUrl } from '../../../../utils/HelperUtils';

function FrameDataBlock(props) {
  const [respData1, setRespData1] = useState();
  const [propsData, setPropsData] = useState();

  useEffect(() => {
    apiGet();
  }, []);

  const apiGet = async () => {
    try {
      const url = `${sitecoreHostUrl}/api/json/${props?.fields?.ApiId?.value}`;
      const apiCall = await axios.get(url);
      const apiRes = await apiCall?.data;
      setRespData1(apiRes);
      setPropsData(variable(apiRes));
    } catch (error) {}
  };

  let desktopWidth = useMediaQuery({ maxWidth: 1024 });

  const isTiny = useMediaQuery({ maxWidth: breakpoints.xxs - 1 });
  const isMobile = useMediaQuery({
    minWidth: breakpoints.xxs,
    maxWidth: breakpoints.xs - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: breakpoints.xs,
    maxWidth: breakpoints.xl,
  });
  const getSize = () => {
    if (isTiny) {
      return 'Tiny';
    } else if (isMobile) {
      return 'XXS';
    } else if (isTablet) {
      return 'S';
    }
    return 'M';
  };

  const { fields } = props;
  let tabTitles = fields?.Tabs?.map((item) => item.fields.TabTitle);
  let tabIcon = fields?.Tabs?.map((item) => item.fields.TabIcon);
  let chartTitle = fields?.Tabs?.map((item) => item.fields.Description.value);

  let chartTitles = fields?.Tabs?.map((item) =>
    item.fields.ChartHeadingList.map((item2) => item2.fields?.chartHeading?.value)
  );

  let Description_Reg = fields?.Tabs[1]?.fields?.Description;

  const variable = (respData1) => {
    let chartTitle1 = chartTitles[0];
    let chartTitle2 = chartTitles[1];
    let chartTitle3 = chartTitles[2];
    let chartTitle4 = chartTitles[3];

    let dounghtData = [
      respData1?.Availability?.babyBoomersValue,
      respData1?.Availability?.genXValue,
      respData1?.Availability?.genYValue,
      respData1?.Availability?.genZValue,
      respData1?.Availability?.genAlphaValue,
    ];

    let dounghtDataVal = dounghtData.map((e) => e.slice(0, -1));

    let parameter = {
      PrimaryDetails: {
        statistic1: {
          color: 'violet',
          data: {
            postText: respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingLabel,
            statisticChange: 0,
            statisticSuffix:
              respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingValue.slice(-2),
            statisticValue: respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingValue.slice(
              0,
              respData1?.PrimaryDetails?.totalWorkforceIndexGlobalRankingValue.length - 4
            ),
          },
          size: 'M',
          type: 'ranking',
        },
        statistic2: {
          color: 'violet',
          data: {
            postText: respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingLabel,
            statisticChange: 0,
            statisticSuffix:
              respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingValue.slice(-2),
            statisticValue:
              respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingValue.slice(
                0,
                respData1?.PrimaryDetails?.totalWorkforceIndexRegionalRankingValue.length - 4
              ),
          },
          size: 'M',
          type: 'ranking',
        },
      },
      Availability: {
        doughnut: {
          chartType: 'doughnut',
          size: getSize(),
          data: {
            datasets: [
              {
                borderColor: 'transparent',
                borderWidth: 0,
                data: dounghtDataVal,
                fill: 'start',
                label: 'Net Secondary Education',
              },
            ],
            labels: [
              respData1?.Availability?.babyBoomersLabel,
              respData1?.Availability?.genXLabel,
              respData1?.Availability?.genYLabel,
              respData1?.Availability?.genZLabel,
              respData1?.Availability?.genAlphalabel,
            ],
          },
          descLabel: respData1?.Availability?.totalWorkforceLabel,
          description: respData1?.Availability?.totalWorkforceValue,
          title: chartTitle1[0],
        },
        rank1: {
          value: respData1?.Availability?.availabilityGlobalRankingValue,
          description: respData1?.Availability?.availabilityGlobalRankingDescriptionValue,
        },
        card1: {
          title: respData1?.Availability?.englishProficiencyValue,
          subtitle: respData1?.Availability?.englishProficiencyLabel,
          description: respData1?.Availability?.englishProficiencyDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card2: {
          title: respData1?.Availability?.percentageHighlySkilledValue,
          subtitle: respData1?.Availability?.percentageHighlySkilledLabel,
          description: respData1?.Availability?.highlySkilledDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card3: {
          title: respData1?.Availability?.netSecondaryEducationValue,
          subtitle: respData1?.Availability?.netSecondaryEducationLabel,
          description: respData1?.Availability?.netSecondaryEducationDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card4: {
          title: respData1?.Availability?.genderGapValue,
          subtitle: respData1?.Availability?.genderGapLabel,
          description: respData1?.Availability?.genderGapRankingDescriptionValue.split('.')[0],
          linkTitle: respData1?.Availability?.genderGapRankingDescriptionValue.split(' ')[5],
          linkUrl: respData1?.Availability?.genderGapRankingLinkValue,
        },
        card5: {
          title: respData1?.Availability?.femaleParticipationRateValue,
          subtitle: respData1?.Availability?.femaleParticipationRateLabel,
          description: respData1?.Availability?.femaleParticipationRateDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card6: {
          title: respData1?.Availability?.maleParticipationRateValue,
          subtitle: respData1?.Availability?.maleParticipationRateLabel,
          description: respData1?.Availability?.maleParticipationRateDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card7: {
          title: respData1?.Availability?.informalWorkforceValue,
          subtitle: respData1?.Availability?.informalWorkforceLabel,
          description: respData1?.Availability?.informalWorkforceDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card8: {
          title: respData1?.Availability?.remoteReadinessRankingValue,
          subtitle: respData1?.Availability?.remoteReadinessRankingLabel,
          description: respData1?.Availability?.remoteReadinessRankingDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
      },
      costeffeciency: {
        bar3: {
          chartType: 'bar',
          size: getSize(),
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.CostEfficiency?.averageMonthlyWageCountryValue,
                  respData1?.CostEfficiency?.averageMonthlyWageRegionValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.CostEfficiency?.averageMonthlyWageCountryLabel.split(' ')[3],
              respData1?.CostEfficiency?.averageMonthlyWageRegionLabel.split(' ')[3],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.CostEfficiency?.averageMonthlyWageCountryValue,
                    respData1?.CostEfficiency?.averageMonthlyWageRegionValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle3[0],
        },
        rank2: {
          value: respData1?.CostEfficiency?.costEfficiencyGlobalRankingValue,
          description: respData1?.CostEfficiency?.costEfficiencyGlobalRankingDescriptionValue,
        },
        card9: {
          title: respData1?.CostEfficiency?.isEqualPayforEqualWorkMandatedValue,
          subtitle: respData1?.CostEfficiency?.isEqualPayforEqualWorkMandatedLabel,
          description: respData1?.CostEfficiency?.isEqualPayforEqualWorkMandatedDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card10: {
          title: respData1?.CostEfficiency?.parityRequirementsValue,
          subtitle: respData1?.CostEfficiency?.parityRequirementsLabel,
          description: respData1?.CostEfficiency?.parityRequirementsDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card11: {
          title: respData1?.CostEfficiency?.standardOvertimePremiumValue,
          subtitle: respData1?.CostEfficiency?.standardOvertimePremiumLabel,
          description: respData1?.CostEfficiency?.standardOvertimePremiumDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card12: {
          title: respData1?.CostEfficiency?.taxBurdenScoreValue,
          subtitle: respData1?.CostEfficiency?.taxBurdenScoreLabel,
          description: '',
          linkTitle: respData1?.CostEfficiency?.taxBurdenScoreDescriptionValue,
          linkUrl: respData1?.CostEfficiency?.taxBurdenScoreLinkValue,
        },
        card13: {
          title: respData1?.CostEfficiency?.costOfLivingValue,
          subtitle: respData1?.CostEfficiency?.costOfLivingLabel,
          description: respData1?.CostEfficiency?.costOfLivingDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
      },
      Regulation: {
        bar1: {
          chartType: 'bar',
          size: getSize(),
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Regulation?.severanceNoticePeriodCountryValue,
                  respData1?.Regulation?.severanceNoticePeriodGlobalValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Regulation?.severanceNoticePeriodCountryLabel.split('-')[2],
              respData1?.Regulation?.severanceNoticePeriodGlobalLabel.split('-')[2],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Regulation?.severanceNoticePeriodCountryValue,
                    respData1?.Regulation?.severanceNoticePeriodGlobalValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle2[0],
        },
        rank3: {
          value: respData1?.Regulation?.regulationGlobalRankingValue,
          description: respData1?.Regulation?.regulationGlobalRankingDescriptionValue,
        },
        card14: {
          title: respData1?.Regulation?.subcontractallowedValue,
          subtitle: respData1?.Regulation?.subcontractallowedLabel,
          description: respData1?.Regulation?.subcontractallowedDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card15: {
          title: respData1?.Regulation?.maximumContractDurationValue,
          subtitle: respData1?.Regulation?.maximumContractDurationLabel,
          description:
            respData1?.Regulation?.notesUnderneathContractDurationValue !== 'NA'
              ? respData1?.Regulation?.maximumContractDurationDescriptionValue +
                '. ' +
                respData1?.Regulation?.notesUnderneathContractDurationValue
              : respData1?.Regulation?.maximumContractDurationDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card16: {
          title: respData1?.Regulation?.cybersecurityGlobalRankValue,
          subtitle: respData1?.Regulation?.cybersecurityGlobalRankLabel,
          description:
            respData1?.Regulation?.cybersecurityGlobalRankDescriptionValue.split('By')[0],
          linkTitle:
            'By ' + respData1?.Regulation?.cybersecurityGlobalRankDescriptionValue.split('By')[1],
          linkUrl: respData1?.Regulation?.cybersecurityGlobalRankLinkValue,
        },
        card17: {
          title: respData1?.Regulation?.indexofEconomicFreedomValue,
          subtitle: respData1?.Regulation?.indexofEconomicFreedomLabel,
          description: '',
          linkTitle: respData1?.Regulation?.indexofEconomicFreedomDescriptionValue,
          linkUrl: respData1?.Regulation?.indexofEconomicFreedomLinkValue,
        },
        card18: {
          title: respData1?.Regulation?.businessEnvironmentRiskGradeValue,
          subtitle: respData1?.Regulation?.businessEnvironmentRiskGradeLabel,
          description:
            respData1?.Regulation?.businessEnvironmentRiskGradeDescriptionValue.split('By')[0],
          linkTitle:
            'By ' +
            respData1?.Regulation?.businessEnvironmentRiskGradeDescriptionValue.split('By')[1],
          linkUrl: respData1?.Regulation?.businessEnvironmentRiskGradeLinkValue,
        },
      },
      Productivity: {
        bar4: {
          chartType: 'bar',
          size: getSize(),
          data: {
            datasets: [
              {
                barPercentage: 1,
                borderRadius: 100,
                categoryPercentage: 0.8,
                data: [
                  respData1?.Productivity?.leaveAfter1YearsCountryValue,
                  respData1?.Productivity?.leaveAfter1YearsRegionValue,
                ],
                label: 'Workforce rankings',
              },
            ],
            labels: [
              respData1?.Productivity?.leaveAfter1YearsCountryLabel.split('-')[1],
              respData1?.Productivity?.leaveAfter1YearsRegionLabel.split('-')[1],
            ],
            yValues: {
              max:
                Math.max(
                  ...[
                    respData1?.Productivity?.leaveAfter1YearsCountryValue,
                    respData1?.Productivity?.leaveAfter1YearsRegionValue,
                  ].map((i) => Number(i))
                ) * 1.2,
              min: 0,
            },
          },
          title: chartTitle4[0],
        },
        rank4: {
          value: respData1?.Productivity?.productivityGlobalRankingValue,
          description: respData1?.Productivity?.productivityGlobalRankingDescriptionValue,
        },
        card19: {
          title: respData1?.Productivity?.gdpGrowthValue,
          subtitle: respData1?.Productivity?.gdpGrowthLabel,
          description: respData1?.Productivity?.gdpGrowthDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card20: {
          title: respData1?.Productivity?.governmentAIReadinessValue,
          subtitle: respData1?.Productivity?.governmentAIReadinessLabel,
          description: '',
          linkTitle:
            'By ' + respData1?.Productivity?.governmentAIReadinessDescriptionValue.split(' ')[1],
          linkUrl: respData1?.Productivity?.governmentAIReadinessLinkValue,
        },
        card21: {
          title: respData1?.Productivity?.globalInnovationIndexValue,
          subtitle: respData1?.Productivity?.globalInnovationIndexLabel,
          description: respData1?.Productivity?.globalInnovationIndexDescriptionValue,
          linkTitle: '',
          linkUrl: '',
        },
        card22: {
          title: respData1?.Productivity?.globalGreenEconomyIndexValue,
          subtitle: respData1?.Productivity?.globalGreenEconomyIndexLabel,
          description:
            respData1?.Productivity?.globalGreenEconomyIndexDescriptionValue.split('By')[0],
          linkTitle:
            'By ' + respData1?.Productivity?.globalGreenEconomyIndexDescriptionValue.split('By')[1],
          linkUrl: respData1?.Productivity?.globalGreenEconomyIndexLinkValue,
        },
      },
    };
    return parameter;
  };

  let column_2 = !desktopWidth ? 'col-2' : '';
  let column_3 = !desktopWidth ? 'col-3' : '';
  let column_4 = !desktopWidth ? 'col-4' : '';
  let column_5 = !desktopWidth ? 'col-5' : '';
  let column_6 = !desktopWidth ? 'col-6' : '';
  let column_7 = !desktopWidth ? 'col-7' : '';
  let column_8 = !desktopWidth ? 'col-8' : '';
  let column_9 = !desktopWidth ? 'col-9' : '';
  let column_12 = !desktopWidth ? 'col-12' : '';

  return (
    <>
      {propsData?.Availability?.doughnut && (
        <section className="FramDataBlock_Container" id="FramDataBlock_Container">
          <div className="container-xl">
            <div className="row rankingData">
              <h3 className="rankingHeader">Total Workforce Index™ Ranking</h3>
              <div className="threeRankChart">
                <div className={`${column_12}`}>
                  <div className="block-row">
                    <DataBlockStatistic Data={propsData?.PrimaryDetails?.statistic1} />
                    <DataBlockStatistic Data={propsData?.PrimaryDetails?.statistic2} />
                  </div>
                </div>
              </div>
            </div>
            <div className="availability">
              <div className="Headings">
                <div className="TitleIcon">
                  <ImageSitecoreField field={tabIcon[0]} />
                </div>
                <div className="TitleValue1">
                  <h2>{<TextSitecoreField field={tabTitles[0]} />}</h2>
                </div>
                <div className="TitleValue2">
                  <h3>{<RankDataBlock Data={propsData?.Availability?.rank1} />}</h3>
                </div>
              </div>

              <div className={`${column_12} donught_Container`}>
                <div className={`${column_7} newContainer`}>
                  <DataBlock Data={propsData?.Availability?.doughnut} showSymbol={false} />
                </div>
                <div className={`${column_5} availabilityRanking`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Availability?.card1} />
                    <CardsforFrame Data={propsData?.Availability?.card2} />
                    <CardsforFrame Data={propsData?.Availability?.card3} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Availability?.card4} />
                    <CardsforFrame Data={propsData?.Availability?.card5} />
                    <CardsforFrame Data={propsData?.Availability?.card6} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Availability?.card7} />
                    <CardsforFrame Data={propsData?.Availability?.card8} />
                  </div>
                </div>
              </div>
            </div>
            <div className="costEfficiency">
              <div className="Headings">
                <div className="TitleIcon">
                  <ImageSitecoreField field={tabIcon[2]} />
                </div>
                <div className="TitleValue1">
                  <h2>{<TextSitecoreField field={tabTitles[2]} />}</h2>
                </div>
                <div className="TitleValue2">
                  <h3>{<RankDataBlock Data={propsData?.costeffeciency?.rank2} />}</h3>
                </div>
              </div>
              <div className={`${column_12} twoBarContainer barchartCanvas`}>
                <div className={`${column_7} newContainer`}>
                  <DataBlock Data={propsData?.costeffeciency?.bar3} showSymbol={true} />
                </div>

                <div className={`${column_5} costEfficiencyRanking`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.costeffeciency?.card9} />
                    <CardsforFrame Data={propsData?.costeffeciency?.card10} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.costeffeciency?.card11} />
                    <CardsforFrame Data={propsData?.costeffeciency?.card12} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.costeffeciency?.card13} />
                  </div>
                </div>
              </div>
            </div>
            {/* Changed the position of below div - cost efficiency and regulation */}
            <div className="regulationData">
              <div className="Headings">
                <div className="TitleIcon">
                  <ImageSitecoreField field={tabIcon[1]} />
                </div>
                <div className="TitleValue1">
                  <h2>{<TextSitecoreField field={tabTitles[1]} />}</h2>
                </div>
                <div className="TitleValue2">
                  <h3>{<RankDataBlock Data={propsData?.Regulation?.rank3} />}</h3>
                </div>
              </div>

              <div className="twoBarContainer barchartCanvas">
                <div className={`${column_7} newContainer`}>
                  <DataBlock Data={propsData?.Regulation?.bar1} showSymbol={false} />
                </div>
                <div className={`${column_5} regulationDataRanking`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Regulation?.card14} />
                    <CardsforFrame Data={propsData?.Regulation?.card15} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Regulation?.card16} />
                    <CardsforFrame Data={propsData?.Regulation?.card17} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Regulation?.card18} />
                  </div>
                </div>
              </div>
            </div>
            <div className="productiveData">
              <div className="Headings">
                <div className="TitleIcon">
                  <ImageSitecoreField field={tabIcon[3]} />
                </div>
                <div className="TitleValue1">
                  <h2>{<TextSitecoreField field={tabTitles[3]} />}</h2>
                </div>
                <div className="TitleValue2">
                  <h3>{<RankDataBlock Data={propsData?.Productivity?.rank4} />}</h3>
                </div>
              </div>
              <div className="twoBarContainer barchartCanvas">
                <div className={`${column_7} newContainer`}>
                  <DataBlock Data={propsData?.Productivity?.bar4} showSymbol={false} />
                </div>
                <div className={`${column_5} productiveDataRanking`}>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Productivity?.card19} />
                    <CardsforFrame Data={propsData?.Productivity?.card20} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Productivity?.card21} />
                  </div>
                  <div className="card-wraper">
                    <CardsforFrame Data={propsData?.Productivity?.card22} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default FrameDataBlock;
