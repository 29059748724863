import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import Tag from '../../../globals/buttons/Tag';
import Button from '../../../globals/buttons/Button';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const CardOutcome = ({ title, text, ctaText, ctaUrl, img, tag, field, componentName = '' }) => {
  const [filterData, setFiltData] = useState([]);
  let tagsToShow = [],
    matchedValue = [];
  let objectTag = [],
    final = [];
  let keysItem = field ? Object.keys(field) : [];
  const keysToMatch = keysItem && keysItem.map((e) => e.toLowerCase());

  const tagName = () => {
    tag &&
      tag.map((i) => {
        tagsToShow.push(i?.fields?.name?.value.replace(/ /g, '').toLowerCase());
      });

    for (let i = 0; i < tagsToShow.length; i++) {
      if (keysToMatch.includes(tagsToShow[i])) {
        if (tagsToShow[i] === 'lineofbusiness') {
          tagsToShow[i] = 'lineOfBusiness';
        }
      }
    }
    matchedValue.push(tagsToShow);

    tagsToShow.map((e) => {
      objectTag.push(field[e]);
      final = objectTag.flat(1);
    });
    const filterObj = { titlevalue: [] };

    final.forEach(function (obj) {
      if (obj?.fields?.subTag) {
        filterObj.titlevalue.indexOf(obj?.fields?.subTag?.fields?.title?.value) === -1 &&
          filterObj.titlevalue.push(
            obj?.fields?.subTag?.fields?.title?.value +
              '|' +
              obj?.fields?.subTag?.fields?.icon?.value
          );
      } else {
        filterObj.titlevalue.indexOf(obj?.fields?.title?.value) === -1 &&
          filterObj.titlevalue.push(obj?.fields?.title?.value + '|' + obj?.fields?.icon?.value);
      }
    });

    let map = {};
    filterObj.titlevalue.forEach(function (cat) {
      map[cat] = true;
    });
    filterObj.titlevalue = Object.keys(map);
    const filtDataValues = filterObj.titlevalue.filter((item) => !item.includes('undefined'));
    setFiltData(filtDataValues);
  };
  useEffect(() => {
    tagName();
  }, []);

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <a
      href={ctaUrl}
      className={'card card__outcome'}
      {...OtherLinkDataAttributes({
        name: componentName,
        dataItemName: title?.value,
      })}
    >
      <div className="card-image img-h200">
        <ResponsiveImage
          imageSmall={img}
          imageMedium={img}
          imageLarge={img}
          imageXLarge={img}
          cssClass="card-image"
        />
      </div>
      <div className="card-body customer-outcomes-carousel">
        <div className="tags customer-outcomes">
          {isMobile
            ? filterData &&
              filterData
                ?.slice(0, 2)
                ?.map((i3, index) => (
                  <Tag
                    key={index}
                    fontSize="small"
                    text={i3.split('|')[0]}
                    icon={i3.split('|')[1]}
                  />
                ))
            : filterData &&
              filterData
                ?.slice(0, 5)
                ?.map((i3, index) => (
                  <Tag
                    key={index}
                    fontSize="small"
                    text={i3.split('|')[0]}
                    icon={i3.split('|')[1]}
                  />
                ))}
          {/* {filterData &&
            filterData
              ?.slice(0, limit)
              ?.map((i3, index) => (
                <Tag key={index} fontSize="small" text={i3.split('|')[0]} icon={i3.split('|')[1]} />
              ))} */}
        </div>

        <h3 className="title outcomes">
          <TextSitecoreField field={title} />
        </h3>
        <p className="description">
          <TextSitecoreField field={text} />
        </p>
        <div className="cta__wrap">
          <Button
            variant="text-button"
            text={ctaText}
            componentName={componentName}
            dataItemName={title?.value}
          />
        </div>
      </div>
    </a>
  );
};

CardOutcome.defaultProps = {
  title: '',
  text: '',
  ctaText: '',
  ctaUrl: '',
  img: '',
  tag: [],
  field: {},
  componentName: '',
};

CardOutcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  img: PropTypes.string,
  componentName: PropTypes.string,
};

export default CardOutcome;
