import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import CardOutcome from '../Cards/CardOutcome';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';
import { isBrowserDocumentAvailable } from '../../../utils/HelperUtils';

const CarouselCustomerOutcomes = (props) => {
  const [progress, setProgress] = useState(5);
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { title, subTitle, insightitems = [], tagsToShow } = fields;

  let slidesToShow = 0;
  switch (insightitems?.length) {
    case 1:
      slidesToShow = 1;
      break;
    case 2:
      slidesToShow = 2;
      break;
    default:
      slidesToShow = 2.2;
  }

  const settingsSliderNav = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: insightitems && insightitems.length === 1 ? 1 : 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    accessibility: true,
    afterChange: (current) => {
      hideAriaHiddenTiles();
      setProgress((100 / (insightitems?.length - slidesToShow + 1)) * (current + 1));
    },
    customPaging: (i) => (
      <div className="pagination">
        <span className="active">
          {i < 9 ? '0' : ''}
          {i + 1}
        </span>
        <span>&nbsp;/&nbsp;</span>
        <span className="total">
          {insightitems.length < 10 ? '0' : ''}
          {insightitems && insightitems?.length}
        </span>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: false,
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: false,
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function hideAriaHiddenTiles() {
    if (isBrowserDocumentAvailable) {
      Array.from(document?.querySelectorAll('.slick-slide')).forEach((slide) => {
        slide.style.visibility = slide.classList?.contains('slick-active') ? 'visible' : 'hidden';
      });
    }
  }

  useEffect(() => {
    hideAriaHiddenTiles();
  });

  return (
    <section
      className={`carousel standard outcomes outcomes__${insightitems && insightitems?.length}`}
      {...ComponentDataAttributes(componentName)}
    >
      <div className="container-xl section-wrapper">
        <div className="row header-wrapper">
          <div className="col-10 header-class">
            <h2 className="title">
              <TextSitecoreField field={title} />
            </h2>
            {subTitle?.value && (
              <p className="sub-title">
                <TextSitecoreField field={subTitle} />
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col carousel-wrapper customer-outcome">
            <Slider {...settingsSliderNav}>
              {insightitems &&
                insightitems?.map((item) => (
                  <CardOutcome
                    key={item?.id}
                    field={item.fields}
                    title={item?.fields?.Title}
                    text={item?.fields?.subTitle}
                    ctaText="Read More"
                    ctaUrl={item?.url}
                    tag={tagsToShow}
                    img={item?.fields?.image}
                    componentName={componentName}
                  />
                ))}
            </Slider>
            {insightitems?.length > 2 && (
              <div className="progress" role="progressbar" aria-label="progressbar">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="progressbar"
                  style={{ width: `${progress}%` }}
                >
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

CarouselCustomerOutcomes.defaultProps = {
  fields: {},
  title: 'How we have helped our customers',
  cssClass: '',
};

CarouselCustomerOutcomes.propTypes = {
  /** Section Header H2 */
  title: PropTypes.string,
  cssClass: PropTypes.string,

  /** Cards */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      ctaText: PropTypes.string,
    })
  ),
  fields: PropTypes.shape({}),
};

export default CarouselCustomerOutcomes;
