import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import Button from '../../../globals/buttons/Button';
import DataBlockFeaturetteChart from './../DataBlock/DataBlockFeaturetteChart';

import './dataBlockInsightChart.scss';

import { wrapChartData } from '../../../utils/HelperUtils';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';

const DataBlockInsightChart = ({
  header,
  title,
  description,
  ctaText,
  textAlign,
  ctaLink,
  data,
  backgroundColor,
  cssClass,
  componentName,
}) => {
  const _BarChartAlt = wrapChartData(data);
  const isTiny = useMediaQuery({ maxWidth: breakpoints.xxs - 1 });
  const isMobile = useMediaQuery({
    minWidth: breakpoints.xxs,
    maxWidth: breakpoints.xs - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: breakpoints.xs,
    maxWidth: breakpoints.xl,
  });

  const getSize = () => {
    if (isTiny) {
      return 'Tiny';
    } else if (isMobile) {
      return 'XXS';
    } else if (isTablet) {
      return 'S';
    }
    return 'M';
  };

  return (
    <section
      className={`featurette-section standard data ${textAlign} ${cssClass} ${backgroundColor}`}
      style={{ paddingRight: '0px' }}
    >
      <div className="container-xl">
        <div className={`row content-wrapper ${textAlign}`}>
          <div className="col-lg-5">
            <div className="featurette-body" style={{ backgroundColor: 'black' }}>
              {header && <div className="sub-title">{header?.value}</div>}
              {title && title?.value && (
                <h2 className="title">
                  <TextSitecoreField field={title} />
                </h2>
              )}
              {description && description?.value && (
                <div className="richtext-wrapper">
                  <TextSitecoreField field={description} />
                </div>
              )}
              {ctaText && (
                <div className="cta-wrapper">
                  <Button
                    text={<TextSitecoreField field={ctaText} /> || ctaText?.value}
                    cssClass={`${backgroundColor === 'secondary' ? 'white' : ''}`}
                    variant="text-button"
                    arrow={true}
                    dataItemName={title?.value}
                    BtnFunction={() => {
                      location.href = ctaLink?.value?.href;
                    }}
                    componentName={componentName}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-7">
            <div className="featurette-data">
              <DataBlockFeaturetteChart
                title={_BarChartAlt?.title}
                descLabel={_BarChartAlt?.descLabel ? _BarChartAlt?.descLabel : undefined}
                description={_BarChartAlt?.description ? _BarChartAlt?.description : undefined}
                size={getSize()}
                color={_BarChartAlt?.color}
                backgroundColor={backgroundColor}
                chartType={_BarChartAlt?.chartType}
                data={_BarChartAlt?.data}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

DataBlockInsightChart.defaultProps = {
  header: {},
  title: {},
  description: {},
  ctaText: {},
  textAlign: '',
  ctaLink: {},
  data: {
    title: '',
    descLabel: '',
    description: '',
    chartType: '',
    data: {},
  },
  backgroundColor: 'secondary',
  cssClass: '',
  componentName: '',
};

DataBlockInsightChart.propTypes = {
  header: PropTypes.shape({}),
  subTitle: PropTypes.shape({}),
  title: PropTypes.shape({}),
  description: PropTypes.shape({}),
  ctaText: PropTypes.shape({}),
  ctaLink: PropTypes.shape({}),
  textAlign: PropTypes.string,
  cssClass: PropTypes.string,
  data: PropTypes.shape({}),
};

export default DataBlockInsightChart;
