import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../globals/buttons/Tag';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import { ComponentDataAttributes } from '../../../constants/gtm';

const ActionBarPodcastProviders = (props) => {
  const {
    fields,
    rendering: { componentName = '' },
  } = props;
  const { podcastList, title } = fields;

  return (
    <div className="action-bar podcast-providers" {...ComponentDataAttributes(componentName)}>
      <div className="title">{title && <TextSitecoreField field={title} />}</div>
      <div className="tag-wrapper">
        {podcastList &&
          podcastList?.map((item) => (
            <Tag
              key={item?.id}
              cssClass={item?.cssClass}
              icon={item?.fields?.icon}
              text={<TextSitecoreField field={item?.fields?.ctaText} />}
              dataItemName={title || title?.value}
              componentName={componentName}
              variant={item?.variant ? item?.variant : 'outline'}
              onClick={() => {
                location.href = item?.fields?.ctaLink?.value?.href
                  ? item?.fields?.ctaLink?.value?.href
                  : '';
              }}
            />
          ))}
      </div>
    </div>
  );
};

ActionBarPodcastProviders.defaultProps = {
  fields: {
    title: '',
    podcastList: [],
  },
};

ActionBarPodcastProviders.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default ActionBarPodcastProviders;
