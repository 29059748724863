import React from 'react';
import { withSitecoreContext, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import BreadcrumbClass from '../../core/Breadcrumb/Breadcrumb';
import ResponsiveImage from '../../../globals/icons/ResponsiveImage';
import ActionBarTags from '../../core/ActionBars/ActionBarTags';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { ComponentDataAttributes, OtherLinkDataAttributes } from '../../../constants/gtm';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';

const BannerServices = (props) => {
  const {
    fields,
    Breadcrumb,
    rendering: { componentName },
  } = props;
  const {
    backgroundImageMobile,
    ctaIconPosition,
    ctaLink,
    ctaStyle,
    ctaText,
    description,
    frostedGlass,
    backgroundImage = {},
    optCtaIconPosition,
    optCtaLink,
    optCtaStyle,
    optCtaText,
    optShowCTAIcon,
    serviceLink,
    serviceTitle,
    showCTAIcon,
    tagTitle,
    tags,
    title,
    frostedGlassColor,
    textColor,
  } = fields;
  const breadcrumbItems =
    Breadcrumb && Array.isArray(Breadcrumb) && Breadcrumb[0]?.props?.fields?.data?.item;
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    <section
      className={`banner-section standard banner services  ${
        frostedGlass?.value === true && !isEmpty(frostedGlassColor?.fields?.text?.value)
          ? 'glass'
          : ''
      }`}
      {...ComponentDataAttributes(componentName)}
    >
      <ResponsiveImage
        cssClass="banner-image"
        imageSmall={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageMedium={
          isMobileScreen && !isEmpty(backgroundImageMobile?.value)
            ? backgroundImageMobile
            : backgroundImage
        }
        imageLarge={backgroundImage}
        imageXLarge={backgroundImage}
        alt={backgroundImage?.fields?.value?.alt}
      />
      <div className="container-xl">
        <div className="row content-wrapper">
          <div
            className={`col-xl-8 col-lg-7 col-md-7 ${
              isMobileScreen
                ? ''
                : frostedGlass?.value === true &&
                  !isEmpty(frostedGlassColor?.fields?.text?.value) &&
                  (frostedGlassColor?.fields?.text?.value === 'light'
                    ? 'frost frost__light'
                    : 'frost frost__dark')
            }`}
          >
            {breadcrumbItems && (
              <BreadcrumbClass
                items={breadcrumbItems}
                cssClass={`${
                  textColor?.fields?.text?.value === 'white' ? 'on-secondary' : 'on-primary'
                }`}
                componentName={componentName}
                // textColor={`${
                //   textColor?.fields?.text?.value === 'white' ? 'secondary' : 'primary'
                // }`}
              />
            )}
            <h1
              className={`title ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              }`}
            >
              <TextSitecoreField field={title} />
            </h1>
            <p
              className={`service-provider ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              } `}
            >
              <a
                href={`${serviceLink?.value?.href}`}
                className="delivered"
                {...OtherLinkDataAttributes({
                  name: componentName,
                  text: serviceTitle?.value || '',
                  url: serviceLink?.value?.href,
                  dataItemName: serviceTitle?.value || '',
                })}
              >
                <RichTextSitecoreField field={serviceTitle} />
              </a>
            </p>
            <p
              className={`sub-title ${
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              } para-content-limit `}
            >
              <TextSitecoreField field={description} />
            </p>
            <div className="cta__wrap">
              {ctaLink && ctaText && !isEmpty(ctaText?.value) && !isEmpty(ctaLink?.value?.href) && (
                <ButtonHref
                  text={ctaText?.value || ctaLink?.value?.text}
                  href={
                    !isEmpty(ctaLink?.value?.href) || !isEmpty(ctaLink?.value?.anchor)
                      ? ctaLink?.value?.href + ctaLink?.value?.anchor
                      : ctaLink?.value?.href
                  }
                  cssClass={!isMobileScreen ? textColor?.fields?.text?.value : 'black'}
                  variant={ctaStyle ? ctaStyle?.fields?.text?.value : 'primary-button'}
                  arrow={showCTAIcon ? showCTAIcon.value : false}
                  linkType={ctaLink?.value?.linktype}
                  linkFields={ctaLink}
                  dataItemName={title || ''}
                  iconPosition={
                    ctaIconPosition?.fields?.text?.value === 'right' ? 'icon__right' : 'icon__left'
                  }
                  componentName={componentName}
                />
              )}
              {optCtaLink &&
                optCtaText &&
                !isEmpty(optCtaText?.value) &&
                !isEmpty(optCtaLink?.value?.href) && (
                  <ButtonHref
                    text={optCtaText?.value || optCtaLink?.value?.text}
                    href={optCtaLink?.value?.href}
                    cssClass={
                      isMobileScreen && !isEmpty(textColor)
                        ? 'black'
                        : textColor?.fields?.text?.value
                    }
                    variant={optCtaStyle ? optCtaStyle?.fields?.text?.value : 'text-button'}
                    arrow={optShowCTAIcon ? optShowCTAIcon?.value : false}
                    linkType={optCtaLink?.value?.linktype}
                    linkFields={optCtaLink}
                    dataItemName={title || ''}
                    iconPosition={
                      optCtaIconPosition?.fields?.text?.value === 'right'
                        ? 'icon__right'
                        : 'icon__left'
                    }
                    componentName={componentName}
                  />
                )}
            </div>
            <ActionBarTags
              title={tagTitle}
              items={tags}
              cssClass={
                !isMobileScreen && textColor?.fields?.text?.value === 'white'
                  ? 'on-secondary'
                  : 'on-primary'
              }
              className={`${textColor?.fields?.text?.value === 'black' ? 'primary' : 'secondary'}`}
              componentName={componentName}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const BannerServicesContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'Breadcrumb' }])(BannerServices)
);

BannerServices.defaultProps = {
  fields: {
    serviceTitle: {
      value: 'default: <strong>default </strong>',
    },
    serviceLink: {
      value: {
        text: '',
        anchor: '',
        linktype: 'internal',
        class: '',
        title: '',
        querystring: '',
        id: '',
        href: '/',
      },
    },
    tagTitle: {
      value: 'default',
    },
    tags: [
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          icon: {
            value: {},
          },
          displayInUI: {
            value: true,
          },
          sortOrder: {
            value: null,
          },
          title: {
            value: 'default',
          },
        },
      },
    ],
    backgroundImageMobile: {
      value: {
        src: '',
        alt: 'default',
        width: '',
        height: '',
      },
    },
    foregroundImage: {
      value: {},
    },
    frostedGlass: {
      value: '',
    },
    secondaryButtonLink: {
      value: {
        href: '',
      },
    },
    secondaryButtonText: {
      value: '',
    },
    title: {
      value: 'default',
    },
    description: {
      value: 'default',
    },
    backgroundImage: {
      value: {
        src: '',
        alt: 'default',
        width: '',
        height: '',
      },
    },
    showCTAIcon: {
      value: true,
    },
    ctaIcon: {
      value: {
        src: '',
        alt: '',
      },
    },
    ctaIconPosition: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'left',
        },
      },
    },
    ctaLink: {
      value: {
        text: '',
        anchor: '',
        linktype: 'internal',
        class: '',
        title: '',
        querystring: '',
        id: '',
        href: '/',
      },
    },
    ctaStyle: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'primary-button',
        },
      },
    },
    ctaText: {
      value: '',
    },
    frostedGlassColor: {},
    textColor: {
      fields: {
        text: {
          value: 'black',
        },
      },
    },
    optCtaLink: {
      value: {
        text: '',
        anchor: '',
        linktype: 'internal',
        class: '',
        title: '',
        querystring: '',
        id: '',
        href: '/',
      },
    },
    optCtaStyle: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'text-button',
        },
      },
    },
    optCtaText: {
      value: 'default',
    },
    optShowCTAIcon: {
      value: false,
    },
    optCtaIcon: {
      value: {},
    },
    optCtaIconPosition: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        text: {
          value: 'right',
        },
      },
    },
  },
};

BannerServices.propTypes = {
  fields: PropTypes.shape({}),
};

export default BannerServicesContainer;
