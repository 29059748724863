import React from 'react';
import PropTypes from 'prop-types';

const ButtonLink = ({ ctaText, ctaUrl, ctaLabel, cssClass, variant }) => (
  <a href={ctaUrl} className={`button ${variant} ${cssClass}`} aria-label={ctaLabel}>
    {ctaText}
    <div className="arrow-icon"></div>
  </a>
);

ButtonLink.defaultProps = {
  ctaText: '',
  ctaUrl: '',
  ctaLabel: '',
  cssClass: '',
  variant: 'primary-button',
};

ButtonLink.propTypes = {
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  /** Button Color */
  cssClass: PropTypes.string,
  /** Solid or Outline */
  variant: PropTypes.oneOf(['primary-button', 'secondary-button', 'text-button', 'nav-button']),
};

export default ButtonLink;
