import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import ManageContentTypeTags from '../DetailsBlocks/ManageContentTypeTags';
import { getContentTypeTags } from '../../../utils/HelperUtils';
import { OtherLinkDataAttributes } from '../../../constants/gtm';

const CardEventCarousel = ({
  title,
  subTitle,
  text,
  ctaUrl,
  ctaText,
  tags,
  date,
  contentType,
  componentName,
  item,
  fields,
}) => (
  <a
    href={ctaUrl}
    className={'card card__carousel event'}
    {...OtherLinkDataAttributes({
      name: componentName,
      text: text?.value,
      url: ctaUrl,
      dataItemName: title,
    })}
  >
    <div className="card-header p-0 mb-24">
      <ManageContentTypeTags tags={getContentTypeTags(item, fields)} />
    </div>
    <div className="card-body p-0">
      <div className="sub-title">{subTitle}</div>
      <h3 className="title">{title}</h3>
      <p className="description mb-16">{text}</p>
    </div>
    <div className="card-footer-carousel m-0">
      <Button
        variant="text-button"
        text={ctaText}
        arrow={true}
        fontSize="small"
        componentName={componentName}
        dataItemName={title}
      />
    </div>
  </a>
);

CardEventCarousel.defaultProps = {
  tags: [{ id: 0, text: '10 min read' }],
  contentType: 'Article',
  date: 'April 1, 2025',
  subtTitle: '',
  title: '',
  text: '',
  ctaUrl: '',
  ctaText: 'Read more',
};

CardEventCarousel.propTypes = {
  /** Content tag (i.e. webinar, article, or movie etc.) */
  tags: PropTypes.array,
  contentType: PropTypes.string,
  date: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
};

export default CardEventCarousel;
